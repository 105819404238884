/* eslint-disable array-callback-return */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  ArrowBack,
  Menu,
  People,
  EmojiEmotions,
  TableChart,
} from "@material-ui/icons";
import { logout } from "../../redux/actions/auth.actions";
import { LogOut } from "@styled-icons/boxicons-regular/LogOut";
import { Box } from "@styled-icons/boxicons-regular/Box";
import { AddBusiness, Settings, SupportAgent } from "@styled-icons/material";
import { SpoonKnife } from "@styled-icons/icomoon/SpoonKnife";
import { getComplexes } from "../../redux/actions/complexes.action";
import { getUsers } from "../../redux/actions/users.action";
import { getCustomers } from "../../redux/actions/customers.action";

const drawerWidth = 190;
const version = "1.0.41";

const MyDrawer = ({ location, open, _toggleBar }) => {
  const classes = useStyles(location);
  const {
    remote,
    auth: { user },
    users,
    customers,
    complexes,
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const drawerItems = [
    {
      name: "Delivery Compl...",
      icon: <TableChart className={classes.sideicon} />,
      link: "/delivery-complexes",
    },

    {
      name: "Collection Comp...",
      icon: <AddBusiness className={classes.sideicon} />,
      link: "/collection-complexes",
    },

    {
      name: "Stock Outs",
      icon: <Box className={classes.sideicon} />,
      link: "/stock-outs",
    },
    {
      name: "Users",
      icon: <People className={classes.sideicon} />,
      link: "/users",
    },

    {
      name: "Customers",
      icon: <EmojiEmotions className={classes.sideicon} />,
      link: "/customers",
    },

    {
      name: "Customer Alerts",
      icon: <SupportAgent className={classes.sideicon} />,
      link: "/customer-alerts",
    },
    {
      name: "Products",
      icon: <SpoonKnife className={classes.sideicon} />,
      link: "/products",
    },
    {
      name: "Settings",
      icon: <Settings className={classes.sideicon} />,
      link: "/settings",
    },

    {
      name: "Logout",
      icon: <LogOut className={classes.sideicon} style={{ marginLeft: 5 }} />,
      link: "/login",
    },
  ];

  const drawerAction = (name) => {
    if (name === "Logout") {
      dispatch(logout());
    } else {
      if (remote) {
        if (name === "Products" || "Stock Outs") {
          if (!complexes) {
            dispatch(getComplexes(remote.country.name));
          }
        } else if (name === "Users") {
          if (!users) {
            dispatch(getUsers(remote.country.name.toLowerCase()));
          }
        } else if (name === "Customers") {
          if (!customers) {
            dispatch(getCustomers(remote.country.country_code));
          }
        }
      }
    }
  };

  const drawerItem = (index, name, icon, link) => {
    return (
      <ListItem
        button
        key={index}
        className={
          location.pathname === link ? classes.activeItem : classes.listItem
        }
        onClick={() => drawerAction(name)}
      >
        {name === "Logout" ? (
          <div className={classes.link} style={{ paddingLeft: 5 }}>
            {icon}
            <ListItemText primary={name} className={classes.text} />
          </div>
        ) : (
          <Link to={link} className={classes.link}>
            {icon}
            <ListItemText primary={name} className={classes.text} />
          </Link>
        )}
      </ListItem>
    );
  };

  const drawer = (
    <div style={{ height: "100%", backgroundColor: "#000" }}>
      <img
        alt=""
        src={require("../../assets/dadv2.png").default}
        className={classes.logo}
      />

      <Grid
        container
        justify={open ? "flex-end" : "center"}
        style={{ borderBottom: "1px solid #364447", paddingBottom: 12 }}
      >
        <Grid item>
          <Button onClick={_toggleBar}>
            {open ? (
              <ArrowBack className={classes.menuIcon} />
            ) : (
              <Menu className={classes.menuIcon} />
            )}
          </Button>
        </Grid>
      </Grid>

      <List style={{ padding: 0 }}>
        {drawerItems.map(({ name, icon, link }, index) => {
          if (user.role === "site") {
            if (name === "Stock Outs" || name === "Logout") {
              return drawerItem(index, name, icon, link);
            }
          } else if (user.role === "agent") {
            if (name !== "Users") {
              return drawerItem(index, name, icon, link);
            }
          } else if (name === "Settings") {
            if (user.role === "admin" || user.role === "manager") {
              return drawerItem(index, name, icon, link);
            }
          } else {
            return drawerItem(index, name, icon, link);
          }
        })}
      </List>
    </div>
  );
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: open ? classes.drawerOpen : classes.drawerClose,
      }}
    >
      <div className={classes.whiteMargin} />
      {drawer}
      <div className={classes.role}>@{user.role}</div>
      <div className={classes.version}>{version}</div>
      <div className={classes.whiteMarginBottom} />
    </Drawer>
  );
};

MyDrawer.propTypes = {};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },

  logo: {
    width: "55%",
    marginLeft: "20%",
    marginTop: 15,
    marginBottom: 15,
  },

  role: {
    color: "#fff",
  },

  version: {
    color: "#fff",
  },

  whiteMargin: {
    backgroundColor: "#fff",
  },

  whiteMarginBottom: {
    backgroundColor: "#ebebeb",
  },

  drawerOpen: {
    backgroundColor: "#000",
    border: "none",
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    backgroundColor: "#000",
    border: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },

  listItem: {
    padding: 0,
    borderBottom: "1px solid #364447",
    "&:hover": {
      backgroundColor: "#3d3d3d",
      borderLeft: "5px solid #fff",
      "& a span": {
        fontWeight: "bold",
      },
    },
  },

  activeItem: {
    padding: 0,
    backgroundColor: "#ed1b24",
    borderLeft: "5px solid #fff",
    "&:hover": {
      backgroundColor: "#ed1b24",
    },
    "& a span": {
      color: "#fff",
    },
  },

  link: {
    display: "flex",
    textDecoration: "none",
    padding: "10px 6px 10px 12px",
    color: "#fff",
  },

  menuIcon: {
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3d3d3d",
      borderRadius: 5,
    },
  },
  sideicon: {
    marginRight: 20,
    width: 25,
    height: 25,
  },
}));

export default MyDrawer;
