import firebase from "firebase";
import "firebase/auth";
import "firebase/app";
import "firebase/analytics";
import "firebase/remote-config";
import "firebase/storage";
import "firebase/database";

// LIVE
const firebaseConfig = {
  apiKey: "AIzaSyBTCeBGcfwMP13-Umhj4NQhcar2cmZpQI0",
  authDomain: "dial-a-delivery-196607.firebaseapp.com",
  databaseURL: "https://dial-a-delivery-196607.firebaseio.com",
  projectId: "dial-a-delivery-196607",
  storageBucket: "dial-a-delivery-196607.appspot.com",
  messagingSenderId: "342563950613",
  appId: "1:342563950613:web:6c34edbc5ef916958bbe1c",
  measurementId: "G-3RCBDKPMR8",
};

// TEST
// const firebaseConfig = {
//   apiKey: "AIzaSyBu8pR_c5jaip_vCDEMFU5D2tVRQwSHPPY",
//   authDomain: "dial-a-delivery-test.firebaseapp.com",
//   databaseURL: "https://dial-a-delivery-test-default-rtdb.firebaseio.com",
//   projectId: "dial-a-delivery-test",
//   storageBucket: "dial-a-delivery-test.appspot.com",
//   messagingSenderId: "325385918250",
//   appId: "1:325385918250:web:850b8a9345fc20192b3053",
// };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth();
firebase.remoteConfig();
firebase.database();

const storage = firebase.storage();

export { storage, firebase as default };
