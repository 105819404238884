import React, { useState } from "react";
import {
  List,
  ListItem,
  Card,
  Tab,
  Tabs,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core/";
import { ArrowForwardIos } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import ShopItem from "./shopItem";

const Shop = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  return (
    <div>
      {props.specialsMenu.length > 0 ? (
        <div style={{ paddingRight: 8, paddingLeft: 8 }}>
          <Tabs
            value={value}
            scrollButtons="on"
            variant="scrollable"
            classes={{ indicator: classes.indicator }}
          >
            {props.specialsMenu.map((item, i) => {
              return props.specials
                .filter((f) => f.items === item.uuid)
                .map((special) => {
                  return (
                    <Tab
                      key={item.id}
                      disableRipple
                      onClick={() =>
                        props.addProduct({
                          ...item,
                          brand: props.brand,
                        })
                      }
                      style={{ opacity: 1 }}
                      icon={
                        <Card
                          style={{
                            borderRadius: 8,
                          }}
                        >
                          <img
                            style={{
                              height: "80px",
                              width: "250px",
                            }}
                            src={special.special_image.en}
                            aly={item.name.en}
                          />
                          <ListItem
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              textTransform: "capitalize",
                            }}
                          >
                            <ListItemText
                              primary={
                                <div>
                                  <span style={{ paddingRight: 80 }}>
                                    {item.name.en}
                                  </span>
                                  {!item.available && (
                                    <div className={classes.available}>
                                      <p>Unavailable</p>
                                    </div>
                                  )}
                                </div>
                              }
                              className="products-shop"
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                className="products-shop"
                                primary={
                                  <div style={{ marginBottom: -20 }}>
                                    <span>
                                      {props.availableCurrency
                                        ? item.prices
                                          ? Number(
                                              item.prices[props.currency]
                                            ).toFixed(item.pricePrecision) !==
                                              (0.0).toFixed(
                                                item.pricePrecision
                                              ) &&
                                            `${Number(
                                              item.prices[props.currency]
                                            ).toFixed(item.pricePrecision)}`
                                          : Number(item.price).toFixed(
                                              item.pricePrecision
                                            ) !==
                                              (0.0).toFixed(
                                                item.pricePrecision
                                              ) &&
                                            `${Number(item.price).toFixed(
                                              item.pricePrecision
                                            )}`
                                        : Number(item.price).toFixed(
                                            item.pricePrecision
                                          ) !==
                                            (0.0).toFixed(
                                              item.pricePrecision
                                            ) &&
                                          `${Number(item.price).toFixed(
                                            item.pricePrecision
                                          )}`}{" "}
                                    </span>
                                    <span>
                                      <ArrowForwardIos
                                        style={{ marginBottom: -10, width: 15 }}
                                      />
                                    </span>
                                  </div>
                                }
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        </Card>
                      }
                    />
                  );
                });
            })}
          </Tabs>
        </div>
      ) : null}
      <List>
        {props.categories.map((category, i) => {
          let t = {};
          let items = props.brandCategoriesItems.filter(
            (e) => !(t[e.id] = e.id in t)
          );
          return (
            <ShopItem
              addProduct={props.addProduct}
              key={i}
              items={props.menu.items}
              category={category}
              menu={props.menu}
              updateMenu={props.updateMenu}
              brand={props.brand}
              classes={classes}
              currency={props.currency}
              availableCurrency={props.availableCurrency}
            />
          );
        })}
      </List>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    color: "#000",
    fontSize: 14,
  },
  btn: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  primary: {
    fontSize: 10,
    fontWeight: "bold",
  },
  listText: {
    fontSize: 15,
    fontWeight: "bold",
  },
  indicator: {
    backgroundColor: "transparent",
  },
  subtitle: {
    color: "#000",
    fontSize: 12,
  },
  warningMessage: {
    borderRadius: 4,
    backgroundColor: "#F99B1C",
    padding: 16,
    margin: 4,
    marginLeft: 4,
    alignSelf: "center",
  },
  cartBtn: {
    bottom: 8,
    left: 0,
    right: 0,
    position: "absolute",
    width: "96%",
    paddingHorizontal: 16,
    height: 48,
    backgroundColor: "#D71920",
  },
  available: {
    margin: 0,
    fontSize: 11,
    display: "inline-flex",
    borderRadius: 40,
    backgroundColor: "#ffbdc0",
    padding: 4,
    "&>p": {
      color: "#f2242f",
      margin: 0,
      marginLeft: 5,
    },
  },
}));

export default Shop;
