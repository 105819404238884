//===================================
// REMOTE CONFIG
//===================================

export const GET_REMOTE_CONFIG_SUCCESS = "GET_REMOTE_CONFIG_SUCCESS";

//===================================
// AUTHENTICATION
//===================================

export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

//===================================

export const RESET_EMAIL_FAILED = "RESET_EMAIL_FAILED";
export const RESET_EMAIL_LOADING = "RESET_EMAIL_LOADING";
export const RESET_EMAIL_SUCCESS = "RESET_EMAIL_SUCCESS";

//===================================
// COMPLEXES
//===================================

export const GET_COMPLEXES_FAILED = "GET_COMPLEXES_FAILED";
export const GET_COMPLEXES_LOADING = "GET_COMPLEXES_LOADING";
export const GET_COMPLEXES_SUCCESS = "GET_COMPLEXES_SUCCESS";

//===================================
// COLLECTION COMPLEXES
//===================================

export const GET_COLLECTION_COMPLEXES_FAILED =
  "GET_COLLECTION_COMPLEXES_FAILED";
export const GET_COLLECTION_COMPLEXES_LOADING =
  "GET_COLLECTION_COMPLEXES_LOADING";
export const GET_COLLECTION_COMPLEXES_SUCCESS =
  "GET_COLLECTION_COMPLEXES_SUCCESS";

export const UPDATE_COLLECTION_COMPLEXES_FAILED =
  "UPDATE_COLLECTION_COMPLEXES_FAILED";
export const UPDATE_COLLECTION_COMPLEXES_LOADING =
  "UPDATE_COLLECTION_COMPLEXES_LOADING";
export const UPDATE_COLLECTION_COMPLEXES_SUCCESS =
  "UPDATE_COLLECTION_COMPLEXES_SUCCESS";

//===================================
// COMPLEXES
//===================================

export const GET_MENU_FAILED = "GET_MENU_FAILED";
export const GET_MENU_LOADING = "GET_MENU_LOADING";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";

export const UPDATE_MENU_FAILED = "UPDATE_MENU_FAILED";
export const UPDATE_MENU_LOADING = "UPDATE_MENU_LOADING";
export const UPDATE_MENU_SUCCESS = "UPDATE_MENU_SUCCESS";

//===================================
// ZONE COMPLEXES
//===================================

export const GET_ZONE_COMPLEX_FAILED = "GET_ZONE_COMPLEX_FAILED";
export const GET_ZONE_COMPLEX_LOADING = "GET_ZONE_COMPLEX_LOADING";
export const GET_ZONE_COMPLEX_SUCCESS = "GET_ZONE_COMPLEX_SUCCESS";

export const GET_ZONES_COMPLEXES_FAILED = "GET_ZONES_COMPLEXES_FAILED";
export const GET_ZONES_COMPLEXES_LOADING = "GET_ZONES_COMPLEXES_LOADING";
export const GET_ZONES_COMPLEXES_SUCCESS = "GET_ZONES_COMPLEXES_SUCCESS";

export const REFRESH_ZONES_COMPLEXES_FAILED = "REFRESH_ZONES_COMPLEXES_FAILED";
export const REFRESH_ZONES_COMPLEXES_LOADING =
  "REFRESH_ZONES_COMPLEXES_LOADING";
export const REFRESH_ZONES_COMPLEXES_SUCCESS =
  "REFRESH_ZONES_COMPLEXES_SUCCESS";

export const UPDATE_ZONE_COMPLEX_FAILED = "UPDATE_ZONE_COMPLEX_FAILED";
export const UPDATE_ZONE_COMPLEX_LOADING = "UPDATE_ZONE_COMPLEX_LOADING";
export const UPDATE_ZONE_COMPLEX_SUCCESS = "UPDATE_ZONE_COMPLEX_SUCCESS";

//===================================
// AGENTS
//===================================

export const GET_USERS_FAILED = "GET_USERS_FAILED";
export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

export const REFRESH_USERS_FAILED = "REFRESH_USERS_FAILED";
export const REFRESH_USERS_LOADING = "REFRESH_USERS_LOADING";
export const REFRESH_USERS_SUCCESS = "REFRESH_USERS_SUCCESS";

export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const ADD_USER_LOADING = "ADD_USER_LOADING";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";

export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const RESET_USER_PASSWORD_FAILED = "RESET_USER_PASSWORD_FAILED";
export const RESET_USER_PASSWORD_LOADING = "RESET_USER_PASSWORD_LOADING";
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS";

export const DISABLE_USER_FAILED = "DISABLE_USER_FAILED";
export const DISABLE_USER_LOADING = "DISABLE_USER_LOADING";
export const DISABLE_USER_SUCCESS = "DISABLE_USER_SUCCESS";

//===================================
// CUSTOMERS
//===================================

export const GET_CUSTOMERS_FAILED = "GET_CUSTOMERS_FAILED";
export const GET_CUSTOMERS_LOADING = "GET_CUSTOMERS_LOADING";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";

export const GET_CUSTOMER_FAILED = "GET_CUSTOMER_FAILED";
export const GET_CUSTOMER_LOADING = "GET_CUSTOMER_LOADING";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";

export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";

export const GET_CUSTOMER_ADDRESSES_FAILED = "GET_CUSTOMER_ADDRESSES_FAILED";
export const GET_CUSTOMER_ADDRESSES_LOADING = "GET_CUSTOMER_ADDRESSES_LOADING";
export const GET_CUSTOMER_ADDRESSES_SUCCESS = "GET_CUSTOMER_ADDRESSES_SUCCESS";

export const SEARCH_CUSTOMER_FAILED = "SEARCH_CUSTOMER_FAILED";
export const SEARCH_CUSTOMER_LOADING = "SEARCH_CUSTOMER_LOADING";
export const SEARCH_CUSTOMER_SUCCESS = "SEARCH_CUSTOMER_SUCCESS";

export const GET_MORE_CUSTOMERS_FAILED = "GET_MORE_CUSTOMERS_FAILED";
export const GET_MORE_CUSTOMERS_LOADING = "GET_MORE_CUSTOMERS_LOADING";
export const GET_MORE_CUSTOMERS_SUCCESS = "GET_MORE_CUSTOMERS_SUCCESS";

export const REFRESH_CUSTOMERS_FAILED = "REFRESH_CUSTOMERS_FAILED";
export const REFRESH_CUSTOMERS_LOADING = "REFRESH_CUSTOMERS_LOADING";
export const REFRESH_CUSTOMERS_SUCCESS = "REFRESH_CUSTOMERS_SUCCESS";

export const UPDATE_CUSTOMER_PERSONAL_INFO_FAILED =
  "UPDATE_CUSTOMER_PERSONAL_INFO_FAILED";
export const UPDATE_CUSTOMER_PERSONAL_INFO_LOADING =
  "UPDATE_CUSTOMER_PERSONAL_INFO_LOADING";
export const UPDATE_CUSTOMER_PERSONAL_INFO_SUCCESS =
  "UPDATE_CUSTOMER_PERSONAL_INFO_SUCCESS";

export const UPDATE_CUSTOMER_ADDRESS_FAILED = "UPDATE_CUSTOMER_ADDRESS_FAILED";
export const UPDATE_CUSTOMER_ADDRESS_LOADING =
  "UPDATE_CUSTOMER_ADDRESS_LOADING";
export const UPDATE_CUSTOMER_ADDRESS_SUCCESS =
  "UPDATE_CUSTOMER_ADDRESS_SUCCESS";

export const DISABLE_CUSTOMER_FAILED = "DISABLE_CUSTOMER_FAILED";
export const DISABLE_CUSTOMER_LOADING = "DISABLE_CUSTOMER_LOADING";
export const DISABLE_CUSTOMER_SUCCESS = "DISABLE_CUSTOMER_SUCCESS";

//===================================
// STOCK
//===================================

export const GET_STOCK_FAILED = "GET_STOCK_FAILED";
export const GET_STOCK_LOADING = "GET_STOCK_LOADING";
export const GET_STOCK_SUCCESS = "GET_STOCK_SUCCESS";

export const DISABLE_PRODUCT_FAILED = "DISABLE_PRODUCT_FAILED";
export const DISABLE_PRODUCT_LOADING = "DISABLE_PRODUCT_LOADING";
export const DISABLE_PRODUCT_SUCCESS = "DISABLE_PRODUCT_SUCCESS";
