import * as types from "../action.types";

export const collectionComplexesReducer = (state, action) => {
  switch (action.type) {
    case types.GET_COLLECTION_COMPLEXES_LOADING:
      return { isLoading: true, list: [] };

    case types.GET_COLLECTION_COMPLEXES_FAILED:
      return {
        isLoading: false,
        error: action.payload,
        list: null,
      };

    case types.GET_COLLECTION_COMPLEXES_SUCCESS:
      return { list: action.payload, isLoading: false };

    case types.UPDATE_COLLECTION_COMPLEXES_LOADING:
      return { ...state, isUpdating: true };

    case types.UPDATE_COLLECTION_COMPLEXES_SUCCESS:
      const updateDComplex = action.payload;

      const list = state.list.map((item) => {
        if (item.uuid === updateDComplex.uuid) {
          return updateDComplex;
        }
        return item;
      });
      return { ...state, list, isUpdating: false };

    case types.UPDATE_COLLECTION_COMPLEXES_FAILED:
      return { ...state, isUpdating: false, ...action.payload };
    default:
      return state;
  }
};
