import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import ComplexItem from "./ComplexItem";
import UpdateComplex from "./UpdateComplex";

const ZonesList = ({ zones, select, editor, selected, _onSelectComplex }) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState({});

  return (
    <div>
      {zones.map(
        ({ zone_uuid, name, primary, auxiliary, premium, overflow }, index) => (
          <div key={index} className={classes.row} spacing={2}>
            <div className={classes.col}>
              <p className={classes.title}>{name}</p>
            </div>

            <div className={classes.col}>
              {primary &&
                primary.map((complex, index) => (
                  <ComplexItem
                    key={index}
                    select={select}
                    editor={editor}
                    selected={selected.find((f) => f.uuid === complex.uuid)}
                    _onSelectComplex={() =>
                      _onSelectComplex(complex, zone_uuid)
                    }
                    {...complex}
                    _onEdit={() => {
                      setActive({
                        zone_uuid: zone_uuid,
                        zone_name: name,
                        complex_type: "primary",
                        ...complex,
                      });
                      setEdit(true);
                    }}
                  />
                ))}
            </div>

            <div className={classes.col}>
              {auxiliary &&
                auxiliary.map((complex, index) => (
                  <ComplexItem
                    key={index}
                    select={select}
                    selected={selected.find((f) => f.uuid === complex.uuid)}
                    _onSelectComplex={() =>
                      _onSelectComplex(complex, zone_uuid)
                    }
                    {...complex}
                    editor={editor}
                    _onEdit={() => {
                      setActive({
                        zone_uuid: zone_uuid,
                        zone_name: name,
                        complex_type: "auxiliary",
                        ...complex,
                      });
                      setEdit(true);
                    }}
                  />
                ))}
            </div>

            <div className={classes.col}>
              {premium &&
                premium.map((complex, index) => (
                  <ComplexItem
                    key={index}
                    select={select}
                    selected={selected.find((f) => f.uuid === complex.uuid)}
                    _onSelectComplex={() =>
                      _onSelectComplex(complex, zone_uuid)
                    }
                    {...complex}
                    editor={editor}
                    _onEdit={() => {
                      setActive({
                        zone_uuid: zone_uuid,
                        zone_name: name,
                        complex_type: "premium",
                        ...complex,
                      });
                      setEdit(true);
                    }}
                  />
                ))}
            </div>

            <div className={classes.col}>
              {overflow &&
                overflow.map((complex, index) => (
                  <ComplexItem
                    key={index}
                    select={select}
                    selected={selected.find((f) => f.uuid === complex.uuid)}
                    _onSelectComplex={() =>
                      _onSelectComplex(complex, zone_uuid)
                    }
                    {...complex}
                    editor={editor}
                    _onEdit={() => {
                      setActive({
                        zone_uuid: zone_uuid,
                        zone_name: name,
                        complex_type: "overflow",
                        ...complex,
                      });
                      setEdit(true);
                    }}
                  />
                ))}
            </div>
          </div>
        )
      )}

      <UpdateComplex
        open={edit}
        handleDialog={() => setEdit(false)}
        close={() => setEdit(false)}
        data={active}
      />
    </div>
  );
};

ZonesList.propTypes = {
  zones: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    padding: 5,
    flexDirection: "row",
    borderBottom: "1px solid #d1d1d1",
  },

  col: {
    flex: 1,
  },
  title: {
    margin: 0,
    fontSize: 13,
    fontWeight: 600,
  },
}));

export default ZonesList;
