import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Container,
  Dialog,
  TextField,
  Typography,
} from "@material-ui/core/";
import Loader from "react-loader-spinner";
import { makeStyles } from "@material-ui/core/styles";
import { login, resetPassword } from "../redux/actions/auth.actions";
import logo from "../assets/dadv2.png";

const Login = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [email, setEmail] = useState("");
  const [npassword, setnPassword] = useState("");
  const [openEmailDialog, setOpenEmailDialog] = useState(false);

  async function onLogin(event) {
    dispatch(login({ email: username, password }));
  }

  async function onPasswordReset(event) {
    event.preventDefault();
    dispatch(resetPassword({ email, password: npassword }));
  }

  useEffect(() => {
    if (auth && auth.emailSent) {
      setOpenEmailDialog(false);
    }
  }, [auth]);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <div style={{ textAlign: "center" }}>
          <img src={logo} width="150px" alt="logo" />
        </div>
        <div className={classes.paper}>
          <div className={classes.form}>
            {auth?.emailSent && (
              <Typography>
                <span style={{ color: "green" }}>{auth.message}</span>
              </Typography>
            )}

            {auth?.error && !auth?.error.email && !auth?.error.password && (
              <Typography
                style={{
                  color: "#D71920",
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                {auth.error}
              </Typography>
            )}

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="email"
              autoFocus
              InputProps={{
                classes: {
                  notchedOutline:
                    auth?.error && auth?.error.username
                      ? classes.notchedOutline
                      : null,
                },
              }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputProps={{
                classes: {
                  notchedOutline:
                    auth?.error && auth?.error.password && !openEmailDialog
                      ? classes.notchedOutline
                      : null,
                },
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            {/* <Typography onClick={() => setOpenEmailDialog(true)}>
              Forget Password?
              <span
                style={{ marginLeft: 5, color: "#D71920", cursor: "pointer" }}
              >
                Reset
              </span>
            </Typography> */}

            {!openEmailDialog && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  backgroundColor: "#D71920",
                  borderRadius: 0,
                  boxShadow: "none",
                  color: "#ffff",
                }}
                className={classes.submit}
                onClick={() => onLogin()}
              >
                {auth && auth.isLoading ? (
                  <Loader type="Oval" color="white" width="20" height="20" />
                ) : (
                  "login"
                )}
              </Button>
            )}
          </div>
        </div>
      </Container>
      <Dialog
        onClose={() => setOpenEmailDialog(false)}
        open={openEmailDialog}
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: 0,
            boxShadow: "none",
            minWidth: 400,
            paddingTop: 15,
            top: -70,
            backgroundColor: "#ccc",
          },
        }}
      >
        <div style={{ paddingLeft: 8, paddingRight: 8 }}>
          {auth?.error && !auth?.error.email && !auth?.error.password && (
            <Typography
              style={{
                color: "#D71920",
                marginBottom: 10,
                textAlign: "center",
              }}
            >
              {auth.error}
            </Typography>
          )}

          <TextField
            variant="outlined"
            size="small"
            required
            fullWidth
            label="Email"
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {auth?.error && auth?.error.email && (
            <Typography
              style={{
                color: "#D71920",
                marginBottom: 10,
              }}
            >
              {auth.error.email}
            </Typography>
          )}

          <TextField
            variant="outlined"
            size="small"
            required
            fullWidth
            label="New Password"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginTop: 20 }}
            value={npassword}
            onChange={(e) => setnPassword(e.target.value)}
          />
          {auth?.error && auth?.error.password && (
            <Typography
              style={{
                color: "#D71920",
                paddingTop: 10,
              }}
            >
              {auth.error.password}
            </Typography>
          )}
        </div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          style={{
            backgroundColor: "#9e1318",
            borderRadius: 0,
            boxShadow: "none",
            color: "#ffff",
            marginTop: 20,
          }}
          onClick={onPasswordReset}
        >
          {auth && auth.isEmailSentLoading ? (
            <Loader type="Oval" color="white" width="20" height="20" />
          ) : (
            "Submit"
          )}
        </Button>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#D71920",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  notchedOutline: {
    borderColor: "red !important",
  },
}));

export default Login;
