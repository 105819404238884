/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  makeStyles,
  Select,
  TextField,
} from "@material-ui/core";
import { Droppable } from "react-beautiful-dnd";
import { Call, Visibility } from "@material-ui/icons";
import OrderLogs from "./OrderLogs";
import CommentItem from "./CommentItem";
import { calculateOrderDuration } from "../../utils/calculateOrderDuration";

const delayTimes = [
  "1 mins",
  "2 mins",
  "3 mins",
  "5 mins",
  "10 mins",
  "15 mins",
  "30 mins",
  "45 mins",
  "60 mins",
];

const ActiveTicket = ({
  _id,
  tickets,
  _onSave,
  _onCloseTicket,
  _onEscalate,
  _onCallNow,
}) => {
  const classes = useStyles();
  const [delay, setDelay] = useState("");
  const [comment, setComment] = useState("");
  const [openLogs, setOpenLogs] = useState(false);
  const [ticket, setTicket] = useState("");
  const [order_duration, setOrderDuration] = useState("");
  const [back_pool, setBackPool] = useState(1);

  useEffect(() => {
    if (!ticket) {
      setTicket(tickets[0]);
    }
  }, [tickets]);

  useEffect(() => {
    if (ticket) {
      getOrderDurations();
      const interval = setInterval(() => {
        getOrderDurations();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [ticket]);

  useEffect(() => {
    if (ticket) {
      if (back_pool < 300 && !openLogs) {
        const timer = setInterval(() => {
          setBackPool(back_pool + 1);
        }, 999);

        return () => clearInterval(timer);
      } else {
        if (back_pool === 300 && ticket) {
          _onEscalate("Timed Out");
          setTicket("");
          setBackPool(0);
          setOrderDuration("");
          setOpenLogs(false);
        }
      }
    }
  }, [back_pool, ticket]);

  const getOrderDurations = () => {
    const { duration } = calculateOrderDuration(
      ticket.country_name,
      ticket.order_placed
    );

    setOrderDuration(duration);
  };

  const _onClick = (type) => {
    if (!comment) {
      alert("Comment is required");
    } else {
      if (type === "save") {
        _onSave(comment, delay);
      }

      if (type === "close") {
        _onCloseTicket(comment);
      }

      if (type === "escalate") {
        _onEscalate(comment);
      }

      setDelay("");
      setComment("");
      setTicket();
      setOpenLogs(false);
      setOrderDuration("");
    }
  };

  return (
    <div>
      <h2 className="alert-title">Active Ticket</h2>

      <div className={classes.header}>
        {ticket?._id && (
          <>
            <h3 className={classes.headerTitle}>Ticket #{ticket?.number}</h3>
            <h3 className={classes.headerTitle} style={{ marginLeft: "5%" }}>
              Ticket Ref : {ticket?._id}
            </h3>
            {order_duration && (
              <span style={{ marginLeft: "10%" }}>{order_duration}</span>
            )}
          </>
        )}
      </div>

      <Droppable droppableId={_id}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classes.content}
          >
            {ticket ? (
              <div>
                <h4 className={classes.title} style={{ marginBottom: 7 }}>
                  Previous Comments
                </h4>
                {ticket.comments?.map((comment, index) => (
                  <CommentItem key={index} {...comment} />
                ))}

                <h4 className={classes.title}>Customer Details</h4>
                <p className={classes.text}>{ticket.customer.name}</p>
                <div style={{ display: "inline-flex" }}>
                  <p className={classes.text}>{ticket.customer.phone}</p>
                  <a
                    href={
                      "https://yum.wingu.co.ke/webclient/#/call?phone=" +
                      ticket.customer.phone
                    }
                    target="__blank"
                    style={{ display: "block" }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.btn1}
                      onClick={_onCallNow}
                    >
                      <Call />
                      Call Now
                    </Button>
                  </a>
                </div>
                <p className={classes.text}>{ticket.customer.location}</p>
                <p className={classes.text}>
                  Instructions: {ticket.customer.instructions}
                </p>
                <p className={classes.text}>
                  Complex:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {ticket.complex_name}
                  </span>
                </p>
                <div style={{ display: "inline-flex" }}>
                  <h4 className={classes.text}>
                    Order Number - {ticket.odos_number}
                  </h4>

                  <Button
                    variant="contained"
                    size="small"
                    className={classes.btn1}
                    onClick={() => setOpenLogs(true)}
                  >
                    <Visibility /> View Logs
                  </Button>
                </div>

                <h4 className={classes.text}>
                  Order Ref - {ticket.order_uuid}
                </h4>

                <h4 className={classes.title}>Comment</h4>
                <TextField
                  fullWidth
                  multiline
                  margin="dense"
                  variant="outlined"
                  value={comment}
                  onChange={(event) => {
                    const value = event.target.value;
                    setComment(value);
                  }}
                  name="comment"
                  minRows={3}
                />
                <FormControl
                  className={classes.select}
                  variant="outlined"
                  margin="dense"
                  style={{ marginTop: 7 }}
                >
                  <Select
                    native
                    value={delay}
                    onChange={(event) => {
                      const value = event.target.value;
                      setDelay(value);
                    }}
                    inputProps={{
                      name: "delay_time",
                      id: "delay_time",
                    }}
                  >
                    <option value="">Delay</option>
                    {delayTimes.map((time, index) => (
                      <option key={index} value={time}>
                        {time}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : (
              <div>
                <br />
                <br />
                <br />
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: 52,
                    color: "#cccccc",
                  }}
                >
                  No Active Ticket
                </h1>
              </div>
            )}

            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <div style={{ display: "flex", marginTop: 15 }}>
        <Button
          variant="contained"
          className={classes.btn}
          style={{
            backgroundColor: "#007bff",
          }}
          onClick={() => _onClick("save")}
        >
          Save
        </Button>

        <div style={{ flex: 1 }} />
        <Button
          variant="contained"
          className={classes.btn}
          style={{
            backgroundColor: "#ffd5d7",
            marginRight: 12,
            color: "#d71920",
          }}
          onClick={() => _onClick("escalate")}
        >
          Save and escalate
        </Button>
        <Button
          variant="contained"
          className={classes.btn}
          style={{ backgroundColor: "#d71920" }}
          onClick={() => _onClick("close")}
        >
          Save and close
        </Button>
      </div>

      <OrderLogs
        open={openLogs}
        close={() => setOpenLogs(false)}
        order_uuid={ticket?.order_uuid}
        odos_number={ticket?.odos_number}
        order_placed={ticket?.order_placed}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#000",
    padding: 10,
    marginBottom: 7,
    display: "flex",
    color: "#ffff",
    minHeight: 50,
  },

  headerTitle: {
    margin: 0,
    color: "#fff",
  },

  content: {
    backgroundColor: "#fff",
    height: "70vh",
    overflowY: "scroll",
    border: "1px solid #ec9da0",
    padding: 10,
  },

  title: {
    marginBottom: 3,
    fontSize: 16,
    color: "#000",
  },

  btn: {
    textTransform: "none",
    color: "#fff",
  },

  text: {
    marginTop: 10,
    marginBottom: 0,
  },

  btn1: {
    marginLeft: 8,
    marginTop: 7,
    padding: "3px 6px",
    textTransform: "none",
    height: 24,
    backgroundColor: "#078900",
    color: "#fff",
    fontSize: 10,
    "&:hover": {
      backgroundColor: "#0ba603",
    },

    "& svg": {
      fontSize: 14,
      marginRight: 4,
    },
  },
}));

export default ActiveTicket;
