import { BASE_URL } from "../app.config";

export const updateComplexPayments = async (payments, complex_uud) => {
  try {
    const response = await fetch(
      `${BASE_URL}/complexes/update_payments/${complex_uud}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({ payments }),
      }
    );

    const result = await response.json();

    if (result) {
      return result;
    }

    return false;
  } catch (error) {
    return false;
  }
};
