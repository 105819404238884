/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import {
  ArrowDownward,
  ArrowUpward,
  Refresh,
  SearchOutlined,
} from "@material-ui/icons";
import UserItem from "../components/users/userItem";
import NewUser from "../components/users/newUser";
import UpdateUser from "../components/users/updateUser";
import { useDispatch, useSelector } from "react-redux";
import { AddUser } from "@styled-icons/entypo";
import clsx from "clsx";
import Pagination from "../components/commons/pagination";
import Loader from "react-loader-spinner";
import { getUsers, refrestUsers } from "../redux/actions/users.action";
import { checkToken } from "../utils/check.token";
import { MySelect } from "../components/commons/select";

const headings = ["Name", "Email", "Created", "Signed in", "Role", "", ""];
const filters = ["None", "Disabled", "Agent", "Manager", "Supervisor"];

const Users = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    remote: { country, user_mgt },
    users: rUsers,
  } = useSelector((state) => state);

  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState();
  const [sort, setSort] = useState({ sort_by: "name", order_by: "ascending" });
  const [activeUser, setActiveUser] = useState({});

  const [newUser, setNewUser] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [_users, _setUsers] = useState([]);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    if (!rUsers && country) {
      if (country.name) {
        dispatch(getUsers(country.name.toLowerCase()));
      }
    }
  }, [rUsers, country]);

  useEffect(() => {
    let users = [];
    let _users = rUsers?.users;
    if (_users) {
      if (query) {
        _users =
          _users &&
          _users.filter(
            ({ name, email, role }) =>
              name.toLowerCase().includes(query.toLowerCase()) ||
              email.toLowerCase().includes(query.toLowerCase()) ||
              role.toLowerCase().includes(query.toLowerCase())
          );
      }

      if (filter) {
        const key = Object.keys(filter)[0];
        const value = Object.values(filter)[0];
        if (value && value !== "none") {
          _users = _users && _users.filter((f) => f[key] === value);
        }
      }

      if (sort) {
        _users = _users.sort((a, b) => {
          if (sort.sort_by === "created" || sort.sort_by === "sign_in") {
            if (a[sort.sort_by] && b[sort.sort_by]) {
              if (sort.order_by === "ascending") {
                return new Date(b[sort.sort_by]) - new Date(a[sort.sort_by]);
              } else {
                return new Date(a[sort.sort_by]) - new Date(b[sort.sort_by]);
              }
            }
          } else {
            if (a[sort.sort_by] && b[sort.sort_by]) {
              if (sort.order_by === "ascending") {
                return a[sort.sort_by].localeCompare(b[sort.sort_by]);
              } else {
                return b[sort.sort_by].localeCompare(a[sort.sort_by]);
              }
            }
          }
        });
      }

      _setUsers(_users);

      if (_users) {
        if (page === 1) {
          users = _users?.slice(0, rows);
        } else {
          users = _users?.slice(rows * (page - 1), rows * page);
        }
      }

      setUsers([...users]);
    }
  }, [page, rows, rUsers, query, filter, sort]);

  function _onNext() {
    setPage(page + 1);
  }

  function _onPrev() {
    setPage(page - 1);
  }

  function onFilterChange(value) {
    let _filter = {};
    if (value === "disabled") {
      _filter["disabled"] = true;
    } else {
      _filter["role"] = value;
    }

    setFilter(_filter);
  }

  return (
    <>
      <div className={classes.heading} />
      <div className={classes.main}>
        <Grid container spacing={3}>
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              marginTop: 24,
              marginBottom: 10,
            }}
          >
            <div className={classes.searchbar_container}>
              <div className={classes.searchbar}>
                <TextField
                  variant="outlined"
                  placeholder="search..."
                  size="small"
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            <div className={classes.filter_by}>
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  marginRight: 5,
                  width: "50%",
                }}
              >
                Filter By:
              </p>
              <MySelect
                native={true}
                classes={classes}
                select_items={filters}
                setValue={(value) => onFilterChange(value.toLowerCase())}
                name="filter_by"
                id="filter_by"
              />
            </div>

            <div>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => setNewUser(true)}
                className={classes.addBtn}
              >
                <AddUser className={classes.addIcon} />
                Add User
              </Button>
            </div>

            <div>
              <Button
                variant="text"
                color="primary"
                className={classes.refreshBtn}
                onClick={() => dispatch(refrestUsers(country.name))}
              >
                <Refresh />
              </Button>
            </div>
          </div>
        </Grid>

        <div className={classes.header} spacing={2}>
          {headings.map((title, index) => (
            <div
              key={index}
              className={classes.headerItem}
              style={{
                flex: index < 2 ? 3 : index > 4 ? 1 : 2,
                display: "inline-flex",
              }}
            >
              <p className={classes.title}>{title}</p>
              {title ? (
                sort.order_by === "ascending" ? (
                  <ArrowUpward
                    className={clsx(classes.sortIcon, "sort")}
                    style={{
                      display:
                        sort.sort_by === "signed_in" && title === "Signed in"
                          ? "block"
                          : sort.sort_by === title.toLowerCase() && "block",
                    }}
                    onClick={() =>
                      setSort({
                        sort_by:
                          title === "Signed in"
                            ? "signed_in"
                            : title.toLowerCase(),
                        order_by: "descending",
                      })
                    }
                  />
                ) : (
                  <ArrowDownward
                    className={clsx(classes.sortIcon, "sort")}
                    style={{
                      display:
                        sort.sort_by === "signed_in" && title === "Signed in"
                          ? "block"
                          : sort.sort_by === title.toLowerCase() && "block",
                    }}
                    onClick={() =>
                      setSort({
                        sort_by:
                          title === "Signed in"
                            ? "signed_in"
                            : title.toLowerCase(),
                        order_by: "ascending",
                      })
                    }
                  />
                )
              ) : (
                ""
              )}
            </div>
          ))}
        </div>

        {users.length > 0 ? (
          <div className={classes.content}>
            {users.map((user, index) => (
              <UserItem
                key={index}
                {...user}
                user_mgt={user_mgt}
                _onUpdate={() => {
                  setActiveUser(user);
                  setUpdateUser(true);
                }}
              />
            ))}
          </div>
        ) : rUsers ? (
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
            }}
          >
            {rUsers?.error?.message}
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
            }}
          >
            <Loader type="ThreeDots" color="red" width="60" height="60" />
          </div>
        )}

        {users.length > 0 && (
          <Pagination
            prev={Boolean(page <= 1)}
            next={Boolean(page * rows >= _users?.length)}
            from={(page - 1) * rows + 1}
            to={page * rows >= _users?.length ? _users.length : page * rows}
            total={_users?.length}
            rows={rows}
            _onChangeRows={(rows) => setRows(rows)}
            _onNext={_onNext}
            _onPrev={_onPrev}
          />
        )}
      </div>

      <NewUser
        open={newUser}
        user_mgt={user_mgt}
        country={country}
        _onClose={() => setNewUser(false)}
      />

      <UpdateUser
        open={updateUser}
        user={activeUser}
        user_mgt={user_mgt}
        country={country}
        _onClose={() => setUpdateUser(false)}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    backgroundColor: "#fcfcfc",
    width: "100%",
    minHeight: 40,
  },

  main: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  searchbar_container: {
    backgroundColor: "inherit",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "60%",
  },

  notchedOutline: {
    border: 0,
  },

  searchbar: {
    paddingLeft: theme.spacing(4),
    "& > div": {
      backgroundColor: "#fff",
      width: "100%",
      minHeight: 40,
      borderRadius: 5,
    },
  },

  filter_by: {
    display: "inline-flex",
    alignItems: "center",
    padding: "inherit",
    marginRight: theme.spacing(3),
    width: "20%",
  },
  header: {
    backgroundColor: "#ed1b24",
    width: "100%",
    padding: 5,
    display: "flex",
    flexDirection: "row",
  },

  headerItem: {
    flex: 1,
    "& .sort": {
      display: "none",
    },
    "&:hover .sort": {
      display: "block",
      color: "#82817f",
    },
  },
  title: {
    color: "#fff",
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
  },

  content: {
    backgroundColor: "#fff",
    padding: 7,
    paddingTop: 0,
    miHeight: "65vh",
  },

  addIcon: {
    width: 21,
    marginRight: 6,
  },
  addBtn: {
    marginTop: theme.spacing(2),
    textTransform: "none",
    marginRight: theme.spacing(3),
  },
  refreshBtn: {
    marginTop: theme.spacing(2),
  },
  select: {
    backgroundColor: "#fff",
  },

  label: {
    marginTop: 15,
    marginBottom: 3,
    marginLeft: 3,
    fontSize: 15,
    fontWeight: 600,
  },
}));
export default Users;
