import React from "react";
import { makeStyles } from "@material-ui/core";
import CommentItem from "./CommentItem";
import moment from "moment";

const ClosedTicketItem = ({ number, comments, assigned_to, closed_at }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.item} style={{ flex: 2.5 }}>
        <p className={classes.text} style={{ fontWeight: 700 }}>
          {number}
        </p>
      </div>

      <div className={classes.item} style={{ flex: 6 }}>
        {comments.map((item, index) => (
          <CommentItem key={index} {...item} />
        ))}
      </div>

      <div className={classes.item} style={{ flex: 3 }}>
        <p className={classes.text}>{assigned_to}</p>
      </div>

      <div className={classes.item} style={{ flex: 2, textAlign: "right" }}>
        <p className={classes.text}>
          {moment(closed_at).format("DD-MM-YYYY h:mm")}
        </p>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 5,
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #bfbfbf",
  },

  item: {
    flex: 1,
  },

  text: {
    fontSize: 15,
    margin: "7px 0px",
  },

  state: {
    display: "inline-flex",
    marginLeft: 0,
    marginTop: 6,
    border: "1px solid #000",
    padding: "3px 4px",
    borderRadius: 20,
    width: "fit-content",
    "& svg": {
      width: 14,
      marginRight: 2,
    },
    "& p": {
      fontSize: 10.5,
      margin: 0,
    },
  },
}));
export default ClosedTicketItem;
