/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ClosedTicketItem from "./ClosedTicketItem";
import firebase from "../../firebase";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const headings = ["Number", "Comments", "Closed by", "Date Closed"];
const flexes = [2.5, 6, 3, 2];
const ticketsRef = firebase.database().ref("tickets");

const ClosedTickets = (props) => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const [tickets, setTickets] = useState("");

  useEffect(() => {
    if (props.open && !tickets) {
      ticketsRef
        .orderByChild("status")
        .equalTo("closed")
        .on("value", (snapshot) => {
          let allTickets = [];
          snapshot.forEach((snap) => {
            if (snap.val()) {
              if (snap.val().country_name === user.country) {
                allTickets.push({ _id: snap.key, ...snap.val() });
              }
            }
          });

          setTickets(allTickets);
        });
    }
  }, [props.open]);

  return (
    <div className={classes.root}>
      <Dialog
        open={props.open}
        className={classes.root}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="md"
        PaperProps={{
          classes: {
            root: classes.paper,
          },
        }}
      >
        <DialogTitle className={classes.header}>
          <div align="right" style={{ marginBottom: -40 }}>
            <IconButton onClick={props.close} className={classes.closeBtn}>
              <Close />
            </IconButton>
          </div>
          Closed Tickets
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.header1} spacing={2}>
            {headings.map((title, index) => (
              <div
                key={index}
                className={classes.headerItem}
                style={{
                  flex: flexes[index],
                  textAlign: index === headings.length - 1 ? "right" : "",
                }}
              >
                <p className={classes.title}>{title}</p>
              </div>
            ))}
          </div>

          {tickets ? (
            tickets.map((item, index) => (
              <ClosedTicketItem key={index} {...item} />
            ))
          ) : (
            <div
              style={{
                width: "fit-content",
                margin: "70px auto",
              }}
            >
              <CircularProgress size={50} color="primary" />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "8px 12px",
    fontWeight: 700,
  },

  header1: {
    backgroundColor: "#000",
    width: "100%",
    padding: 6,
    display: "flex",
    flexDirection: "row",
  },

  headerItem: {
    flex: 1,
  },
  title: {
    color: "#fff",
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
  },
  content: {
    borderTop: "3px #fff  solid",
  },
  closeBtn: {},
}));
export default ClosedTickets;
