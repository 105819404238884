import * as types from "../action.types";

export const usersReducer = (state, action) => {
  switch (action.type) {
    case types.GET_USERS_LOADING:
      return { isLoading: true, users: null };

    case types.REFRESH_USERS_LOADING:
      return { isLoading: true, users: null };

    case types.GET_USERS_FAILED:
      return {
        isLoading: false,
        error: action.payload,
        users: null,
      };

    case types.GET_USERS_SUCCESS:
      return { users: action.payload, isLoading: false };

    case types.REFRESH_USERS_SUCCESS:
      return { users: action.payload, isLoading: false };

    case types.DISABLE_USER_SUCCESS:
      const users = state.users;

      const updated_user = action.payload;
      users.map((user) => {
        if (user.email === updated_user.email) {
          user.disabled = updated_user.disabled;
        }

        return user;
      });

      return { users, isLoading: false, isUpdated: true };

    case types.ADD_USER_SUCCESS:
      let newUsers = [];

      if (state.users) {
        newUsers = state.users;
      }

      newUsers.unshift(action.payload);

      return { users: newUsers, isLoading: false, isAdded: true };

    case types.UPDATE_USER_SUCCESS:
      let uUsers = state.users;
      uUsers.forEach((user, i) => {
        if (user.uuid === action.payload.uuid) {
          uUsers[i] = action.payload;
        }
      });

      return { users: uUsers, isLoading: false, isUserUpdated: true };

    default:
      return state;
  }
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case types.ADD_USER_LOADING:
      return { isLoading: true, user: null };

    case types.ADD_USER_FAILED:
      return {
        isLoading: false,
        error: action.payload,
      };

    case types.ADD_USER_SUCCESS:
      return { user: action.payload, isLoading: false };

    case types.UPDATE_USER_SUCCESS:
      return { user: action.payload, isLoading: false, isUpdated: true };

    default:
      return state;
  }
};

export const disableUserReducer = (state, action) => {
  switch (action.type) {
    case types.DISABLE_USER_LOADING:
      return { isLoading: true, user: action.payload };

    case types.DISABLE_USER_FAILED:
      return {
        isLoading: false,
        message: action.payload,
      };

    case types.DISABLE_USER_SUCCESS:
      return { isLoading: false, user: action.payload };

    default:
      return state;
  }
};

export const resetPasswordReducer = (state, action) => {
  switch (action.type) {
    case types.RESET_USER_PASSWORD_LOADING:
      return { isLoading: true, user: action.payload };

    case types.RESET_USER_PASSWORD_FAILED:
      return {
        isLoading: false,
        message: action.payload,
      };

    case types.RESET_USER_PASSWORD_SUCCESS:
      return { isLoading: false, user: action.payload };

    default:
      return state;
  }
};
