import * as types from "../action.types";
import { BASE_URL } from "../../app.config";

export function getStocks(complex_uuid, brand_uuid) {
  return async (dispatch) => {
    dispatch({ type: types.GET_STOCK_LOADING });

    const response = await fetch(
      `${BASE_URL}/stock_list/${complex_uuid}/${brand_uuid}`,
      {
        method: "GET",
      }
    );

    const result = await response.json();

    if (result) {
      dispatch({
        type: types.GET_STOCK_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.GET_STOCK_FAILED,
        payload: result,
      });
    }
  };
}

export function disableProduct(data) {
  return async (dispatch) => {
    dispatch({ type: types.DISABLE_PRODUCT_LOADING });

    const response = await fetch(`${BASE_URL}/stock/disable_product`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result.success) {
      dispatch({
        type: types.DISABLE_PRODUCT_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: types.DISABLE_PRODUCT_FAILED,
        payload: result,
      });
    }
  };
}
