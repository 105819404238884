/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Routes from "./routes";
import { getZonesComplexes } from "./redux/actions/zone.complex.actions";
import remoteConfig from "./utils/remote.config";
import { checkToken } from "./utils/check.token";
import { getCollectionComplexes } from "./redux/actions/complexes.action";
import { GET_REMOTE_CONFIG_SUCCESS } from "./redux/action.types";

checkToken();

function App() {
  const dispatch = useDispatch();
  const [user_mgt, setMgt] = useState({ user_roles: [], who_edits: [] });
  const [country, setCountry] = useState("");

  const getData = async () => {
    try {
      await remoteConfig.fetchAndActivate().catch((err) => {});

      const _country = JSON.parse(
        remoteConfig.getValue("countries").asString()
      );

      const user_mgt = JSON.parse(remoteConfig.getValue("user_mgt").asString());

      if (_country.name) {
        dispatch(getCollectionComplexes(_country.name));
        dispatch(getZonesComplexes(_country.name.toLowerCase()));

        dispatch({
          type: GET_REMOTE_CONFIG_SUCCESS,
          payload: { country: _country, user_mgt },
        });

        setCountry(_country);
        setMgt(user_mgt);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return <Routes country={country} user_mgt={user_mgt} />;
}

export default App;
