/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  Select,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  Checkbox,
  Box,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { CheckBox, CheckBoxOutlineBlank, Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { updateCollectionComplexes } from "../../redux/actions/complexes.action";
import { updateComplexPayments } from "../../utils/complexPayments";
import { BASE_URL } from "../../app.config";
import ClosureReason from "./ClosureReason";

const deliveryTimes = [
  "20 - 30 mins",
  "30 - 45 mins",
  "45 - 60 mins",
  "60 - 75 mins",
  "75 - 90 mins",
  "90 - 105 mins",
  "105 - 120 mins",
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UpdateCollectionComplex = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const isUpdating = useSelector(
    (state) => state.collection_complexes?.isUpdating
  );

  const [data, setData] = useState({
    zone_name: "",
    name: "",
    complex_type: "",
    delivery_time: "",
    pizza_delivery_time: "",
    closed_until: "",
  });
  const [payments, setPayments] = useState([]);

  const [opening, setOpening] = useState(moment());
  const [closing, setClosing] = useState(moment());
  const [updating, setUpdating] = useState(false);
  const [reason, setReason] = useState("");
  const [other, setOther] = useState("");
  const [showReasons, setShowReasons] = useState(false);

  useEffect(() => {
    setData({ ...props.data });

    if (props.open && props.data.opening_times) {
      const times = props.data.opening_times.split(" - ");
      times[1] = times[1].replace("hrs", "");

      if (!times[0].includes(":")) {
        times[0] = spliceSlice(times[0], 2, 0, ":");
        times[1] = spliceSlice(times[1], 2, 0, ":");
      }

      const today = moment().format("YYYY-MM-DD");

      const _opening = moment(today + " " + times[0]).format(
        "YYYY-MM-DD HH:mm"
      );

      const _closing = moment(today + " " + times[1]).format(
        "YYYY-MM-DD HH:mm"
      );

      setOpening(_opening);
      setClosing(_closing);
    }

    if (props.open && !updating) {
      _onGetComplexPayments();
    }
  }, [props.open]);

  useEffect(() => {
    if (isUpdating && !updating) {
      setUpdating(true);
    } else if (!isUpdating && updating) {
      setUpdating(false);
      props.close();
    }
  }, [isUpdating, updating]);

  useEffect(() => {
    if (reason) {
      let closure_reason = reason;
      let closed = true;

      if (other) {
        closure_reason = closure_reason + " - " + other;
      }

      if (reason === "Other" && !other) {
        closed = false;
      }

      if (closed) {
        setData((prevState) => {
          return {
            ...prevState,
            open: "closed",
            closure_reason,
          };
        });
      }
    }
  }, [reason, other]);

  async function _onGetComplexPayments() {
    if (props.data.uuid) {
      const response = await fetch(
        `${BASE_URL}/complexes/payments/${props.data.uuid}`,
        {
          method: "GET",
        }
      );

      const result = await response.json();

      if (result) {
        setPayments([...result]);
      }
    }
  }

  function _onChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setData((prevState) => {
      return { ...prevState, [name]: value };
    });
  }

  function _onPaymentChange(index) {
    payments[index].available = !payments[index].available;

    setPayments([...payments]);
  }

  function _onOpenClose() {
    if (user.role === "manager" || user.role === "admin") {
      if (data.open === "open") {
        setShowReasons(true);
      } else {
        setReason("");
        setOther("");

        setData((prevState) => {
          return {
            ...prevState,
            open: prevState.open === "open" ? "closed" : "open",
          };
        });
      }
    }
  }

  async function _onSubmit() {
    if (!updating) {
      setUpdating(true);

      if (data.opening_times) {
        data.opening_times =
          opening.split(" ")[1].replace(":", "") +
          " - " +
          closing.split(" ")[1].replace(":", "");
      }

      if (user) {
        data.agent = user;
      }

      dispatch(updateCollectionComplexes(data));

      updateComplexPayments(payments, data.uuid);
    }
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={props.open}
        className={classes.root}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          classes: {
            root: classes.paper,
          },
        }}
      >
        <DialogTitle className={classes.header}>
          <div align="right" style={{ marginBottom: -40 }}>
            <IconButton
              onClick={() => {
                props.close();
                setData({
                  zone_name: "",
                  name: "",
                  complex_type: "",
                  delivery_time: "",
                  pizza_delivery_time: "",
                });
                setReason("");
                setOther("");
              }}
              className={classes.closeBtn}
            >
              <Close />
            </IconButton>
          </div>
          {data.name}
          <br />
          <p>{data.zone_name}</p>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <form>
            <h3 className={classes.h3}>Open/Closed</h3>
            <div style={{ display: "inline-flex" }}>
              <FormControlLabel
                control={
                  <MySwitch
                    checked={Boolean(data.open === "open")}
                    onChange={_onOpenClose}
                  />
                }
              />
              {reason && (
                <p className={classes.h3}>
                  <label style={{ fontWeight: "bold" }}>Closure Reason:</label>{" "}
                  {reason}
                </p>
              )}
            </div>
            {data.open === "closed" && (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <strong style={{ marginRight: 4 }}>Close Until: </strong>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    margin="dense"
                    ampm={false}
                    autoOk={true}
                    format="DD-MM-YYYY HH:mm"
                    value={data.closed_until}
                    onChange={(date) => {
                      setData((prevState) => {
                        return {
                          ...prevState,
                          closed_until: moment(date).format("YYYY-MM-DD HH:mm"),
                        };
                      });
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            )}
            <h3 className={classes.h3}>Complex</h3>
            <TextField
              disabled={true}
              fullWidth
              value={data.name}
              name="name"
              onChange={_onChange}
              variant="outlined"
              label="Complex"
              size="small"
              className={classes.input}
            />
            <TextField
              disabled={true}
              fullWidth
              value={data.type}
              name="type"
              onChange={_onChange}
              variant="outlined"
              label="Complex Type"
              size="small"
              className={classes.input}
            />
            <br />
            <br />
            <Grid container spacing={1}>
              <Grid item md={3}>
                <p className={classes.label}>Collection Time</p>
                <FormControl
                  className={classes.select}
                  variant="outlined"
                  margin="dense"
                  style={{ marginTop: 0 }}
                >
                  <Select
                    native
                    value={data.collection_time}
                    onChange={_onChange}
                    inputProps={{
                      name: "collection_time",
                      id: "collection_time",
                    }}
                  >
                    <option value="">-- Select Time --</option>
                    {deliveryTimes.map((time, index) => (
                      <option key={index} value={time}>
                        {time}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  className={classes.check}
                  control={
                    <GreenCheckbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      checked={data.pizza_inn_delay}
                      onChange={() =>
                        setData((prevState) => {
                          return {
                            ...prevState,
                            pizza_inn_delay: !prevState.pizza_inn_delay,
                          };
                        })
                      }
                      name="pizza-delay"
                    />
                  }
                  label="Pizza Delay"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  className={classes.check}
                  control={
                    <GreenCheckbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      checked={data.kitchen_delay}
                      onChange={() =>
                        setData((prevState) => {
                          return {
                            ...prevState,
                            kitchen_delay: !prevState.kitchen_delay,
                          };
                        })
                      }
                      name="kitchen-delay"
                    />
                  }
                  label="Kitchen Delay"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            <p className={classes.hoursLabel}>Operating Hours</p>
            <Grid container spacing={3} style={{ width: "100%" }}>
              <Grid item xs={6}>
                <div style={{ marginTop: 14 }} />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardTimePicker
                    fullWidth
                    disableToolbar
                    className="opening-time"
                    variant="inline"
                    inputVariant="outlined"
                    margin="dense"
                    id="time-picker-inline"
                    label="Opening time"
                    ampm={false}
                    autoOk={true}
                    value={opening}
                    onChange={(date) => {
                      const time = moment(date).format("YYYY-MM-DD HH:mm");
                      setOpening(time);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={6}>
                <div style={{ marginTop: 14 }} />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardTimePicker
                    fullWidth
                    disableToolbar
                    className="opening-time"
                    variant="inline"
                    inputVariant="outlined"
                    margin="dense"
                    id="time-picker-inline"
                    label="Closing time"
                    ampm={false}
                    autoOk={true}
                    value={closing}
                    onChange={(date) => {
                      const time = moment(date).format("YYYY-MM-DD HH:mm");
                      setClosing(time);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <br />
            <br />
            <h3>Brands</h3>
            <Grid container spacing={1}>
              {data?.brands?.map(({ logo, available }, index) => (
                <Grid key={index} item sm={3} lg={2}>
                  <div
                    className={classes.brandDiv}
                    style={{
                      backgroundColor: !available && "grey",
                    }}
                  >
                    <img
                      alt=""
                      src={logo.en}
                      width="100%"
                      style={{
                        opacity: !available && 0.3,
                      }}
                    />

                    <FormControlLabel
                      className={classes.brandSwitch}
                      control={
                        <MySwitch
                          checked={available}
                          onChange={() =>
                            setData((prevState) => {
                              prevState.brands[index].available = !available;
                              return {
                                ...prevState,
                              };
                            })
                          }
                        />
                      }
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
            <br />
            <h3>Payments</h3>
            <Grid container spacing={1}>
              {payments.map(({ name, available }, index) => (
                <Grid key={index} item sm={4} lg={4}>
                  <div
                    style={{
                      display: "inline-flex",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <MySwitch
                          checked={available}
                          onChange={() => _onPaymentChange(index)}
                        />
                      }
                    />
                    <p>{name.en}</p>
                  </div>
                </Grid>
              ))}
            </Grid>
            <br />
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  disabled={updating}
                  className={classes.btn}
                  color="primary"
                  onClick={props.close}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item>
                <Button
                  onClick={_onSubmit}
                  variant="contained"
                  className={classes.btn}
                  color="secondary"
                >
                  {updating ? (
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: -2,
                      }}
                    >
                      <Loader type="Oval" color="#fff" width="20" height="20" />
                    </div>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Grid>
            </Grid>
            <br />
          </form>
        </DialogContent>
      </Dialog>

      <ClosureReason
        open={showReasons}
        close={() => setShowReasons(false)}
        selected={reason}
        setOther={(value) => setOther(value)}
        other={other}
        onSelect={(value) => {
          if (value === "Other" && value === reason) {
            setOther("");
          }

          setReason(value === reason ? "" : value);
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    // backgroundColor: "#f5f5f5", // "#DCDBDB",
  },
  header: {
    backgroundColor: "#c71c24",
    textAlign: "center",
    padding: "8px 12px",
    color: "#fff",

    "& p": {
      margin: 0,
      fontSize: 15,
      textAlign: "left",
    },
  },

  brandDiv: {
    width: "100%",
    position: "relative",
  },
  brandSwitch: {
    position: "absolute",
    top: -5,
    right: -19,
  },
  h3: {
    marginBottom: 5,
  },

  content: {
    borderTop: "3px #fff  solid",
  },

  btn: {
    textTransform: "none",
  },

  input: {
    backgroundColor: "#fff",
    marginTop: 20,
  },

  select: {
    backgroundColor: "#fff",

    // "& select": {
    //   minWidth: 10,
    // },
  },

  label: {
    marginTop: 15,
    marginBottom: 3,
    marginLeft: 3,
    fontSize: 15,
    fontWeight: 600,
  },

  check: {
    marginTop: 45,
    "& .MuiTypography-body1": {
      fontSize: "0.85rem",
    },

    "& .MuiCheckbox-root": {
      padding: 4,
    },
  },
  hoursLabel: {
    fontSize: 17,
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 3,
  },

  closeBtn: {
    color: "#fff",
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: "#45484d",
    "&$checked": {
      color: "#099c40",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const MySwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#099c40",
    },
    "&$checked + $track": {
      backgroundColor: "#099c40",
    },
  },
  checked: {},
  track: {},
})(Switch);

function spliceSlice(str, index, count, add) {
  if (index < 0) {
    index = str.length + index;
    if (index < 0) {
      index = 0;
    }
  }

  return str.slice(0, index) + (add || "") + str.slice(index + count);
}

export default UpdateCollectionComplex;
