import * as types from "../action.types";
import { BASE_URL } from "../../app.config";

export function getComplexes(country) {
  return async (dispatch) => {
    dispatch({ type: types.GET_COMPLEXES_LOADING });

    const response = await fetch(`${BASE_URL}/complexes?country=${country}`, {
      method: "GET",
    });

    const result = await response.json();

    if (!result.message) {
      dispatch({
        type: types.GET_COMPLEXES_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.GET_COMPLEXES_FAILED,
        payload: result,
      });
    }
  };
}

export function getCollectionComplexes(country) {
  return async (dispatch) => {
    dispatch({ type: types.GET_COLLECTION_COMPLEXES_LOADING });

    const response = await fetch(
      `${BASE_URL}/collection_complexes/${country}`,
      {
        method: "GET",
      }
    );

    const result = await response.json();

    if (!result.message) {
      dispatch({
        type: types.GET_COLLECTION_COMPLEXES_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.GET_COLLECTION_COMPLEXES_FAILED,
        payload: result,
      });
    }
  };
}

export function updateCollectionComplexes(data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_COLLECTION_COMPLEXES_LOADING });

    try {
      const response = await fetch(`${BASE_URL}/update_complex/${data.type}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (result.updated) {
        dispatch({
          type: types.UPDATE_COLLECTION_COMPLEXES_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: types.UPDATE_COLLECTION_COMPLEXES_FAILED,
          payload: result,
        });
      }
    } catch (error) {
      dispatch({
        type: types.UPDATE_COLLECTION_COMPLEXES_FAILED,
        payload: error,
      });
    }
  };
}
