import React, { useEffect, useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import CustomerAddressesList from "../components/customers/customerAddressesList";
import { Link, useParams } from "react-router-dom";
import {
  getCustomer,
  getCustomerAddresses,
} from "../redux/actions/customers.action";

const customer_details_headings = [
  "Customer",
  "Delivery",
  "In Store",
  "Kerbside",
];
const CustomerDetails = (props) => {
  const classes = useStyles();

  const _customer = useSelector((state) => state.customer);

  const [customer, setCustomer] = useState({});

  const { phone } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      _customer &&
      _customer.addresses &&
      _customer.details?.phone === phone
    ) {
      setCustomer(_customer);
    }
  }, [_customer, phone]);

  useEffect(() => {
    if (phone) {
      dispatch(getCustomerAddresses(phone));
      dispatch(getCustomer(phone));
    } else {
      props.history.push("/customers");
    }
  }, [phone, dispatch, props.history]);

  return (
    <>
      <div className={classes.heading} style={{ display: "inline-flex" }}>
        <Link to="/customers">
          <IconButton className={classes.backIcon}>
            <ArrowBack />
          </IconButton>
        </Link>

        <div style={{ fontWeight: "bold", paddingTop: 12, fontSize: 15 }}>
          Customer Details
        </div>
      </div>
      <div className={classes.main} style={{ marginTop: 20 }}>
        <div className={classes.header} spacing={2}>
          {customer_details_headings.map((title, index) => (
            <div key={index} className={classes.headerItem} style={{ flex: 2 }}>
              <p className={classes.title}>{title}</p>
            </div>
          ))}
        </div>

        <div className={classes.content}>
          {customer.addresses ? (
            <CustomerAddressesList customer={customer} />
          ) : (
            <div
              style={{
                textAlign: "center",
                marginTop: 20,
              }}
            >
              <Loader type="ThreeDots" color="red" width="60" height="60" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    backgroundColor: "#fcfcfc",
    width: "100%",
    minHeight: 40,
  },

  backIcon: {
    color: "#000",
    fontSize: 15,
    "&:hover": {
      display: "block",
      backgroundColor: "#fcfcfc",
    },
  },

  main: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  header: {
    backgroundColor: "#ed1b24",
    width: "100%",
    padding: 5,
    display: "flex",
    flexDirection: "row",
  },

  headerItem: {
    flex: 1,
  },
  title: {
    color: "#fff",
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
  },

  content: {
    backgroundColor: "#fff",
    padding: 7,
    paddingTop: 0,
    miHeight: "65vh",
  },

  refreshBtn: {
    marginTop: theme.spacing(2.3),
  },
}));

export default CustomerDetails;
