import React, { useEffect, useState } from "react";
import {
  Checkbox,
  makeStyles,
  FormControlLabel,
  Switch,
  withStyles,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import { Search } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { disableCustomer } from "../../redux/actions/customers.action";
import { Link } from "react-router-dom";

const CustomerItem = ({ name, email, phone, created, active, _open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [_active, setActive] = useState(true);

  useEffect(() => {
    setActive(active);
  }, [active]);

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: !active ? "#f0f0f0" : "" }}
    >
      <div className={classes.item} style={{ flex: 4 }}>
        <p className={classes.text}>{name}</p>
      </div>

      <div className={classes.item} style={{ flex: 4 }}>
        <p className={classes.text}>{phone}</p>
      </div>

      <div className={classes.item} style={{ flex: 4 }}>
        <p className={classes.text}>{email}</p>
      </div>

      <div className={classes.item} style={{ flex: 4 }}>
        <p className={classes.text}>{created}</p>
      </div>

      <div className={classes.item} style={{ flex: 1 }}>
        <Link to={`/customer/${phone}`}>
          <IconButton className={clsx(classes.editBtn, "edit")}>
            <Search />
          </IconButton>
        </Link>
      </div>

      <div className={classes.item} style={{ flex: 1 }}>
        <FormControlLabel
          control={
            <MySwitch
              checked={_active}
              onChange={() => {
                setActive(!_active);
                dispatch(
                  disableCustomer({
                    phone,
                    active: !_active,
                  })
                );
              }}
            />
          }
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 5,
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #bfbfbf",

    "&:hover": {
      backgroundColor: "#EBFCFF",
    },

    "& .edit": {
      display: "none",
    },

    "&:hover .edit": {
      display: "block",
    },
  },

  btn: {
    borderRadius: 20,
    textTransform: "none",
    fontSize: 12,
    backgroundColor: "#f0f0f0",
    color: "#000",
    marginRight: 22,

    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },

  item: {
    flex: 1,
  },

  text: {
    fontSize: 15,
    margin: "7px 0px",
  },

  editBtn: {
    padding: 2,
    borderRadius: 0,
    textTransform: "none",
    fontSize: 10,
    backgroundColor: "#595757",
    color: "#fff",
    marginRight: 22,

    "&:hover": {
      backgroundColor: "#595757",
    },
  },
}));

const MySwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#099c40",
    },
    "&$checked + $track": {
      backgroundColor: "#099c40",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default CustomerItem;
