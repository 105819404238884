/* eslint-disable no-useless-concat */
import moment from "moment";

export const calculateOrderDuration = (country_name, order_placed) => {
  let country_tz;

  if (country_name.toLowerCase() === "kenya") {
    country_tz = `Africa/Nairobi`;
  } else {
    country_tz = `Africa/Harare`;
  }

  const placed = moment(order_placed).format("YYYY-MM-DD HH:mm:ss");
  const current = moment().tz(country_tz).format("YYYY-MM-DD HH:mm:ss");

  const hrs = moment(current).diff(placed, "hours");
  const mins = moment(current).diff(placed, "minutes");
  const _duration = moment.duration(moment(current).diff(placed));
  const secs = _duration.asSeconds() % 60;

  let duration;
  if (hrs > 0) {
    if (mins % 60 > 0) {
      duration = hrs + "hr" + ":" + (mins % 60) + "m";
    } else {
      duration = hrs + "hr";
    }
  } else {
    if (mins > 0) {
      duration = mins + " mins";
    } else {
      duration = secs + " secs";
    }
  }

  return { hrs, mins, secs, duration };
};
