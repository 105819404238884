import * as types from "../action.types";
import { BASE_URL } from "../../app.config";

export function getBrandMenu(url) {
  return async (dispatch) => {
    dispatch({ type: types.GET_MENU_LOADING });

    const response = await fetch(`${BASE_URL}/${url}`, {
      method: "GET",
    });

    const result = await response.json();

    if (typeof result === "object") {
      dispatch({
        type: types.GET_MENU_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.GET_MENU_FAILED,
        payload: result,
      });
    }
  };
}

export function updateBrandComplexMenu(url, data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_USER_LOADING });

    const response = await fetch(`${BASE_URL}/${url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result.success) {
      dispatch({
        type: types.UPDATE_MENU_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.UPDATE_MENU_FAILED,
        payload: result,
      });
    }
  };
}
