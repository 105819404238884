import {
  AUTH_FAILED,
  AUTH_LOADING,
  RESET_EMAIL_LOADING,
  RESET_EMAIL_SUCCESS,
  RESET_EMAIL_FAILED,
  SET_CURRENT_USER,
} from "../action.types";
import { BASE_URL } from "../../app.config";
import jwtDecode from "jwt-decode";

export function login(data) {
  return async (dispatch) => {
    dispatch({ type: AUTH_LOADING });

    const response = await fetch(`${BASE_URL}/agent/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    if (result.token) {
      const decoded = jwtDecode(result.token);

      localStorage.setItem("jwTtoken", result.token);

      dispatch(setCurrentUser(decoded));

      if (decoded.role === "site") {
        window.location.replace("/stock-outs");
      } else {
        window.location.replace("/delivery-complexes");
      }
    } else {
      dispatch({ type: AUTH_FAILED, payload: result });
    }
  };
}

export function resetPassword(data) {
  return async (dispatch) => {
    dispatch({ type: RESET_EMAIL_LOADING });

    const response = await fetch(`${BASE_URL}/agent/reset_password`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result === "Password reset successfully") {
      dispatch({
        type: RESET_EMAIL_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: RESET_EMAIL_FAILED,
        payload: result,
      });
    }
  };
}

export function logout() {
  localStorage.removeItem("jwTtoken");
  window.location.replace("/login");
}

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
