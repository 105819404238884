import React, { useState } from "react";
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  List,
  Checkbox,
  Paper,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const Group = (props) => {
  const classes = useStyles();

  const [selectedItems, setSelectedItems] = useState([]);
  const [updatedItems, setUpdatedItems] = useState([]);
  const [activeItem, setActiveItem] = useState({});

  const toggleOption = (item) => {
    if (selectedItems.includes(item)) {
      selectedItems.splice(selectedItems.indexOf(item), 1);

      setSelectedItems([...selectedItems]);
    } else {
      setSelectedItems((selectedItems) => [...selectedItems, item]);
    }
  };

  const updatedOption = (item) => {
    let _updatedItems = updatedItems;
    if (_updatedItems.includes(item)) {
      _updatedItems.forEach((option, i) => {
        if (option.uuid === item.uuid) {
          _updatedItems[i] = item;
        }
      });
    } else {
      _updatedItems.push(item);
    }
    props.addOptions(_updatedItems);
    setUpdatedItems([..._updatedItems]);
    setActiveItem({});
  };

  const checked = (item) => {
    let check = selectedItems.filter((f) => f.name.en === item.name.en);

    return check.length > 0;
  };

  const view = () => {
    let sublist = null;

    sublist = selectedItems.map((item) => {
      if (item.groups) {
        let _groups = [];

        item.groups.map((g) => {
          if (props.sizeGroups) {
            props.sizeGroups
              .filter((f) => f.uuid === g)
              .map((grp) => {
                _groups.push(grp);
              });
          }
        });

        return _groups.map((group) => {
          let options = [];
          if (group.options) {
            group.options.map((o) => {
              if (props.sizeGroupOptions) {
                props.sizeGroupOptions
                  .filter((f) => f.uuid === o)
                  .map((opt) => options.push(opt));
              }
            });

            return (
              <Group
                classes={classes}
                key={group.id}
                name={group.name.en}
                currency={props.currency}
                addOptions={props.addOptions}
                options={options.sort((a, b) =>
                  a.position < b.position ? -1 : a.position > b.position ? 1 : 0
                )}
                sizeGroups={props.sizeGroups}
                sizeGroupOptions={props.sizeGroupOptions}
              />
            );
          }
        });
      }
    });

    return { sublist: sublist };
  };

  return (
    <div>
      <ListItem className={classes.option}>
        <ListItemText
          primary={<div style={{ fontWeight: "bold" }}>{props.name}</div>}
        />
      </ListItem>
      <Paper className={classes.list}>
        <List>
          {props.options.map((item) => (
            <ListItem
              style={{ height: 48 }}
              className={classes.hover}
              button
              key={item.id}
              onClick={() => toggleOption(item)}
            >
              <Checkbox
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                }}
                className={classes.sizes}
                checked={checked(item)}
              />
              <ListItemText
                primary={
                  <div
                    style={{
                      paddingLeft: 8,
                      paddingRight: 8,
                      fontSize: 14,
                      marginRight: 10,
                    }}
                  >
                    {item.name.en}
                  </div>
                }
              />
              <ListItemSecondaryAction style={{ right: 0, textAlign: "end" }}>
                <ListItemText
                  primary={
                    <div style={{ fontWeight: "bold" }}>
                      {item.prices[props.currency] !== 0 &&
                        Number(item.prices[props.currency]).toFixed(
                          item.pricePrecision
                        )}

                      {/* <TextField
                          value={
                            activeItem.uuid === item.uuid
                              ? activeItem.price
                              : Number(item.prices[props.currency]).toFixed(
                                  item.pricePrecision
                                )
                          }
                          onChange={(event) => {
                            const value = event.target.value
                              ? Number(event.target.value)
                              : event.target.value;

                            item.prices[props.currency] = value;
                            item.price = value;

                            setActiveItem({
                              price: value,
                              available: item.available,
                              uuid: item.uuid,
                            });
                            updatedOption(item);
                          }}
                          name="price"
                          variant="outlined"
                          size="small"
                          style={{ width: "25%" }}
                          className={classes.input}
                        /> */}

                      <FormControlLabel
                        style={{ marginLeft: 1 }}
                        control={
                          <MySwitch
                            checked={
                              activeItem.uuid === item.uuid
                                ? activeItem.available
                                : item.available
                            }
                            onChange={() => {
                              const available =
                                activeItem.uuid === item.uuid
                                  ? activeItem.available
                                  : item.available;

                              setActiveItem({
                                available: !available,
                                uuid: item.uuid,
                              });

                              item.available = !available;
                              updatedOption(item);
                            }}
                          />
                        }
                      />
                    </div>
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
      {view().sublist}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#2e2e2e",
    "&$checked": {
      color: "#2e2e2e",
    },
    padding: 0,
  },
  hover: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  checked: {},
  sizes: {
    width: 20,
    height: 20,
  },
  list: {
    backgroundColor: "#ffffff",
    borderRadius: 10,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 16,
    padding: 0,
    boxShadow: "none",
  },
  listError: {
    backgroundColor: "#ff000020",
    borderRadius: 10,
    borderColor: "red",
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
    boxShadow: "none",
  },
  title: {
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  listTitle: {
    fontWeight: "bold",
    fontSize: 16,
  },
  subTitle: {
    fontStyle: "italic",
    fontSize: 12,
  },
  option: {
    borderBottomColor: "transparent",
    margin: 0,
    padding: 0,
  },
  toolbar: theme.mixins.toolbar,
}));

const MySwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#099c40",
    },
    "&$checked + $track": {
      backgroundColor: "#099c40",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default Group;
