/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import {
  CheckCircle,
  Circle,
  DeliveryDining,
  SupportAgent,
} from "@styled-icons/material";
import { Dish } from "@styled-icons/boxicons-regular";
import moment from "moment";
import { calculateOrderDuration } from "../../utils/calculateOrderDuration";

const colors = {
  all: "#b5b012",
  dispatched: "#218838",
  received: "#f2b141",
  kitchen: "#007bff",
  rider: "#218838",
  support: "#b4000f",
};

const borderColors = {
  all: "#b5b012",
  dispatched: "#218838",
  received: "#f2b141",
  kitchen: "#479ffc",
  rider: "#539962",
  support: "#b8464f",
};

const icons = {
  all: <Circle style={{ height: 14 }} />,
  kitchen: <Dish style={{ height: 15 }} />,
  dispatched: <DeliveryDining style={{ height: 15 }} />,
  received: <CheckCircle style={{ height: 15 }} />,
  rider: <DeliveryDining style={{ height: 15 }} />,
  support: <SupportAgent style={{ height: 15 }} />,
};

const PoolItem = ({
  _id,
  index,
  number,
  order_uuid,
  comments,
  delay_time,
  complex_name,
  _onTimeOut,
  order_placed,
  country_name,
  escalated,
}) => {
  const classes = useStyles();
  const [_delay, setDelay] = useState("");
  const [delayTime, setDelayTime] = useState("");
  const [timeDiff, setTimeDiff] = useState("");
  const [timeCouter, setTimeCounter] = useState(0);
  const [timeColor, setTimeColor] = useState(colors.kitchen);
  const [start, setStart] = useState(false);
  const [flash, setFlash] = useState(false);
  const [order_duration, setOrderDuration] = useState("");
  const [tags, setTags] = useState([]);

  comments = comments.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  useEffect(() => {
    if (delay_time) {
      const now = moment();
      const delay = moment(delay_time);

      if (now > delay) {
        _onTimeOut(_id);
      } else {
        const diff = moment.duration(delay.diff(now));

        setDelay(
          diff._data.hours * 3600 + diff._data.minutes * 60 + diff._data.seconds
        );

        setStart(true);
      }
    } else {
      setDelay("");
      setDelayTime("");
    }
  }, [delay_time]);

  useEffect(() => {
    if (_delay && _delay > -1) {
      const timer = setInterval(() => {
        setDelay(_delay - 1);
        setDelayTime(convertHMS(_delay));
      }, 999);

      return () => clearInterval(timer);
    } else if (start) {
      setDelayTime("");
      _onTimeOut(_id);
    }
  }, [_delay]);

  useEffect(() => {
    const _tags = [];
    comments.map((comment) => {
      if (!_tags.includes(comment.state.title)) {
        _tags.push(comment.state.title);
      }
    });

    setTags([..._tags]);

    const time = comments ? moment(comments[0].created_at) : moment();
    const now = moment();
    const duration = moment.duration(now.diff(time));

    const d =
      duration._data.hours === 0
        ? duration._data.minutes + "mins"
        : duration._data.hours + "hrs " + duration._data.minutes + "mins";

    setTimeDiff(d);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const time = comments ? moment(comments[0].created_at) : moment();
      const now = moment();
      const duration = moment.duration(now.diff(time));

      const d =
        duration._data.hours === 0
          ? duration._data.minutes + "mins"
          : duration._data.hours + "hrs " + duration._data.minutes + "mins";
      setTimeCounter(timeCouter + 1);
      setTimeDiff(d);
    }, 3000);

    return () => clearTimeout(timer);
  }, [timeCouter]);

  useEffect(() => {
    getOrderDurations();
    const interval = setInterval(() => {
      getOrderDurations();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getOrderDurations = () => {
    const { mins, duration } = calculateOrderDuration(
      country_name,
      order_placed
    );

    if (mins > 60) {
      setTimeColor("#d71920");
      setFlash(true);
    } else {
      if (escalated) {
        setTimeColor("#f7941e");
        setFlash(true);
      } else {
        setTimeColor("#078900");
      }
    }

    setOrderDuration(duration);
  };

  return (
    <Draggable draggableId={_id} index={index}>
      {(provided) => (
        <div
          className={classes.root}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Grid container>
            <Grid
              item
              sm={1}
              className={flash ? classes.flash : ""}
              style={{
                backgroundColor: _delay
                  ? _delay > 600
                    ? "#40a43b"
                    : _delay > 300
                    ? "#f8a13a"
                    : "#dc353b"
                  : timeColor,
              }}
            />

            <Grid item sm={11} style={{ paddingLeft: 6 }}>
              <div style={{ display: "flex" }}>
                <h3 className={classes.num}>#{number}</h3>
                <span style={{ marginLeft: "10%", fontStyle: "italic" }}>
                  {order_duration}
                </span>
              </div>
              <div className={classes.complex_name}>{complex_name}</div>

              {comments && (
                <>
                  <p className={classes.text}>{comments[0].body}</p>
                  <p className={classes.text} style={{ fontWeight: 600 }}>
                    {timeDiff}{" "}
                    <span
                      style={{
                        marginLeft: 5,
                        color:
                          _delay > 600
                            ? "#40a43b"
                            : _delay > 300
                            ? "#f8a13a"
                            : "#dc353b",
                      }}
                    >
                      {delayTime}
                    </span>
                  </p>

                  <span style={{margin:3,fontSize:13}}>Ref: <strong >{order_uuid}</strong></span>

                  <Grid container>
                    <Grid item sm={6}>
                      {tags.map(
                        (tag, index) =>
                          index % 2 === 0 && (
                            <div
                              key={tag}
                              className={classes.state}
                              style={{
                                color: colors[tag.toLowerCase()] || colors.all,
                                borderColor:
                                  borderColors[tag.toLowerCase()] ||
                                  borderColors.all,
                              }}
                            >
                              {icons[tag.toLowerCase()] || icons.all}

                              <p>{tag}</p>
                            </div>
                          )
                      )}
                    </Grid>
                    <Grid item sm={6}>
                      {tags.map(
                        (tag, index) =>
                          index % 2 !== 0 && (
                            <div
                              key={tag}
                              className={classes.state}
                              style={{
                                color: colors[tag.toLowerCase()] || colors.all,
                                borderColor:
                                  borderColors[tag.toLowerCase()] ||
                                  borderColors.all,
                              }}
                            >
                              {icons[tag.toLowerCase()] || icons.all}

                              <p>{tag}</p>
                            </div>
                          )
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </Draggable>
  );
};

function convertHMS(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    padding: 8,
    marginBottom: 5,
    border: "1px solid #bfbfbf",
  },

  num: {
    margin: 0,
    marginBottom: 2,
  },

  text: {
    margin: 0,
    fontSize: 12,
  },

  state: {
    display: "inline-flex",
    border: "1px solid #000",
    padding: "3px 4px",
    borderRadius: 20,
    marginTop: 4,
    width: "fit-content",
    "& svg": {
      width: 14,
      marginRight: 2,
    },
    "& p": {
      fontSize: 10.5,
      margin: 0,
    },
  },
  complex_name: {
    fontWeight: "bold",
    marginBottom: 5,
    fontStyle: "italic",
  },

  tag: {
    marginLeft: 5,
    fontWeight: "bold",
    fontSize: 10.5,
  },

  flash: {
    animation: `$myEffect 1800ms infinite`,
  },

  "@keyframes myEffect": {
    "0%": {
      opacity: 0.6,
    },
    "50%": {
      opacity: 0.9,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

export default PoolItem;
