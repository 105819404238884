import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Add, Refresh, SearchOutlined } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import { checkToken } from "../utils/check.token";
import { storage } from "../firebase";
import { BASE_URL } from "../app.config";

const Voucher = (props) => {
  const classes = useStyles();
  const uploadCsvRef = useRef(null);
  const [file, setFile] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const uploadCsv = async () => {
    if (file && !uploaded) {
      setUploading(true);

      const uploadTask = storage.ref(`/vouchers/${file.name}`).put(file);
      uploadTask.on("state_changed", "", console.error, () => {
        storage
          .ref("vouchers")
          .child(file.name)
          .getDownloadURL()
          .then(async (url) => {
            const response = await fetch(`${BASE_URL}/add_voucher`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "content-type": "application/json",
              },
              body: JSON.stringify({ name: file.name }),
            });

            const result = await response.json();

            if (result.success) {
              setUploaded(true);
            } else {
              setUploaded(false);
            }
            setUploading(false);
          });
      });
    }
  };

  const onFileUploadClick = (event) => {
    uploadCsvRef.current.click();
  };

  const onfileUploadChange = (event) => {
    const fileUploaded = Object.values(event.target.files)[0];
    setUploaded(false);
    setFile(fileUploaded);
  };

  return (
    <>
      <div className={classes.heading} />
      <div className={classes.main}>
        <div
          style={{
            display: "inline-flex",
            marginTop: 10,
          }}
        >
          {file ? (
            <>
              <p style={{ margin: 0 }}>{file.name}</p>
            </>
          ) : (
            <>
              <div
                onClick={onFileUploadClick}
                style={{
                  cursor: "pointer",
                }}
              >
                Choose CSV
              </div>

              <input
                type="file"
                ref={uploadCsvRef}
                onChange={onfileUploadChange}
                multiple
                style={{ display: "none" }}
              />
            </>
          )}
          {uploading ? (
            <div
              style={{
                marginBottom: -2,
                marginLeft: 10,
              }}
            >
              <Loader type="Oval" color="grey" width="20" height="20" />
            </div>
          ) : (
            <div
              onClick={uploadCsv}
              style={{
                backgroundColor: uploaded ? "green" : "grey",
                color: "#fff",
                cursor: "pointer",
                paddingLeft: 4,
                paddingRight: 4,
                borderRadius: 10,
                marginLeft: 10,
              }}
            >
              {uploaded ? "uploaded" : "upload"}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    backgroundColor: "#fcfcfc",
    width: "100%",
    minHeight: 40,
  },
  main: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  notchedOutline: {
    border: 0,
  },

  content: {
    backgroundColor: "#fff",
    padding: 7,
    paddingTop: 0,
    miHeight: "65vh",
  },
}));
export default Voucher;
