/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { DragDropContext } from "react-beautiful-dnd";
import Pool from "../components/customerAlerts/Pool";
import ActiveTicket from "../components/customerAlerts/ActiveTicket";
import MyTickets from "../components/customerAlerts/MyTickets";
import firebase from "../firebase";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import ClosedTickets from "../components/customerAlerts/ClosedTickets";
import { countryTimeZone } from "../utils/checkTimeZone";

const ticketsRef = firebase.database().ref("tickets");

const CustomerAlerts = () => {
  const classes = useStyles();
  const [data, setData] = useState("");
  const [closedTickets, setClosedTickets] = useState(false);
  const [loading, setLoading] = useState(true);

  const user = useSelector((state) => state.auth.user);
  const { country } = useSelector((state) => state.remote);

  useEffect(() => {
    if (country) {
      ticketsRef
        .orderByChild("status")
        .equalTo("open")
        .on("value", (snapshot) => {
          let allTickets = [];
          snapshot.forEach((snap) => {
            if (snap.val()) {
              if (snap.val().country_name === country.name) {
                allTickets.push({ _id: snap.key, ...snap.val() });
              }
            }
          });
          const sortedData = sortData(allTickets, user);

          setData({ ...sortedData });

          setLoading(false);
        });
    }
  }, [country, user]);

  function _onDragEnd({ draggableId, destination, source }) {
    if (!destination) {
      return;
    }

    if (
      (destination.droppableId === source.droppableId &&
        destination.index === source.index) ||
      (source.droppableId === "pool" &&
        destination.droppableId === "myTickets") ||
      (destination.droppableId === "active" &&
        data.sections.active.tickets.length > 0)
    ) {
      return;
    }

    if (destination.droppableId !== source.droppableId) {
      const section1 = data.sections[source.droppableId];
      const section2 = data.sections[destination.droppableId];

      const newTickets1 = Array.from(section1.tickets);
      const newTickets2 = Array.from(section2.tickets);

      newTickets1.splice(source.index, 1);
      newTickets2.splice(destination.index, 0, draggableId);

      const ticket = data.tickets[draggableId];

      if (destination.droppableId === "active") {
        ticket.assigned_to = user.email;
        ticket.delay = "";
      } else if (destination.droppableId === "pool") {
        ticket.assigned_to = "";
        ticket.delay = "";
      }

      const ticketsRef = firebase.database().ref("tickets/" + draggableId);
      ticketsRef.update(ticket);

      setData((prevState) => {
        return {
          ...prevState,

          sections: {
            ...prevState.sections,
            [section1._id]: { ...section1, tickets: [...newTickets1] },
            [section2._id]: { ...section2, tickets: [...newTickets2] },
          },
        };
      });
    }
  }

  async function _onSave(comment, delay) {
    if (data.sections.active.tickets.length === 0) {
      return;
    }

    if (!delay) {
      alert("Delay is required");
      return;
    }

    if (!comment) {
      alert("Comment is required");
      return;
    }

    const _data = data;
    let ticket = data.sections.active.tickets[0];

    const _ticket = _data.tickets[ticket];

    const orderLogRef = firebase.database().ref("orders/" + _ticket.order_uuid);
    const order = await orderLogRef.get();

    const t_z = countryTimeZone(order.val()?.country_name);

    const current_at = moment().tz(t_z).format("YYYY-MM-DD HH:mm:ss");

    _ticket.comments = _ticket.comments ? _ticket.comments : [];

    _ticket.comments.unshift({
      body: comment,

      created_at: current_at,
      state: {
        title: order.val()?.order_status ?? "",
      },
    });

    _ticket.delay = delay;

    let delay_time = moment().add(+delay.split(" ")[0], "minutes").toString();

    const ticketsRef = firebase.database().ref("tickets/" + ticket);

    ticketsRef.update({ ..._ticket, delay_time });

    const l_data = {
      saved_by: user.email,
    };

    _onSaveLogs(
      order.val(),
      orderLogRef,
      comment,
      current_at,
      l_data,
      _ticket.base_url
    );
  }

  async function _onCallNow() {
    const comment = "Call Now button clicked";
    const _data = data;
    let ticket = data.sections.active.tickets[0];

    const _ticket = _data.tickets[ticket];

    const orderLogRef = firebase.database().ref("orders/" + _ticket.order_uuid);
    const order = await orderLogRef.get();

    const t_z = countryTimeZone(order.val()?.country_name);

    const current_at = moment().tz(t_z).format("YYYY-MM-DD HH:mm:ss");

    _ticket.comments = _ticket.comments ? _ticket.comments : [];

    _ticket.comments.unshift({
      body: comment,
      created_at: current_at,
      state: {
        title: order.val()?.order_status ?? "",
      },
    });

    const ticketsRef = firebase.database().ref("tickets/" + ticket);

    ticketsRef.update({ ..._ticket });

    const l_data = {
      call_now_by: user.email,
    };

    _onSaveLogs(
      order.val(),
      orderLogRef,
      comment,
      current_at,
      l_data,
      _ticket.base_url
    );
  }

  async function _onCloseTicket(comment) {
    if (data.sections.active.tickets.length === 0) {
      return;
    }

    if (!comment) {
      alert("Comment is required");
      return;
    }

    const _ticket = data.tickets[data.sections.active.tickets[0]];

    const orderLogRef = firebase.database().ref("orders/" + _ticket.order_uuid);
    const order = await orderLogRef.get();

    const t_z = countryTimeZone(order.val()?.country_name);

    const current_at = moment().tz(t_z).format("YYYY-MM-DD HH:mm:ss");

    _ticket.comments = _ticket.comments ? _ticket.comments : [];

    _ticket.comments.unshift({
      body: comment,
      created_at: current_at,
      state: {
        title: order.val()?.order_status ?? "",
      },
    });

    _ticket.status = "closed";
    _ticket.closed_at = current_at;

    const ticketsRef = firebase
      .database()
      .ref("tickets/" + data.sections.active.tickets[0]);

    ticketsRef.update(_ticket);

    const _data = data;
    _data.sections.active.tickets = [];

    setData({ ..._data });

    const l_data = {
      closed_by: user.email,
    };

    _onSaveLogs(
      order.val(),
      orderLogRef,
      comment,
      current_at,
      l_data,
      _ticket.base_url
    );

    if (_ticket.ticket_id) {
      await fetch("https://cloud.dial-a-delivery.online/api/ticket/close", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ticket_id: _ticket.ticket_id,
          comment,
        }),
      });
    }
  }

  async function _onEscalate(comment) {
    if (data.sections.active.tickets.length === 0) {
      return;
    }

    if (!comment) {
      alert("Comment is required");
      return;
    }

    const _data = data;

    const ticket = data.sections.active.tickets[0];
    const _ticket = data.tickets[ticket];

    const orderLogRef = firebase.database().ref("orders/" + _ticket.order_uuid);
    const order = await orderLogRef.get();

    const t_z = countryTimeZone(order.val()?.country_name);
    const current_at = moment().tz(t_z).format("YYYY-MM-DD HH:mm:ss");

    _ticket.comments = _ticket.comments ? _ticket.comments : [];

    _ticket.comments.unshift({
      body: comment,
      created_at: current_at,
      state: {
        title: order.val()?.order_status ?? "",
      },
    });

    _data.sections.pool.tickets.push(ticket);
    _data.sections.active.tickets = [];

    if (comment !== "Timed Out") {
      _ticket.assigned_to = "";
      _ticket.escalated = true;
      _ticket.escalated_by = user.email;
    }

    const ticketsRef = firebase.database().ref("tickets/" + ticket);

    ticketsRef.update(_ticket);

    let l_data;

    if (comment === "Timed Out") {
      l_data = {
        timeout: true,
        timeout_by: user.email,
      };
    } else {
      l_data = {
        escalated: true,
        escalated_by: user.email,
      };
    }

    _onSaveLogs(
      order.val(),
      orderLogRef,
      comment,
      current_at,
      l_data,
      _ticket.base_url
    );
  }

  function _onTimeOut(ticket) {
    const _ticket = data.tickets[ticket];
    _ticket.assigned_to = "";
    _ticket.delay = "";

    const ticketsRef = firebase.database().ref("tickets/" + ticket);
    ticketsRef.update(_ticket);
  }

  async function _onSaveLogs(
    order,
    orderLogRef,
    comment,
    current_at,
    _data,
    base_url
  ) {
    let logs = [];
    if (order) {
      if (order.logs) {
        logs = order.logs;
      }

      logs.push({
        created_at: current_at,
        comment,
        state: order?.order_status ?? "",
        user: user.name,
        type: "Alert Ticket",
        ..._data,
      });

      orderLogRef.update({ logs });

      if (order.customer) {
        await fetch(
          "https://cloud.dial-a-delivery.online/api/save_order_logs",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              order_uuid: order.order_uuid,
              phone: order.customer.phone,
              logs,
            }),
          }
        );

        try {
          const url = base_url + "/rest/order_log";

          await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
              AUTHORIZATION: "y_bHqy-Y5wor07I4tFMuCh2PbUdBR_fhWkFKC8-JyAo",
              TOKEN: "EROmYghE2wZgdGIOgAmliHXJv-kf57eIfVjOJ7ROq2c",
            },
            body: JSON.stringify({
              order_uuid: order.order_uuid,
              comment,
              category: "Alert Ticket",
              user: user.email,
              created_at: current_at,
              ..._data,
            }),
          });
        } catch (error) {}
      }
    }
  }

  return (
    <>
      <div className={classes.heading}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <h2 className={classes.name}>Customer Alerts</h2>
          </Grid>

          <Grid item style={{ paddingRight: 50 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.closedBtn}
              style={{
                backgroundColor: "#007bff",
              }}
              onClick={() => setClosedTickets(true)}
            >
              Closed Tickets
            </Button>
          </Grid>
        </Grid>
      </div>

      <div className={classes.main}>
        {!loading ? (
          <DragDropContext onDragEnd={_onDragEnd}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <Pool
                  key="pool"
                  _id="pool"
                  tickets={data.sections.pool.tickets.map(
                    (_id) => data.tickets[_id]
                  )}
                />
              </Grid>

              <Grid item md={6}>
                <ActiveTicket
                  key="active"
                  _id="active"
                  tickets={data.sections.active.tickets.map(
                    (_id) => data.tickets[_id]
                  )}
                  _onSave={_onSave}
                  _onCallNow={_onCallNow}
                  _onCloseTicket={_onCloseTicket}
                  _onEscalate={_onEscalate}
                  _onTimeOut={_onTimeOut}
                />
              </Grid>

              <Grid item md={3}>
                <MyTickets
                  key="myTickets"
                  _id="myTickets"
                  _onTimeOut={_onTimeOut}
                  i={data.sections.myTickets.tickets.length + 1}
                  tickets={data.sections.myTickets.tickets.map(
                    (_id) => data.tickets[_id]
                  )}
                />
              </Grid>
            </Grid>
          </DragDropContext>
        ) : (
          <div
            style={{
              width: "fit-content",
              margin: "0px auto",
              marginTop: 200,
            }}
          >
            <CircularProgress size={50} color="primary" />
          </div>
        )}

        <ClosedTickets
          open={closedTickets}
          close={() => setClosedTickets(false)}
        />
      </div>
    </>
  );
};

function sortData(data, user) {
  const sortedData = {
    sections: {
      pool: {
        _id: "pool",
        tickets: [],
      },

      active: {
        _id: "active",
        tickets: [],
      },

      myTickets: {
        _id: "myTickets",
        tickets: [],
      },
    },
    tickets: {},
  };

  data.sort((a, b) => {
    if (a.created_at > b.created_at) {
      return -1;
    }

    if (a.created_at < b.created_at) {
      return 1;
    }
    return 0;
  });

  data.forEach((item) => {
    sortedData.tickets[item._id] = item;

    if (item.assigned_to === user.email && item.delay) {
      sortedData.sections.myTickets.tickets.push(item._id);
    } else if (item.assigned_to === user.email && !item.delay) {
      sortedData.sections.active.tickets.push(item._id);
    } else if (!item.assigned_to) {
      sortedData.sections.pool.tickets.push(item._id);
    }
  });

  return sortedData;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    backgroundColor: "#fcfcfc",
    width: "100%",
    minHeight: 40,
    padding: "15px 20px",

    position: "fixed",
    zIndex: 1000,
  },

  main: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    minHeight: "92vh",
    marginTop: "5%",
    overflow: "hidden",
  },

  name: {
    margin: 0,
  },

  closedBtn: {
    textTransform: "none",
  },
}));
export default CustomerAlerts;
