import * as types from "../action.types";
import { BASE_URL } from "../../app.config";

export function getCustomerAddresses(phone) {
  return async (dispatch) => {
    dispatch({ type: types.GET_CUSTOMER_ADDRESSES_LOADING });

    const response = await fetch(
      `${BASE_URL}/locations/get_locations/${phone}`,
      {
        method: "GET",
      }
    );

    const result = await response.json();

    if (result) {
      dispatch({
        type: types.GET_CUSTOMER_ADDRESSES_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_ADDRESSES_FAILED,
        payload: result,
      });
    }
  };
}

export function getCustomer(phone) {
  return async (dispatch) => {
    dispatch({ type: types.GET_CUSTOMER_LOADING });

    const response = await fetch(`${BASE_URL}/get_customer/${phone}`, {
      method: "GET",
    });

    const result = await response.json();

    if (result) {
      dispatch({
        type: types.GET_CUSTOMER_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_FAILED,
        payload: result,
      });
    }
  };
}

export function getCustomers(country) {
  return async (dispatch) => {
    dispatch({ type: types.GET_CUSTOMERS_LOADING });

    const response = await fetch(`${BASE_URL}/customer_list/${country}`, {
      method: "GET",
    });

    const result = await response.json();

    if (result) {
      dispatch({
        type: types.GET_CUSTOMERS_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.GET_CUSTOMERS_FAILED,
        payload: result,
      });
    }
  };
}

export function getMoreCustomers(country, rows, token) {
  return async (dispatch) => {
    dispatch({ type: types.GET_MORE_CUSTOMERS_LOADING });

    const response = await fetch(
      `${BASE_URL}/customer_list/${country}?rows=${rows}&token=${token}`,
      {
        method: "GET",
      }
    );

    const result = await response.json();

    if (result) {
      dispatch({
        type: types.GET_MORE_CUSTOMERS_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.GET_MORE_CUSTOMERS_FAILED,
        payload: result,
      });
    }
  };
}

export function searchCustomer(country, query) {
  return async (dispatch) => {
    dispatch({ type: types.SEARCH_CUSTOMER_LOADING });

    const response = await fetch(
      `${BASE_URL}/customer_list/${country}?search=${query}`,
      {
        method: "GET",
      }
    );

    const result = await response.json();

    if (!result.message) {
      dispatch({
        type: types.SEARCH_CUSTOMER_SUCCESS,
        payload: { ...result, query },
      });
    } else {
      dispatch({
        type: types.SEARCH_CUSTOMER_FAILED,
        payload: result,
      });
    }
  };
}

export function refrestCustomers(country) {
  return async (dispatch) => {
    dispatch({ type: types.REFRESH_CUSTOMERS_LOADING });

    const response = await fetch(`${BASE_URL}/customer_list/${country}`, {
      method: "GET",
    });

    const result = await response.json();

    if (result) {
      dispatch({
        type: types.REFRESH_CUSTOMERS_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.REFRESH_CUSTOMERS_FAILED,
        payload: result,
      });
    }
  };
}

export function updateCustomerPersonalInfo(data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_CUSTOMER_PERSONAL_INFO_LOADING });

    const response = await fetch(`${BASE_URL}/update_customer`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result.success) {
      dispatch({
        type: types.UPDATE_CUSTOMER_PERSONAL_INFO_SUCCESS,
        payload: result.payload,
      });
    } else {
      dispatch({
        type: types.UPDATE_CUSTOMER_PERSONAL_INFO_FAILED,
        payload: result.message,
      });
    }
  };
}

export function updateCustomerAddress(data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_CUSTOMER_ADDRESS_LOADING });

    const response = await fetch(`${BASE_URL}/update_customer_location`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result) {
      dispatch({
        type: types.UPDATE_CUSTOMER_ADDRESS_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.UPDATE_CUSTOMER_ADDRESS_FAILED,
        payload: result,
      });
    }
  };
}

export function disableCustomer(data) {
  return async (dispatch) => {
    dispatch({ type: types.DISABLE_CUSTOMER_LOADING, payload: data });

    const response = await fetch(`${BASE_URL}/update_customer`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result.success) {
      dispatch({
        type: types.DISABLE_CUSTOMER_SUCCESS,
        payload: result.payload,
      });
    } else {
      dispatch({
        type: types.DISABLE_CUSTOMER_FAILED,
        payload: result.message,
      });
    }
  };
}
