import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const MySelect = (props) => {
  return (
    <>
      {props.label && <p className={props.classes.label}>{props.label}</p>}
      <FormControl
        fullWidth
        className={props.classes.select}
        variant="outlined"
        margin="dense"
        style={{ marginTop: 0 }}
      >
        <Select
          native={props.native}
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          inputProps={{
            name: props.name,
            id: props.id,
          }}
        >
          {props.select_label && (
            <option value="">-- {props.select_label} --</option>
          )}
          {props.select_items.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export const MenuSelect = (props) => {
  return (
    <>
      {props.label && <p className={props.classes.label}>{props.label}</p>}
      <FormControl
        fullWidth
        className={props.classes.select}
        variant="outlined"
        margin="dense"
        style={{ marginTop: 0 }}
      >
        <Select
          displayEmpty
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          inputProps={{
            name: props.name,
            id: props.id,
          }}
        >
          {props.select_label && (
            <MenuItem value="">
              <em>{props.select_label}</em>
            </MenuItem>
          )}

          {props.select_items?.map((item, i) => (
            <MenuItem
              key={item.uuid + i}
              value={item}
              className={props.classes.hover}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export const AutoSelect = (props) => {
  return (
    <>
      {props.select_items ? (
        <Autocomplete
          id={props.id}
          value={props.value}
          disableClearable
          onChange={(event, newValue) => {
            props.setValue(newValue ?? "");
          }}
          className={props.classes.autoSelect}
          options={props.select_items}
          getOptionLabel={(option) => option.name ?? ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label={!props.value && props.select_label}
              margin="normal"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              style={{ margin: 0, marginTop: -2 }}
            />
          )}
        />
      ) : (
        <div
          style={{
            fontStyle: "italic",
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          {props.select_loading}
        </div>
      )}
    </>
  );
};
