import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, makeStyles, Menu, MenuItem } from "@material-ui/core";
import AddressItem from "./addressItem";
import { MoreHoriz } from "@material-ui/icons";
import UpdateCustomer from "./updateCustomer";
import { disableCustomer } from "../../redux/actions/customers.action";
import { useDispatch, useSelector } from "react-redux";

const CustomerAddressesList = ({ customer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const editor = useSelector((state) => state.auth?.user?.editor);
  const [edit, setEdit] = useState(false);
  const [location, setLocation] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [addresses, setAddresses] = useState({
    delivery: [],
    instore: [],
    kerbside: [],
  });

  const [address, setAddress] = useState();

  useEffect(() => {
    if (customer.addresses) {
      setAddresses({
        delivery: customer.addresses.filter(({ type }) => type === "delivery"),
        instore: customer.addresses.filter(({ type }) => type === "instore"),
        kerbside: customer.addresses.filter(({ type }) => type === "kerbside"),
      });
    }
  }, [customer]);

  return (
    <div className={classes.root}>
      <div style={{ flex: 4 }}>
        <div
          className={customer.details?.active && classes.details}
          style={{ backgroundColor: !customer.details?.active && "#f0f0f0" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1, paddingTop: 5, fontWeight: "500" }}>
              <p className={classes.text}>
                {customer.details?.phone} * {customer.details?.name}
              </p>
            </div>
            {editor && (
              <div style={{ paddingTop: 5, paddingRight: 20 }}>
                <IconButton
                  className={classes.editBtn}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <MoreHoriz />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(false)}
                >
                  <MenuItem
                    onClick={() => {
                      setEdit(true);
                      setLocation(false);
                      setAnchorEl(null);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      dispatch(
                        disableCustomer({
                          phone: customer.details.phone,
                          active: !customer.details.active,
                        })
                      );
                      setAnchorEl(null);
                    }}
                  >
                    {customer.details?.active ? "Deactivate" : "Activate"}
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>

          <p className={classes.text} style={{ fontSize: 12, marginTop: 5 }}>
            * {customer.details?.email}
          </p>
          <p
            style={{
              color: customer.details?.active && "#498f65",
              backgroundColor: customer.details?.active ? "#c5fcdb" : "#c7c7c7",
              width: customer.details?.active ? "20%" : "25%",
              paddingLeft: 8,
              paddingTop: 4,
              paddingBottom: 4,
              borderRadius: 50,
              fontSize: 12,
            }}
          >
            {customer.details?.active ? "Active" : "InActive"}
          </p>
        </div>
      </div>

      <div className={classes.col}>
        {addresses.delivery.map((address, index) => (
          <AddressItem
            key={index}
            {...address}
            display={address.address}
            title={address.nickname}
            _onEdit={() => {
              setLocation(true);
              setEdit(true);
              setAddress(address);
            }}
          />
        ))}
      </div>
      <div className={classes.col}>
        {addresses.instore.map((address, index) => (
          <AddressItem
            key={index}
            {...address}
            title={address.complex_name}
            display={`${address.collection_name} * ${
              address.collector_id_or_telephone || address.phone
            }`}
            _onEdit={() => {
              setLocation(true);
              setEdit(true);
              setAddress(address);
            }}
          />
        ))}
      </div>
      <div className={classes.col}>
        {addresses.kerbside.map((address, index) => (
          <AddressItem
            key={index}
            {...address}
            title={address.complex_name}
            display={
              address.vehicle_registration +
              " * " +
              address.vehicle_make_model +
              " * " +
              address.vehicle_colour
            }
            _onEdit={() => {
              setLocation(true);
              setEdit(true);
              setAddress(address);
            }}
          />
        ))}
      </div>

      <UpdateCustomer
        open={edit}
        handleDialog={() => setEdit(false)}
        _onClose={() => setEdit(false)}
        customer={customer}
        location={location}
        address={address}
      />
    </div>
  );
};

CustomerAddressesList.propTypes = {
  customer: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },

  col: {
    flex: 4,
  },

  title: {
    margin: 0,
    fontSize: 13,
    fontWeight: 600,
  },

  text: {
    fontSize: 13,
    margin: "1px 0px",
  },
  editBtn: {
    padding: 0,
  },

  details: {
    "&:hover": {
      backgroundColor: "#EBFCFF",
    },
  },
}));
export default CustomerAddressesList;
