import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  Select,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React from "react";

const _rows = ["10", "50", "100", "250"];

const Pagination = ({
  prev,
  next,
  from,
  to,
  rows,
  _onChangeRows,
  total,
  _onPrev,
  _onNext,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="flex-end" spacing={3}>
        <Grid
          item
          style={{ display: "flex", flexDirection: "row", paddingTop: 21 }}
        >
          <p style={{ margin: 0, marginTop: 6, marginRight: 4 }}>
            Rows per page
          </p>
          <FormControl
            className={classes.select}
            variant="outlined"
            margin="dense"
            style={{ marginTop: 0 }}
          >
            <Select
              native
              value={rows}
              onChange={(event) => {
                const value = event.target.value;
                _onChangeRows(value);
              }}
              inputProps={{
                name: "row",
                id: "row",
              }}
            >
              {_rows.map((row, index) => (
                <option key={index} value={row}>
                  {row}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <p>
            {" "}
            {from} - {to} of {total}
          </p>
        </Grid>

        <Grid item style={{ paddingTop: 17 }}>
          <Button size="small" disabled={prev} onClick={_onPrev}>
            <ChevronLeft />
          </Button>

          <Button size="small" disabled={next} onClick={_onNext}>
            <ChevronRight />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    borderRadius: 6,
    padding: 12,
    paddingTop: 6,
    paddingBottom: 6,
    marginTop: 20,
  },

  select: {
    backgroundColor: "#fff",
    border: "none",

    "& select": {
      border: "none",
      padding: 7,

      //   minWidth: 200,
    },
  },
}));

export default Pagination;
