/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { GreenCheckbox } from "../commons/checkboxs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const reasons = [
  "Store Equipment Malfunction",
  "Power Outage",
  "Internet Outage",
  "Kitchen Backlog",
  "Riders Unavailable",
  "Security Disruption",
  "Store Renovations",
  "Other",
];

const ClosureReason = ({
  open,
  close,
  selected,
  onSelect,
  other,
  setOther,
  _onSubmit,
  closing,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        className={classes.root}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: 0,
            boxShadow: "none",
            minWidth: 550,
          },
        }}
      >
        <DialogTitle className={classes.header}>
          <div
            onClick={selected === "Other" && !other ? null : close}
            align="right"
            style={{ marginBottom: -40 }}
          >
            <IconButton className={classes.closeBtn}>
              <Close />
            </IconButton>
          </div>
          Closure Reason
        </DialogTitle>
        <DialogContent className={classes.content}>
          {reasons.map((reason, index) => (
            <div key={index}>
              <div onClick={() => onSelect(reason)}>
                <GreenCheckbox checked={reasons[index] === selected} /> {reason}
              </div>
              {reasons[index] === selected && selected === "Other" && (
                <div style={{ padding: "0px 15px" }}>
                  <TextField
                    fullWidth
                    required
                    value={other}
                    placeholder="Type here...."
                    onChange={(event) => setOther(event.target.value)}
                  />
                </div>
              )}
            </div>
          ))}

          <Button
            fullWidth
            className={classes.btn}
            color="primary"
            onClick={_onSubmit ? _onSubmit : close}
          >
            {closing ? (
              <CircularProgress
                style={{ color: "#ffff", width: 20, height: 20 }}
              />
            ) : (
              "Save & Proceed"
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    borderTop: "3px #fff  solid",
  },
  btn: {
    marginTop: 10,
    textTransform: "none",
    color: "#ffff",
    backgroundColor: "green",
    "&:hover": {
      color: "#ffff",
      backgroundColor: "green",
    },
  },
}));

export default ClosureReason;
