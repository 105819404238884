import React from "react";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import { Check, Edit } from "@material-ui/icons";

import icon_delivery_time from "../../assets/icon-action-favorite_24px@2x.png";
import { useSelector } from "react-redux";

const CollectionItem = ({
  name,
  opening_times,
  _onEdit,
  collection_time,
  rains,
  pizza_inn_delay,
  layout,

  select,
  selected,
  _onSelectComplex,
}) => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth?.user);

  return (
    <div
      className={classes.root}
      style={{
        borderBottom: layout === 12 ? "1px solid #d1d1d1" : "",
        backgroundColor: selected ? "#bfedf5" : "",
        cursor: select ? "pointer" : "",
      }}
      onClick={select ? _onSelectComplex : () => {}}>
      {user?.role !== "agent" && !select && (
        <IconButton
          variant="contained"
          color="secondary"
          className={classes.editBtn}
          onClick={_onEdit}>
          <Edit
            className={classes.btnIcon}
            style={{ fontSize: layout === 12 ? 27 : 17 }}
          />
        </IconButton>
      )}

      {user?.role !== "agent" && select && (
        <IconButton
          variant="contained"
          color="secondary"
          className={classes.selectBtn}>
          {selected ? (
            <Check className={classes.selectIcon} />
          ) : (
            <div style={{ height: 15, width: 15 }} />
          )}
        </IconButton>
      )}

      {layout === 12 ? (
        <Grid container alignItems="center" spacing={2}>
          <Grid item sm={4}>
            <p className={classes.title}>{name}</p>
          </Grid>

          <Grid item sm={4}>
            <p className={classes.times}>{opening_times}hrs</p>
          </Grid>

          <Grid item>
            {collection_time && (
              <div
                className={classes.delivery_time}
                style={{ marginLeft: pizza_inn_delay && rains ? 5 : 0 }}>
                <img
                  className={classes.pizza_inn_delay_icon}
                  src={icon_delivery_time}
                  alt="Delivery Time"
                />
                <p>{collection_time}</p>
              </div>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <p className={classes.title}>{name}</p>
          <p className={classes.times}>{opening_times}hrs</p>
          <div style={{ marginTop: 5 }}>
            {collection_time && (
              <div
                className={classes.delivery_time}
                style={{ marginLeft: pizza_inn_delay && rains ? 5 : 0 }}>
                <img
                  className={classes.pizza_inn_delay_icon}
                  src={icon_delivery_time}
                  alt="Delivery Time"
                />
                <p>{collection_time}</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: "15px 7px",
    borderRadius: 3,
    marginRight: 4,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#EBFCFF",
    },
    "&:hover > button": {
      visibility: "visible",
    },
  },

  title: {
    margin: 0,
    fontSize: 13,
    fontWeight: 600,
  },

  times: {
    margin: 0,
    marginTop: 1,
    fontSize: 11,
    color: "#525252",
  },

  delivery_time: {
    margin: 0,
    fontSize: 11,
    display: "inline-flex",
    border: " 1px solid #65ba87",
    borderRadius: 40,
    padding: 2,
    "&>p": {
      color: "green",
      margin: 0,
      marginLeft: 5,
    },
  },

  delivery_time_icon: {
    height: 20,
    color: "#fff",
    width: 20,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 20,
    backgroundColor: "green",
  },

  pizza_inn_delay: {
    margin: 0,
    fontSize: 11,
    display: "inline-flex",
    border: " 1px solid #e85f5a",
    borderRadius: 40,
    padding: 2,
    "&>p": {
      color: "#eb4742",
      margin: 0,
      marginLeft: 5,
    },
  },

  pizza_inn_delay_icon: {
    height: 20,
    color: "#fff",
    width: 20,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 20,
    // backgroundColor: "#eb4742",
  },
  rains: {
    margin: 0,
    fontSize: 11,
    display: "inline-flex",
    border: " 1px solid #589cf5",
    borderRadius: 40,
    padding: 2,
    "&>p": {
      color: "#167BFF",
      margin: 0,
      marginLeft: 5,
    },
  },

  rains_icon: {
    height: 20,
    color: "#fff",
    width: 20,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 20,
    // backgroundColor: "#167BFF",
  },

  editBtn: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 5,
    visibility: "hidden",
  },

  btnIcon: {
    fontSize: 17,
  },

  selectBtn: {
    position: "absolute",
    right: 4,
    top: 4,
    padding: 2,
    borderRadius: 30,
    border: "1px solid #70706f",
  },

  selectIcon: {
    fontSize: 17,
    color: "#000",
  },
}));
export default CollectionItem;
