/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../app.config";
import { GET_REMOTE_CONFIG_SUCCESS } from "../redux/action.types";
import {
  getCollectionComplexes,
  getComplexes,
} from "../redux/actions/complexes.action";
import { getUsers } from "../redux/actions/users.action";
import { getZonesComplexes } from "../redux/actions/zone.complex.actions";
import { straightToKitchen } from "../utils/straightToKitchen";

const countries = [
  {
    name: "Kenya",
    code: "ke",
    flag: "🇰🇪",
  },
  {
    name: "Zimbabwe",
    code: "zw",
    flag: "🇿🇼",
  },
  {
    name: "Mauritius",
    code: "mu",
    flag: "🇲🇺",
  },
  {
    name: "Ghana",
    code: "gh",
    flag: "🇬🇭",
  },
  {
    name: "Congo",
    code: "cd",
    flag: "🇨🇩",
  },
  {
    name: "Zambia",
    code: "zm",
    flag: "🇿🇲",
  },
  {
    name: "Namibia",
    code: "na",
    flag: "🇳🇦",
  },
  {
    name: "Eswatini",
    code: "sz",
    flag: "🇸🇿",
  },
];

const Settings = () => {
  const dispatch = useDispatch();

  const { country, user_mgt } = useSelector((state) => state.remote);
  const { user } = useSelector((state) => state.auth);

  const [active, setActive] = useState("");
  const [straight, setSTK] = useState(false);

  useEffect(() => {
    if (country) {
      _getSTK();
    }
  }, [country]);

  const _getSTK = async () => {
    try {
      if (country) {
        const code = country.country_code.toLowerCase();

        const response = await fetch(`${BASE_URL}/v1/general/get_stk/${code}`, {
          method: "GET",
        });

        const _stk = await response.json();

        setSTK(_stk);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const _onChange = async (code) => {
    setActive(code);
    const response = await fetch(`${BASE_URL}/config/countries/${code}`, {
      method: "GET",
    });

    const _country = await response.json();

    if (_country.name) {
      dispatch(getCollectionComplexes(_country.name));
      dispatch(getZonesComplexes(_country.name.toLowerCase()));
      dispatch(getComplexes(_country.name));
      dispatch(getUsers(_country.name.toLowerCase()));

      dispatch({
        type: GET_REMOTE_CONFIG_SUCCESS,
        payload: { country: _country, user_mgt },
      });

      setActive("");
    }
  };

  const _onStraightToKitchen = async () => {
    const _stk = !straight;
    setSTK(_stk);

    try {
      const base_url = country.base_url;
      const _active = _stk ? 1 : 0;

      const response = await straightToKitchen("all", _active, base_url);

      if (!response) {
        setSTK(false);
      }

      const code = country.country_code.toLowerCase();

      const body = { [code]: _stk };

      let uRes = await fetch(`${BASE_URL}/v1/general/update_stk`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      });

      uRes = await uRes.json();

      if (uRes) {
      }
    } catch (error) {
      setSTK(false);
    }
  };

  return (
    <div style={{ marginLeft: 10 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <h3>Straight to Kitchen</h3>

        <div style={{ marginLeft: 10 }}>
          <FormControlLabel
            control={
              <MySwitch
                checked={straight}
                onChange={() => _onStraightToKitchen()}
              />
            }
          />
        </div>
      </div>
      {user.role === "admin" && (
        <>
          <h3>Change Country </h3>
          {countries.map(({ name, code, flag }, index) => (
            <div key={index}>
              <Button
                variant="outlined"
                style={{
                  borderColor: name === country.name ? "red" : "inherit",
                  marginBottom: 20,
                  minWidth: "10%",
                }}
                onClick={() => _onChange(code)}
              >
                {flag} {name}
              </Button>
              {active === code && (
                <CircularProgress size={20} style={{ marginLeft: 5 }} />
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const MySwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#099c40",
    },
    "&$checked + $track": {
      backgroundColor: "#099c40",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default Settings;
