import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { GreenCheckbox } from "../commons/checkboxs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const reasons = [
  "Customer unreachable upon delivery",
  "Cancellation of an order after it has been prepared",
  "Insufficient funds upon delivery",
  "Persistent delays in payment upon delivery",
  "Prank order",
  "Lack of cash or card to make payment upon delivery",
];

const PrepayReason = ({ open, close, selected, onSelect, onSave }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        className={classes.root}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: 0,
            boxShadow: "none",
            minWidth: 550,
          },
        }}
      >
        <DialogTitle className={classes.header}>
          <div onClick={close} align="right" style={{ marginBottom: -40 }}>
            <IconButton className={classes.closeBtn}>
              <Close />
            </IconButton>
          </div>
          Prepay Reason
        </DialogTitle>
        <DialogContent className={classes.content}>
          {reasons.map((reason, index) => (
            <div key={index}>
              <div onClick={() => onSelect(reason)}>
                <GreenCheckbox checked={reasons[index] === selected} /> {reason}
              </div>
            </div>
          ))}

          <Button
            fullWidth
            className={classes.btn}
            color="primary"
            onClick={onSave}
          >
            Save & Proceed
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    borderTop: "3px #fff  solid",
  },
  btn: {
    marginTop: 10,
    textTransform: "none",
    color: "#ffff",
    backgroundColor: "green",
    "&:hover": {
      color: "#ffff",
      backgroundColor: "green",
    },
  },
}));

export default PrepayReason;
