/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import PoolItem from "./PoolItem";
import { Droppable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/styles";
import { GreenCheckbox } from "../commons/checkboxs";
import { Grid } from "@material-ui/core";

import tickAudio from "../../assets/tone.mp3";

const states = [
  "Pending Payment",
  "Received",
  "Kitchen",
  "Dispatched",
  "Escalated",
  "Ready For Collection",
];

const Pool = ({ _id, tickets }) => {
  const classes = useStyles();

  const [filters, setFilters] = useState({
    Received: "",
    "Pending Payment": "",
    Kitchen: "",
    Dispatch: "",
    Escalated: "",
    "Ready For Collection": "",
  });

  const [_tickets, setTickects] = useState([]);

  useEffect(() => {
    if (tickets) {
      let s_tickets = tickets;

      const f_tickets = [];

      Object.entries(filters).map(([key, value]) => {
        if (value) {
          if (key === "Escalated") {
            tickets
              .filter(({ escalated }) => escalated === value)
              .map((ticket) => f_tickets.push(ticket));
          } else {
            tickets
              .filter(({ order_status }) => order_status === key)
              .map((ticket) => f_tickets.push(ticket));
          }
        }
      });

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          if (filters[key]) {
            s_tickets = f_tickets;
            break;
          }
        }
      }

      s_tickets = s_tickets.sort((a, b) => {
        return new Date(a.order_placed) - new Date(b.order_placed);
      });

      if (s_tickets.length > _tickets.length) {
        let audio = new Audio(tickAudio);

        audio.play();
      }

      setTickects([...s_tickets]);
    }
  }, [filters, tickets]);

  return (
    <div>
      <h2 className="alert-title">Pool</h2>
      <div className={classes.filters}>
        <Grid container>
          <Grid item sm={6}>
            {states.map(
              (state, index) =>
                index <= 2 && (
                  <div
                    key={state}
                    className={classes.filter}
                    onClick={() => {
                      setFilters((prev) => {
                        return { ...prev, [state]: !filters[state] };
                      });
                    }}
                  >
                    <GreenCheckbox /> {state}
                  </div>
                )
            )}
          </Grid>
          <Grid item sm={6}>
            {states.map(
              (state, index) =>
                index >= 3 && (
                  <div
                    key={state}
                    className={classes.filter}
                    onClick={() =>
                      setFilters((prev) => {
                        return { ...prev, [state]: !filters[state] };
                      })
                    }
                  >
                    <GreenCheckbox /> {state}
                  </div>
                )
            )}
          </Grid>
        </Grid>
      </div>
      <Droppable droppableId={_id}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ height: "84vh", overflowY: "scroll" }}
          >
            {_tickets.map((item, index) => (
              <PoolItem
                key={item._id}
                index={index}
                {...item}
                _onTimeOut={() => {}}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  filters: {
    marginBottom: 20,
  },

  select: {
    "& .MuiSelect-select": {
      paddingLeft: 10,
      paddingRight: 25,
    },
  },

  filter: {
    fontWeight: "bold",
    "& .MuiCheckbox-root": {
      backgroundColor: "#EBEBEB",
      padding: 0,
      "& :hover": {
        backgroundColor: "#EBEBEB",
      },
    },
  },
}));

export default Pool;
