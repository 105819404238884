import {
  makeStyles,
  Grid,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkToken } from "../utils/check.token";
import Loader from "react-loader-spinner";
import { SearchOutlined, Apps, ViewList, Refresh } from "@material-ui/icons";
import CollectionItem from "../components/complexes/CollectionItem";
import UpdateCollectionComplex from "../components/complexes/UpdateCollectionComplex";
import { getCollectionComplexes } from "../redux/actions/complexes.action";
import ClosureReason from "../components/complexes/ClosureReason";
import { BASE_URL } from "../app.config";
import CloseUntil from "../components/complexes/CloseUntil";

const CollectionComplexes = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const collection_complexes = useSelector(
    (state) => state.collection_complexes
  );
  const isLoading = useSelector(
    (state) => state.collection_complexes?.isLoading
  );
  const { user } = useSelector((state) => state.auth);

  const [select, setSelect] = useState(false);
  const [closing, setClosing] = useState(false);
  const [selected, setSelected] = useState([]);
  const [query, setQuery] = useState("");
  const [layout, setLayout] = useState(3);
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState({});
  const [reason, setReason] = useState("");
  const [other, setOther] = useState("");
  const [showReasons, setShowReasons] = useState(false);
  const [closeUntil, setCloseUntil] = useState(false);

  const _onSelectComplex = (complex) => {
    if (selected.find((f) => f.uuid === complex.uuid)) {
      setSelected(selected.filter((f) => f.uuid !== complex.uuid));
    } else {
      setSelected([...selected, complex]);
    }
  };

  const _onSubmitBulkOpenClose = async (closed_until) => {
    setClosing(true);

    const data = selected.map((complex) => {
      return {
        complex_uuid: complex.uuid,
        complex_type: complex.type,
        closure_reason: reason,
        agent: user,
        open: "closed",
        closed_until,
      };
    });

    const url = `${BASE_URL}/bulk_complex_closure`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result) {
      setShowReasons(false);
      setClosing(false);
      setReason("");
      setOther("");
      setSelected([]);
      setSelect(!select);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <div>
      <div className={classes.header}>
        <h2 className={classes.title}>Collection Complexes</h2>
      </div>

      <div className={classes.main}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item md={8} lg={7}>
            <div className={classes.searchbar}>
              <TextField
                variant="outlined"
                placeholder="search..."
                size="small"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>

          <Grid item>
            <Button
              variant="text"
              className={classes.btn}
              style={{ backgroundColor: layout !== 12 ? "#ed1b24" : "" }}
              onClick={() => setLayout(3)}
            >
              <Apps className={classes.btnIcon} />
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="text"
              className={classes.btn}
              style={{ backgroundColor: layout === 12 ? "#ed1b24" : "" }}
              onClick={() => setLayout(12)}
            >
              <ViewList className={classes.btnIcon} />
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={() => dispatch(getCollectionComplexes(user.country))}
            >
              <Refresh />
            </Button>
          </Grid>

          <Grid item>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item>{select && <h3>Selected: {selected.length}</h3>}</Grid>
              <Grid item>
                {select && selected.length !== 0 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setShowReasons(true);
                    }}
                  >
                    Close
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ borderRadius: 20 }}
                  onClick={() => {
                    setSelect(!select);
                    setSelected([]);
                  }}
                >
                  {select ? "Cancel" : "Close Complexes"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <br />
        <br />
        <Grid container spacing={layout === 12 ? 0 : 2}>
          {collection_complexes?.list
            .filter((f) => f.name.toLowerCase().includes(query.toLowerCase()))
            .map((item, index) => (
              <Grid key={index} item md={layout}>
                <CollectionItem
                  {...item}
                  layout={layout}
                  select={select}
                  selected={selected.find((f) => f.uuid === item.uuid)}
                  _onSelectComplex={() => _onSelectComplex(item)}
                  _onEdit={() => {
                    setActive({
                      ...item,
                    });
                    setEdit(true);
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </div>

      {(collection_complexes?.list.length === 0 || isLoading) && (
        <div
          style={{
            textAlign: "center",
            marginTop: 20,
          }}
        >
          <Loader type="ThreeDots" color="red" width="60" height="60" />
        </div>
      )}

      <UpdateCollectionComplex
        open={edit}
        handleDialog={() => setEdit(false)}
        close={() => setEdit(false)}
        data={active}
      />

      <ClosureReason
        open={showReasons}
        close={() => {
          setShowReasons(false);
          setReason("");
          setOther("");
        }}
        closing={closing}
        selected={reason}
        setOther={(value) => setOther(value)}
        other={other}
        onSelect={(value) => {
          if (value === "Other" && value === reason) {
            setOther("");
          }
          setReason(value === reason ? "" : value);
        }}
        _onSubmit={() => {
          setShowReasons(false);
          setCloseUntil(true);
        }}
      />

      <CloseUntil
        open={closeUntil}
        closing={closing}
        close={() => setCloseUntil(false)}
        _onSubmit={(value) => {
          _onSubmitBulkOpenClose(value);
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },

  title: {
    marginTop: 1,
    marginBottom: 1,
    marginLeft: 12,
  },

  main: {
    padding: "25px 80px",
  },

  searchbar: {
    paddingLeft: theme.spacing(4),
    width: "100%",
    "& > div": {
      backgroundColor: "#fff",
      width: "100%",
      borderRadius: 5,
    },
  },

  btn: {
    height: "100%",
    backgroundColor: "#333333",
    color: "#fff",
    padding: "5px 0px",
    minWidth: 45,

    "&:hover": {
      backgroundColor: "#000",
    },
  },

  btnIcon: {
    fontSize: 28,
  },
}));

export default CollectionComplexes;
