import React, { useEffect, useState } from "react";
import {
  makeStyles,
  FormControlLabel,
  Switch,
  withStyles,
} from "@material-ui/core";
import { RedCheckbox } from "../commons/checkboxs";
import { useSelector } from "react-redux";

const StockItem = ({
  product,
  complex_uuid,
  brand_uuid,
  checked,
  _onDisable,
  _onSelect,
  role,
}) => {
  const classes = useStyles();
  const { isLoading } = useSelector((state) => state.stocks);

  const [_active, setActive] = useState(true);

  useEffect(() => {
    setActive(product.available);
  }, [product]);

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: !product.available ? "#f0f0f0" : "" }}
    >
      <div className={classes.item} style={{ flex: 1 }}>
        {role !== "agent" && (
          <RedCheckbox
            onChange={() => _onSelect(product)}
            checked={checked()}
          />
        )}
      </div>

      <div className={classes.item} style={{ flex: 4 }}>
        <p className={classes.text}>{product.name?.en}</p>
      </div>

      <div className={classes.item} style={{ flex: 4 }}>
        <p className={classes.text}>{product.description?.en}</p>
      </div>

      <div className={classes.item} style={{ flex: 2 }}>
        {product.prices &&
          Object.entries(product.prices).map(([key, value], index) => (
            <p
              style={{
                fontSize: 15,
                margin: 0,
                marginTop: index === 0 ? 5 : 0,
                marginBottom: index > 0 ? 5 : 0,
              }}
            >
              <span style={{ fontWeight: 800, fontSize: 13, marginRight: 1 }}>
                {key}
              </span>

              {value}
            </p>
          ))}
      </div>
      <div className={classes.item} style={{ flex: 2 }}>
        <p className={classes.text}>{product.type}</p>
      </div>

      <div className={classes.item} style={{ flex: 3 }}>
        <p className={classes.text}>{product.complex_name}</p>
      </div>

      <div className={classes.item} style={{ flex: 2 }}>
        <p className={classes.text}>{product.brand_name}</p>
      </div>

      <div className={classes.item} style={{ flex: 1 }}>
        {role !== "agent" && (
          <FormControlLabel
            control={
              <MySwitch
                checked={_active}
                onChange={() => {
                  if (!isLoading) {
                    _onDisable({
                      product,
                      complex_uuid,
                      brand_uuid,
                      available: !_active,
                    });
                    setActive(!_active);
                  }
                }}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 5,
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #bfbfbf",

    "&:hover": {
      backgroundColor: "#EBFCFF",
    },

    "& .edit": {
      display: "none",
    },

    "&:hover .edit": {
      display: "block",
    },
  },

  btn: {
    borderRadius: 20,
    textTransform: "none",
    fontSize: 12,
    backgroundColor: "#f0f0f0",
    color: "#000",
    marginRight: 22,

    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },

  item: {
    flex: 1,
  },

  text: {
    fontSize: 15,
    margin: "7px 0px",
  },

  editBtn: {
    padding: 2,
    borderRadius: 0,
    textTransform: "none",
    fontSize: 10,
    backgroundColor: "#595757",
    color: "#fff",
    marginRight: 22,

    "&:hover": {
      backgroundColor: "#595757",
    },
  },
}));

const MySwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#099c40",
    },
    "&$checked + $track": {
      backgroundColor: "#099c40",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default StockItem;
