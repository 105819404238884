import * as types from "../action.types";

export const zoneComplexReducer = (state, action) => {
  switch (action.type) {
    case types.GET_ZONES_COMPLEXES_LOADING:
      return { isLoading: true, zones_list: null };

    case types.GET_ZONES_COMPLEXES_FAILED:
      return {
        isLoading: false,
        error: action.payload,
        zones_list: null,
      };

    case types.GET_ZONES_COMPLEXES_SUCCESS:
      return { zones_list: action.payload, isLoading: false };

    case types.UPDATE_ZONE_COMPLEX_LOADING:
      return { zones_list: state.zones_list, isLoading: true, updated: false };

    case types.UPDATE_ZONE_COMPLEX_FAILED:
      return {
        zones_list: state.zones_list,
        isLoading: false,
        error: action.payload,
        updated: false,
      };

    case types.UPDATE_ZONE_COMPLEX_SUCCESS:
      const zones_list = state.zones_list;
      const updated_complex = action.payload;

      if (zones_list?.length > 0) {
        zones_list.map((zone) => {
          if (zone.zone_uuid === updated_complex.data.zone_uuid) {
            let index;
            zone[`${updated_complex.data.complex_type}`].map((complex, i) => {
              if (complex.uuid === updated_complex.data.uuid) {
                index = i;
              }
            });
            if (index >= 0) {
              zone[`${updated_complex.data.complex_type}`][index] =
                updated_complex.data;
            }
          }

          return zone;
        });
        // Update the list with the updated complex
      }

      return { zones_list, isLoading: false, updated: true };

    default:
      return state;
  }
};
