/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CloseUntil = ({ open, close, closing, _onSubmit }) => {
  const classes = useStyles();
  const [closedUntil, setClosedUntil] = useState(
    moment().format("YYYY-MM-DD HH:mm")
  );

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        className={classes.root}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: 0,
            boxShadow: "none",
            minWidth: 550,
          },
        }}
      >
        <DialogTitle className={classes.header}>
          <div
            onClick={closing ? null : close}
            align="right"
            style={{ marginBottom: -40 }}
          >
            <IconButton className={classes.closeBtn}>
              <Close />
            </IconButton>
          </div>
          <strong>CLOSE UNTIL</strong>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              width: "60%",
            }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                fullWidth
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                margin="dense"
                ampm={false}
                autoOk={true}
                format="DD-MM-YYYY HH:mm"
                label="Select Date & Time"
                value={closedUntil}
                onChange={(date) => {
                  setClosedUntil(moment(date).format("YYYY-MM-DD HH:mm"));
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>

          <Button
            fullWidth
            className={classes.btn}
            color="primary"
            onClick={closedUntil ? () => _onSubmit(closedUntil) : null}
          >
            {closing ? (
              <CircularProgress
                style={{ color: "#ffff", width: 20, height: 20 }}
              />
            ) : (
              "SAVE"
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    borderTop: "3px #fff  solid",
  },
  btn: {
    marginTop: 10,
    color: "#ffff",
    backgroundColor: "green",
    fontWeight: "bold",
    "&:hover": {
      color: "#ffff",
      backgroundColor: "green",
    },
  },
}));

export default CloseUntil;
