import * as types from "../action.types";

export const complexesReducer = (state, action) => {
  switch (action.type) {
    case types.GET_COMPLEXES_LOADING:
      return { isLoading: true, zones_list: null };

    case types.GET_COMPLEXES_FAILED:
      return {
        isLoading: false,
        error: action.payload,
        complexes: null,
      };

    case types.GET_COMPLEXES_SUCCESS:
      return { list: action.payload, isLoading: false };

    default:
      return state;
  }
};
