import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  ListItemText,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
  TextField,
} from "@material-ui/core";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getComplexes } from "../redux/actions/complexes.action";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Menu from "../components/products/menu";
import {
  getBrandMenu,
  updateBrandComplexMenu,
} from "../redux/actions/menu.actions";
import { SpoonKnife } from "@styled-icons/icomoon/SpoonKnife";

const Products = (props) => {
  const classes = useStyles();

  const r_menu = useSelector((state) => state.menu);
  const { user } = useSelector((state) => state.auth);
  const remote = useSelector((state) => state.remote);
  const r_complexes = useSelector((state) => state.complexes);

  const dispatch = useDispatch();

  const [complexes, setComplexes] = useState();
  const [complex, setComplex] = useState("");
  const [brand, setBrand] = useState("");
  const [method, setMethod] = useState("");
  const [source, setSource] = useState("callcenter");
  const [menu, setMenu] = useState();
  const [active_menu_path, setActiveMenuPath] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user.brand) {
      const _brand = remote.country?.brands.find((b) => b.name === user.brand);
      if (_brand) {
        setBrand(_brand);
      }
    }
  }, [remote, user]);

  useEffect(() => {
    if (r_menu) {
      if (r_menu.payload) {
        const mu = {
          specials: r_menu.payload.specials ?? [],
          brand: r_menu.payload.brand[0],
          categories: r_menu.payload.categories ?? [],
          items: r_menu.payload.items ?? [],
          groups: r_menu.payload.groups ?? [],
          sizes: r_menu.payload.sizes ?? [],
          options: r_menu.payload.options ?? [],
          tab_header_config: r_menu.payload.tab_header_config,
          available_currencies: complex.available_currencies,
        };

        setMenu(mu);
        setLoading(false);
      } else {
        if (r_menu.error) {
          setLoading(false);
        }
      }
    }
  }, [r_menu, complex]);

  useEffect(() => {
    if (!r_complexes) {
      dispatch(getComplexes(user.country));
    } else {
      if (r_complexes?.list) {
        const _complexes = r_complexes.list.map((_complex) => _complex.complex);
        setComplexes(_complexes);
      }
    }
  }, [r_complexes, user, dispatch]);

  const getMenu = () => {
    try {
      if (brand && method && source && complex) {
        setMenu();
        setLoading(true);
        const mode = method === "In Store" ? "in_store" : method.toLowerCase();
        const url = `${brand.uuid}/${mode}/${source}/${complex.uuid}`;
        setActiveMenuPath(url);
        dispatch(getBrandMenu(`menu/${url}`));
      }
    } catch (error) {}
  };

  const updateMenu = (_menu, key) => {
    const url = `update_menu/${active_menu_path}`;

    setMenu({ ..._menu });

    dispatch(updateBrandComplexMenu(url, { [`${key}`]: _menu[`${key}`] }));
  };

  return (
    <>
      <div className={classes.header} style={{ display: "inline-flex" }}>
        <div style={{ fontWeight: "bold", paddingTop: 12, fontSize: 15 }}>
          Products
        </div>
      </div>
      <div className={classes.main} style={{ marginTop: 20 }}>
        <div className={classes.content}>
          <Grid container>
            <Grid
              item
              sm={3}
              style={{
                flexBasis: "20%",
                maxWidth: "20%",
                marginRight: 10,
              }}
            >
              <div style={{ marginBottom: 10, display: "inline-flex" }}>
                <RadioGroup
                  aria-label="source"
                  name="source"
                  value={source}
                  onChange={(event) => setSource(event.target.value)}
                >
                  <div style={{ display: "inline-flex" }}>
                    <FormControlLabel
                      value="callcenter"
                      control={<Radio />}
                      label="Call Centre"
                      className="products-source"
                    />
                    <FormControlLabel
                      value="customer"
                      control={<Radio />}
                      label="Customer"
                      className="products-source"
                    />
                  </div>
                </RadioGroup>
              </div>
              <Card className={classes.card} style={{ marginBottom: 10 }}>
                <div className={classes.cardHeader}>complex</div>
                <CardContent
                  className={classes.cardContent}
                  style={{ paddingBottom: "0 !important" }}
                >
                  <Paper
                    elevation={0}
                    className={classes.paper}
                    style={{
                      paddingBottom: 0,
                      paddingLeft: 8,
                    }}
                  >
                    {complexes ? (
                      <Autocomplete
                        id="complex-autoselect"
                        value={complex}
                        disableClearable
                        onChange={(event, newValue) => {
                          setComplex(newValue ?? "");
                        }}
                        options={complexes}
                        getOptionLabel={(option) => option.name ?? ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={!complex && "select a complex"}
                            margin="normal"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            style={{ margin: 0, marginTop: -2 }}
                          />
                        )}
                      />
                    ) : (
                      <div
                        style={{
                          fontStyle: "italic",
                          paddingTop: 8,
                          paddingBottom: 8,
                        }}
                      >
                        Fetching Complexes...
                      </div>
                    )}
                  </Paper>
                </CardContent>
              </Card>
              {!user.brand && (
                <Card className={classes.card} style={{ marginBottom: 10 }}>
                  <div className={classes.cardHeader}>brand</div>
                  <CardContent
                    className={classes.cardContent}
                    style={{ paddingBottom: "0 !important" }}
                  >
                    <Paper
                      elevation={0}
                      className={classes.paper}
                      style={{
                        paddingBottom: 0,
                        paddingLeft: 8,
                      }}
                    >
                      <FormControl className={classes.formControl} fullWidth>
                        <Select
                          value={brand}
                          disableUnderline
                          displayEmpty
                          onChange={(event) => {
                            setBrand(event.target.value);
                          }}
                          inputProps={{
                            name: "brand",
                            id: "brand-simple",
                          }}
                        >
                          <MenuItem value="">
                            <em>select a brand</em>
                          </MenuItem>
                          {remote &&
                            remote.country?.brands.map((_brand, i) => (
                              <MenuItem
                                key={_brand.uuid + i}
                                value={_brand}
                                className={classes.hover}
                              >
                                {_brand.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Paper>
                  </CardContent>
                </Card>
              )}
              <Card className={classes.card} style={{ marginBottom: 10 }}>
                <div className={classes.cardHeader}>method</div>
                <CardContent
                  className={classes.cardContent}
                  style={{ paddingBottom: "0 !important" }}
                >
                  <Paper elevation={0} className={classes.paper}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        value={method}
                        disableUnderline
                        displayEmpty
                        onChange={(event) => {
                          setMethod(event.target.value);
                        }}
                        inputProps={{
                          name: "method",
                          id: "method-simple",
                        }}
                      >
                        <MenuItem value="">
                          <em>select a method</em>
                        </MenuItem>
                        {remote &&
                          remote.country &&
                          remote.country.methods &&
                          remote.country.methods.map((_method, i) => (
                            <MenuItem
                              key={_method + i}
                              value={_method}
                              className={classes.hover}
                            >
                              {_method}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Paper>
                </CardContent>
              </Card>
              <Button
                fullWidth={true}
                onClick={() => getMenu()}
                variant="contained"
                style={{ backgroundColor: "#D71920", borderRadius: 8 }}
              >
                {loading ? (
                  <Loader type="Oval" color="#fff" width="20" height="20" />
                ) : (
                  <ListItemText
                    disableTypography
                    style={{ margin: 0 }}
                    className={classes.primary}
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Display Menu
                      </Typography>
                    }
                  />
                )}
              </Button>
            </Grid>
            <Grid item sm={7}>
              {menu ? (
                <Menu menu={menu} updateMenu={updateMenu} />
              ) : loading ? (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <div style={{ marginBottom: 20 }}>
                    <Loader
                      type="Oval"
                      color="#D71920"
                      width="50"
                      height="50"
                    />
                  </div>
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    fetching brand complex menu
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <div>
                    <SpoonKnife className={classes.icon} />
                  </div>
                  <div
                    style={{
                      fontSize: 25,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {r_menu?.error ? r_menu?.error : "select menu to display"}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    backgroundColor: "#fcfcfc",
    width: "100%",
    minHeight: 40,
  },
  main: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  header: {
    backgroundColor: "#fff",
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
  },
  card: {
    border: "1px solid gray",
    backgroundColor: "#fff",
    boxShadow: "none",
    borderRadius: 0,
  },
  cardContent: {
    padding: "0px !important",
  },
  cardHeader: {
    display: "flex",
    backgroundColor: "#474546",
    paddingLeft: 15,
    paddingBottom: 8,
    paddingTop: 8,
    color: "#fff",
    fontWeight: "bold",
    textTransform: "uppercase",
  },

  content: {
    paddingTop: 0,
    minHeight: "65vh",
  },
  paper: {
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 8,
  },
  icon: {
    marginRight: 20,
    width: 50,
    height: 50,
    marginBottom: 20,
    color: "#D71920",
  },
}));

export default Products;
