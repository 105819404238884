import * as types from "../action.types";

const initState = {
  country: {
    name: "",
  },
};
export const remoteConfigReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_REMOTE_CONFIG_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};
