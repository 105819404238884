export const countryTimeZone = (country_name) => {
  let time_zone;
  if (country_name && country_name.toLowerCase() === "kenya") {
    time_zone = `Africa/Nairobi`;
  } else {
    time_zone = `Africa/Harare`;
  }

  return time_zone;
};
