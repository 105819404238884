import * as types from "../action.types";
import { BASE_URL } from "../../app.config";

export function getUsers(country) {
  return async (dispatch) => {
    dispatch({ type: types.GET_USERS_LOADING });

    const response = await fetch(`${BASE_URL}/get_agents/${country}`, {
      method: "GET",
    });

    const result = await response.json();

    if (!result.message) {
      dispatch({
        type: types.GET_USERS_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.GET_USERS_FAILED,
        payload: result,
      });
    }
  };
}

export function refrestUsers(country) {
  return async (dispatch) => {
    dispatch({ type: types.REFRESH_USERS_LOADING });

    const response = await fetch(`${BASE_URL}/get_agents/${country}`, {
      method: "GET",
    });

    const result = await response.json();

    if (!result.message) {
      dispatch({
        type: types.REFRESH_USERS_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.REFRESH_USERS_FAILED,
        payload: result,
      });
    }
  };
}

export function addUser(data) {
  return async (dispatch) => {
    dispatch({ type: types.ADD_USER_LOADING });

    const response = await fetch(`${BASE_URL}/agent/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        password: "dad123",
      }),
    });

    const result = await response.json();
    if (result.uuid) {
      dispatch({
        type: types.ADD_USER_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.ADD_USER_FAILED,
        payload: result,
      });
    }
  };
}

export function updateUserDetails(data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_USER_LOADING });

    const response = await fetch(`${BASE_URL}/agent/update_user`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result) {
      dispatch({
        type: types.UPDATE_USER_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.UPDATE_USER_FAILED,
        payload: result,
      });
    }
  };
}

export function disableUser(data) {
  return async (dispatch) => {
    dispatch({ type: types.DISABLE_USER_LOADING, payload: data });

    const response = await fetch(`${BASE_URL}/agent/disable_user`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result) {
      dispatch({
        type: types.DISABLE_USER_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.DISABLE_USER_FAILED,
        payload: result,
      });
    }
  };
}

export function resetUserPassword(data) {
  return async (dispatch) => {
    dispatch({ type: types.RESET_USER_PASSWORD_LOADING, payload: data });

    const response = await fetch(`${BASE_URL}/agent/reset_password`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({ ...data, password: "dad123" }),
    });

    const result = await response.json();
    if (result) {
      dispatch({
        type: types.RESET_USER_PASSWORD_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.RESET_USER_PASSWORD_FAILED,
        payload: result,
      });
    }
  };
}
