import * as types from "../action.types";

export const menuReducer = (state, action) => {
  switch (action.type) {
    case types.GET_MENU_LOADING:
      return { isLoading: true };

    case types.GET_MENU_FAILED:
      return {
        isLoading: false,
        error: action.payload,
        payload: null,
      };

    case types.GET_MENU_SUCCESS:
      return { payload: action.payload, isLoading: false };

    case types.UPDATE_MENU_SUCCESS:
      return { payload: state.payload, isLoading: false, updated: true };

    default:
      return state;
  }
};
