export const straightToKitchen = async (complex_uuid, active, base_url) => {
  try {
    const url = base_url + "/rest/straight_to_kitchen";

    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        AUTHORIZATION: "y_bHqy-Y5wor07I4tFMuCh2PbUdBR_fhWkFKC8-JyAo",
        TOKEN: "EROmYghE2wZgdGIOgAmliHXJv-kf57eIfVjOJ7ROq2c",
      },
      body: JSON.stringify({
        complex_uuid,
        active,
      }),
    });

    response = await response.json();

    if (response) {
      return response.success;
    }

    return false;
  } catch (error) {
    return false;
  }
};
