import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ZonesList from "../components/complexes/ZonesList";
import Loader from "react-loader-spinner";
import { SearchOutlined } from "@material-ui/icons";
import { checkToken } from "../utils/check.token";
import ClosureReason from "./../components/complexes/ClosureReason";
import { BASE_URL } from "../app.config";
import CloseUntil from "../components/complexes/CloseUntil";

const headings = ["Zone", "Primary", "Auxiliary", "Premium", "Overflow"];

const Complexes = (props) => {
  const { user } = useSelector((state) => state.auth);
  const zones_complexes = useSelector((state) => state.zones_complexes);

  const [select, setSelect] = useState(false);
  const [selected, setSelected] = useState([]);
  const [closing, setClosing] = useState(false);
  const [reason, setReason] = useState("");
  const [other, setOther] = useState("");
  const [showReasons, setShowReasons] = useState(false);
  const classes = useStyles();
  const [query, setQuery] = useState("");
  const [closeUntil, setCloseUntil] = useState(false);

  useEffect(() => {
    checkToken();
  }, []);

  const _onSelectComplex = (complex) => {
    if (selected.find((f) => f.uuid === complex.uuid)) {
      setSelected(selected.filter((f) => f.uuid !== complex.uuid));
    } else {
      const complexes = [];

      zones_complexes?.zones_list.forEach((element) => {
        element.primary?.forEach((element2) => {
          if (element2.uuid === complex.uuid) {
            complexes.push({
              name: element2.name,
              uuid: element2.uuid,
              type: "primary",
              open: "closed",
              zone_uuid: element.zone_uuid,
            });
          }
        });

        element.auxiliary?.forEach((element2) => {
          if (element2.uuid === complex.uuid) {
            complexes.push({
              name: element2.name,
              uuid: element2.uuid,
              type: "auxiliary",
              open: "closed",
              zone_uuid: element.zone_uuid,
            });
          }
        });

        element.premium?.forEach((element2) => {
          if (element2.uuid === complex.uuid) {
            complexes.push({
              name: element2.name,
              uuid: element2.uuid,
              type: "premium",
              open: "closed",
              zone_uuid: element.zone_uuid,
            });
          }
        });
      });

      setSelected([...selected.concat(complexes)]);
    }
  };

  const _onSubmitBulkOpenClose = async (closed_until) => {
    setClosing(true);

    const data = selected.map((complex) => {
      return {
        complex_uuid: complex.uuid,
        complex_type: complex.type,
        zone_uuid: complex.zone_uuid,
        closure_reason: reason,
        open: "closed",
        agent: user,
        closed_until,
      };
    });

    const url = `${BASE_URL}/bulk_zone_complex_closure`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result) {
      setCloseUntil(false);
      setShowReasons(false);
      setClosing(false);
      setReason("");
      setOther("");
      setSelected([]);
      setSelect(!select);
    }
  };

  return (
    <div>
      <div className={classes.searchbar_container}>
        <h2 className={classes.name}>Delivery Complexes</h2>
        <div className={classes.searchbar}>
          <TextField
            variant="outlined"
            placeholder="Search Zone"
            size="small"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{ flex: 1 }} />
        {select && (
          <div style={{ paddingRight: 15 }}>
            <h3>Selected: {selected.length}</h3>
          </div>
        )}

        {select && selected.length !== 0 && (
          <div style={{ paddingRight: 40 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setShowReasons(true);
              }}
            >
              Close
            </Button>
          </div>
        )}

        <div style={{ paddingTop: 5, paddingRight: 50 }}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ borderRadius: 20 }}
            onClick={() => {
              setSelect(!select);
              setSelected([]);
            }}
          >
            {select ? "Cancel" : "Close Complexes"}
          </Button>
        </div>
      </div>
      <br />

      <div className={classes.main}>
        <div className={classes.header} spacing={2}>
          {headings.map((title, index) => (
            <div key={index} className={classes.headerItem}>
              <p className={classes.title}>{title}</p>
            </div>
          ))}
        </div>

        {zones_complexes?.zones_list ? (
          <div
            className={classes.content}
            style={{
              height:
                zones_complexes?.zones_list.filter((f) =>
                  f.name.toLowerCase().includes(query.toLowerCase())
                ).length > 10
                  ? "88vh"
                  : "inherit",
            }}
          >
            <ZonesList
              zones={zones_complexes?.zones_list.filter((f) =>
                f.name.toLowerCase().includes(query.toLowerCase())
              )}
              select={select}
              selected={selected}
              _onSelectComplex={_onSelectComplex}
            />
          </div>
        ) : zones_complexes?.error ? (
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
            }}
          >
            {zones_complexes?.error.message}
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
            }}
          >
            <Loader type="ThreeDots" color="red" width="60" height="60" />
          </div>
        )}
      </div>

      <ClosureReason
        open={showReasons}
        close={() => {
          setShowReasons(false);
          setReason("");
          setOther("");
        }}
        selected={reason}
        setOther={(value) => setOther(value)}
        other={other}
        closing={closing}
        onSelect={(value) => {
          if (value === "Other" && value === reason) {
            setOther("");
          }
          setReason(value === reason ? "" : value);
        }}
        _onSubmit={() => {
          setCloseUntil(true);
          setShowReasons(false);
        }}
      />

      <CloseUntil
        open={closeUntil}
        closing={closing}
        close={() => setCloseUntil(false)}
        _onSubmit={(value) => {
          _onSubmitBulkOpenClose(value);
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  searchbar_container: {
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  notchedOutline: {
    border: 0,
  },
  searchbar: {
    paddingLeft: theme.spacing(4),
    width: "50%",
    "& > div": {
      backgroundColor: "#EBFCFF",
      width: "100%",
      borderRadius: 5,
    },
  },
  header: {
    backgroundColor: "#ed1b24",
    width: "100%",
    padding: 5,
    display: "flex",
    flexDirection: "row",
  },

  headerItem: {
    flex: 1,
  },
  title: {
    color: "#fff",
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
  },

  name: {
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 12,
  },

  content: {
    backgroundColor: "#fff",
    overflow: "scroll",
    padding: 7,
    paddingTop: 0,
  },
}));

export default Complexes;
