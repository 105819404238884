import React from "react";
import PoolItem from "./PoolItem";
import { Droppable } from "react-beautiful-dnd";

const MyTickets = ({ _id, tickets, _onTimeOut }) => {
  return (
    <div>
      <h2 className="alert-title">My Tickets</h2>

      <Droppable droppableId={_id}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ height: "84vh", overflowY: "scroll" }}>
            {tickets.map((item, index) => (
              <PoolItem
                key={item._id}
                index={index}
                {...item}
                _onTimeOut={_onTimeOut}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default MyTickets;
