import * as types from "../action.types";

export const stockReducer = (state, action) => {
  switch (action.type) {
    case types.GET_STOCK_LOADING:
      return { isLoading: true, products: null };

    case types.GET_STOCK_FAILED:
      return {
        isLoading: false,
        error: action.payload,
        data: null,
      };

    case types.GET_STOCK_SUCCESS:
      return { data: action.payload, isLoading: false };

    case types.DISABLE_PRODUCT_LOADING:
      return { isLoading: true, data: state.data };

    case types.DISABLE_PRODUCT_SUCCESS:
      state.data = state.data.map((product) => {
        const u_p = action.payload.find((p) => {
          if (product.uuid === p.uuid) {
            if (product.type === p.type) {
              return p;
            }
          }
        });

        if (u_p) {
          product = u_p;
        }

        return product;
      });

      return { data: state.data, isLoading: false, disabled: true };

    default:
      return state;
  }
};
