import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { Check, Edit } from "@material-ui/icons";

import icon_rains from "../../assets/icon-rains_24px@2x.png";
import icon_pizza_inn_delay from "../../assets/icon-pizza-inn_24px@2x.png";
import icon_delivery_time from "../../assets/icon-action-favorite_24px@2x.png";
import { useSelector } from "react-redux";

const ComplexItem = ({
  name,
  select,
  selected,
  _onSelectComplex,
  opening_times,
  _onEdit,
  delivery_time,
  rains,
  pizza_inn_delay,
}) => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth?.user);

  return (
    <div
      className={classes.root}
      onClick={select ? _onSelectComplex : () => {}}
      style={
        select
          ? { cursor: "pointer", backgroundColor: selected ? "#bfedf5" : "" }
          : {}
      }>
      {user?.role !== "agent" && !select && (
        <IconButton
          variant="contained"
          color="secondary"
          className={classes.editBtn}
          onClick={_onEdit}>
          <Edit className={classes.btnIcon} />
        </IconButton>
      )}

      {user?.role !== "agent" && select && (
        <IconButton
          variant="contained"
          color="secondary"
          className={classes.selectBtn}>
          {selected ? (
            <Check className={classes.selectIcon} />
          ) : (
            <div style={{ height: 15, width: 15 }} />
          )}
        </IconButton>
      )}

      <p className={classes.title}>{name}</p>
      <p className={classes.times}>{opening_times}hrs</p>
      <div style={{ marginTop: 5 }}>
        {delivery_time && (
          <div
            className={classes.delivery_time}
            style={{ marginLeft: pizza_inn_delay && rains ? 5 : 0 }}>
            <img
              className={classes.pizza_inn_delay_icon}
              src={icon_delivery_time}
              alt="Delivery Time"
            />
            <p>{delivery_time}</p>
          </div>
        )}
        {pizza_inn_delay && (
          <div
            className={classes.pizza_inn_delay}
            style={{ marginLeft: delivery_time ? 5 : 0 }}>
            <img
              className={classes.pizza_inn_delay_icon}
              src={icon_pizza_inn_delay}
              alt="Delays"
            />
            <p>Delays</p>
          </div>
        )}
        {rains && (
          <div
            className={classes.rains}
            style={{
              marginLeft: pizza_inn_delay && delivery_time ? 5 : 10,
              marginTop: pizza_inn_delay && delivery_time ? 5 : 0,
            }}>
            <img className={classes.rains_icon} src={icon_rains} alt="Delays" />
            <p>Delays</p>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: 4,
    borderRadius: 3,
    marginBottom: 1,
    marginRight: 19,
    "&:hover": {
      backgroundColor: "#EBFCFF",
    },
    "&:hover > button": {
      visibility: "visible",
    },
  },

  title: {
    margin: 0,
    fontSize: 13,
    fontWeight: 600,
  },

  times: {
    margin: 0,
    fontSize: 11,
    color: "#525252",
  },

  delivery_time: {
    margin: 0,
    fontSize: 11,
    display: "inline-flex",
    border: " 1px solid #65ba87",
    borderRadius: 40,
    padding: 2,
    "&>p": {
      color: "green",
      margin: 0,
      marginLeft: 5,
    },
  },

  delivery_time_icon: {
    height: 20,
    color: "#fff",
    width: 20,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 20,
    backgroundColor: "green",
  },

  pizza_inn_delay: {
    margin: 0,
    fontSize: 11,
    display: "inline-flex",
    border: " 1px solid #e85f5a",
    borderRadius: 40,
    padding: 2,
    "&>p": {
      color: "#eb4742",
      margin: 0,
      marginLeft: 5,
    },
  },

  pizza_inn_delay_icon: {
    height: 20,
    color: "#fff",
    width: 20,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 20,
    // backgroundColor: "#eb4742",
  },
  rains: {
    margin: 0,
    fontSize: 11,
    display: "inline-flex",
    border: " 1px solid #589cf5",
    borderRadius: 40,
    padding: 2,
    "&>p": {
      color: "#167BFF",
      margin: 0,
      marginLeft: 5,
    },
  },

  rains_icon: {
    height: 20,
    color: "#fff",
    width: 20,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 20,
    // backgroundColor: "#167BFF",
  },

  editBtn: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 5,
    visibility: "hidden",
  },

  selectBtn: {
    position: "absolute",
    right: 4,
    top: 4,
    padding: 2,
    borderRadius: 30,
    border: "1px solid #70706f",
  },

  selectIcon: {
    fontSize: 17,
    color: "#000",
  },
  btnIcon: {
    fontSize: 17,
  },
}));
export default ComplexItem;
