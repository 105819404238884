import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core/";
import { ArrowForwardIos, ExpandMore, MoreHoriz } from "@material-ui/icons";

const ShopItem = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const submit = (item) => {
    props.addProduct(item);
  };

  return (
    <Accordion
      expanded={props.category.available && !collapsed && !anchorEl}
      onChange={() => setCollapsed(!collapsed)}
    >
      <AccordionSummary
        style={{
          backgroundColor: "#f4f4f4",
          boxShadow: "none",
          minHeight: 40,
        }}
      >
        {collapsed ? (
          <ArrowForwardIos style={{ width: 15 }} />
        ) : !props.category.available ? (
          <ArrowForwardIos style={{ width: 15 }} />
        ) : (
          <ExpandMore />
        )}

        <ListItemText
          className="products-shop"
          primary={props.category.name.en}
        />

        <ListItemSecondaryAction>
          <ListItemText
            primary={
              <div>
                {!props.category.available && (
                  <div className={props.classes.available}>
                    <p>Unavailable</p>
                  </div>
                )}
                <IconButton
                  className={props.classes.editBtn}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <MoreHoriz />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(false)}
                >
                  <MenuItem
                    onClick={() => {
                      props.category.available = !props.category.available;
                      props.updateMenu(props.menu, "categories");
                      setAnchorEl(null);
                    }}
                  >
                    {!props.category.available
                      ? "Enable Option"
                      : "Disable Option"}
                  </MenuItem>
                </Menu>
              </div>
            }
          />
        </ListItemSecondaryAction>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <List style={{ width: "100%" }}>
          {props.category.items.map((c) =>
            props.items
              .filter((f) => f.uuid === c)
              .map((item, i) => (
                <ListItem
                  divider={true}
                  style={{
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 40,
                    paddingRight: 150,
                  }}
                  className={props.classes.btn}
                  button
                  key={item.id + i}
                  onClick={() =>
                    submit({
                      ...item,
                      category_name: props.category.name.en,
                    })
                  }
                >
                  <ListItemText
                    className={props.classes.listText}
                    primary={
                      <div
                        style={{
                          fontWeight: "bold",
                          marginRight: 80,
                          fontSize: 12,
                        }}
                      >
                        {item.name.en}
                      </div>
                    }
                    secondary={
                      <span style={{ paddingRight: 80, fontSize: 12 }}>
                        {item.description && item.description.en}
                      </span>
                    }
                  />
                  <ListItemSecondaryAction
                    style={{ right: -5, display: "inline-flex" }}
                  >
                    {!item.available && (
                      <ListItemText
                        style={{
                          marginTop: item.price > 0 ? 15 : 10,
                          marginRight: item.price > 0 ? 2 : -20,
                        }}
                        primary={
                          <div className={props.classes.available}>
                            <p>Unavailable</p>
                          </div>
                        }
                      />
                    )}

                    <List>
                      <ListItem>
                        {item.price > 0 ? (
                          <ListItemText
                            className={props.classes.listText}
                            primary={
                              props.availableCurrency
                                ? item.prices
                                  ? `${Number(
                                      item.prices[props.currency]
                                    ).toFixed(item.pricePrecision)}`
                                  : `${Number(item.price).toFixed(
                                      item.pricePrecision
                                    )}`
                                : `${Number(item.price).toFixed(
                                    item.pricePrecision
                                  )}`
                            }
                          />
                        ) : null}

                        <ArrowForwardIos
                          style={{ width: 15, marginLeft: 30 }}
                        />
                      </ListItem>
                    </List>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default ShopItem;
