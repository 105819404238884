import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Circle, DeliveryDining, SupportAgent } from "@styled-icons/material";
import { Dish } from "@styled-icons/boxicons-regular";
import moment from "moment";

const colors = {
  all: "#007bff",
  kitchen: "#007bff",
  rider: "#218838",
  support: "#b4000f",
};

const borderColors = {
  all: "#007bff",
  kitchen: "#479ffc",
  rider: "#539962",
  support: "#b8464f",
};

const icons = {
  all: <Circle style={{ height: 14 }} />,
  kitchen: <Dish style={{ height: 15 }} />,
  rider: <DeliveryDining style={{ height: 15 }} />,
  support: <SupportAgent style={{ height: 15 }} />,
};

const OrderLogItem = ({
  created_at,
  state,
  user,
  comment,
  type,
  order_placed,
  log_diff,
}) => {
  const classes = useStyles();

  const [order_diff, setOrderDiff] = useState("");

  useEffect(() => {
    let hrs = moment(created_at).diff(order_placed, "hours");
    let mins = moment(created_at).diff(order_placed, "minutes");

    if (mins % 60 < 10) {
      mins = "0" + (mins % 60);
    } else {
      mins = mins % 60;
    }

    setOrderDiff(hrs + ":" + mins);
  }, [created_at, order_placed]);

  return (
    <div
      className={classes.root}
      style={{ gridTemplateColumns: "18% 10% 30% 30% 12%" }}
    >
      <div className={classes.item}>
        <p className={classes.text}>
          {moment(created_at).format("DD-MM-YYYY h:mm")}
        </p>
        <p style={{ margin: "0 0", fontWeight: "bold", fontStyle: "italic" }}>
          TOM: {order_diff}
        </p>
      </div>

      <div className={classes.item}>
        <div
          className={classes.state}
          style={{
            color: colors[state.toLowerCase()] || colors.all,
            borderColor: borderColors[state.toLowerCase()] || borderColors.all,
          }}
        >
          {icons[state.toLowerCase()] || icons.all}

          <p>{state}</p>
        </div>

        {log_diff && (
          <p
            style={{
              marginTop: 5,
              marginBottom: 0,
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            MIS:{log_diff}
          </p>
        )}
      </div>

      <div className={classes.item}>
        <p className={classes.text}>{user}</p>
      </div>

      <div className={classes.item}>
        <p className={classes.text}>{comment}</p>
      </div>

      <div className={classes.item} style={{ textAlign: "right" }}>
        <p className={classes.text}>{type}</p>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0 5px",
    display: "grid",
    borderBottom: "1px solid #bfbfbf",
  },

  text: {
    fontSize: 15,
    margin: "7px 0px",
  },

  state: {
    display: "inline-flex",
    marginLeft: 0,
    marginTop: 6,
    border: "1px solid #000",
    padding: "3px 4px",
    borderRadius: 20,
    width: "fit-content",
    "& svg": {
      width: 14,
      marginRight: 2,
    },
    "& p": {
      fontSize: 10.5,
      margin: 0,
    },
  },
}));
export default OrderLogItem;
