import { CssBaseline, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import MyDrawer from "./MyDrawer";

const Layout = ({ children, location }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MyDrawer
        location={location}
        _toggleBar={() => setOpen(!open)}
        open={open}
      />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  content: {
    width: "100%",
    maxWidth: "100%",
    overflowX: "hidden",
  },
}));
export default Layout;
