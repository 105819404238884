import * as types from "../action.types";

export const customersReducer = (state, action) => {
  switch (action.type) {
    case types.GET_CUSTOMERS_LOADING:
      return { isLoading: true, data: null };

    case types.REFRESH_CUSTOMERS_LOADING:
      return { isLoading: true, data: null };

    case types.GET_CUSTOMERS_FAILED:
      return {
        isLoading: false,
        error: action.payload,
        data: null,
      };

    case types.REFRESH_CUSTOMERS_FAILED:
      return {
        isLoading: false,
        error: action.payload,
        data: null,
      };

    case types.GET_CUSTOMERS_SUCCESS:
      return { data: action.payload, isLoading: false };

    case types.REFRESH_CUSTOMERS_SUCCESS:
      return { data: action.payload, isLoading: false };

    case types.SEARCH_CUSTOMER_SUCCESS:
      return {
        data: action.payload,
        isLoading: false,
        searching_total: action.payload.total,
      };

    case types.GET_MORE_CUSTOMERS_SUCCESS:
      let state_data;
      if (state.data) {
        state_data = state.data;
        state_data.total = action.payload.total;
        state_data.token = action.payload.token;
        state_data.customers.push(...action.payload.customers);
      } else {
        state_data = action.payload;
      }

      return { data: state_data, isLoading: false };

    case types.DISABLE_CUSTOMER_SUCCESS:
      state.data.customers.map((customer) => {
        if (customer.phone === action.payload.phone) {
          customer.active = action.payload.active;
        }

        return customer;
      });

      return { data: state.data, isLoading: false, disabled: true };

    default:
      return state;
  }
};

export const customerReducer = (state, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_ADDRESSES_LOADING:
      return {
        isLoading: true,
        addresses: null,
        details: state?.details,
      };

    case types.GET_CUSTOMER_ADDRESSES_FAILED:
      return {
        isLoading: false,
        error: action.payload,
        details: state?.details,
      };

    case types.GET_CUSTOMER_ADDRESSES_SUCCESS:
      return {
        details: state?.details,
        addresses: action.payload,
        isLoading: false,
      };

    case types.GET_CUSTOMER_SUCCESS:
      return {
        details: action.payload,
        addresses: state?.addresses,
        isLoading: false,
      };

    case types.ADD_CUSTOMER_SUCCESS:
      return {
        details: action.payload,
        isLoading: false,
      };

    case types.DISABLE_CUSTOMER_LOADING:
      return {
        isLoading: true,
        details: state?.details || action.payload,
        addresses: state?.addresses,
      };

    case types.DISABLE_CUSTOMER_FAILED:
      return {
        isLoading: false,
        message: action.payload,
        addresses: state?.addresses,
      };

    case types.DISABLE_CUSTOMER_SUCCESS:
      let details;
      if (state.details) {
        state.details.active = action.payload.active;
        details = state.details;
      } else {
        details = action.payload;
      }
      return {
        isLoading: false,
        details,
        addresses: state?.addresses,
      };

    case types.UPDATE_CUSTOMER_PERSONAL_INFO_LOADING:
      return {
        isLoading: true,
        details: state?.details,
        addresses: state?.addresses,
      };

    case types.UPDATE_CUSTOMER_PERSONAL_INFO_FAILED:
      return {
        isLoading: false,
        message: action.payload,
        details: state?.details,
        updated: true,
        addresses: state?.addresses,
      };

    case types.UPDATE_CUSTOMER_PERSONAL_INFO_SUCCESS:
      return {
        isLoading: false,
        updated: true,
        details: action.payload,
        addresses: state?.addresses,
      };

    case types.UPDATE_CUSTOMER_ADDRESS_LOADING:
      return {
        isLoading: true,
        details: state?.details,

        addresses: state?.addresses,
      };

    case types.UPDATE_CUSTOMER_ADDRESS_FAILED:
      return {
        isLoading: false,
        message: action.payload,
        details: state?.details,
        updated: true,
        addresses: state?.addresses,
      };

    case types.UPDATE_CUSTOMER_ADDRESS_SUCCESS:
      state.addresses = state.addresses.map((address) => {
        if (address.address_id === action.payload.address_id) {
          return action.payload;
        } else {
          return address;
        }
      });

      return {
        isLoading: false,
        updated: true,
        details: state?.details,
        addresses: state.addresses,
      };

    default:
      return state;
  }
};
