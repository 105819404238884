import { Checkbox, withStyles } from "@material-ui/core";

export const GreenCheckbox = withStyles({
  root: {
    color: "#45484d",
    "&$checked": {
      color: "#099c40",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const RedCheckbox = withStyles({
  root: {
    color: "#45484d",
    "&$checked": {
      color: "#ed1b24",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
