/* eslint-disable react-hooks/exhaustive-deps */
import { Dish } from "@styled-icons/boxicons-regular";
import {
  CheckCircle,
  Circle,
  DeliveryDining,
  SupportAgent,
} from "@styled-icons/material";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const colors = {
  all: "#b5b012",
  dispatch: "#218838",
  received: "#f2b141",
  kitchen: "#007bff",
  rider: "#218838",
  support: "#b4000f",
};

const borderColors = {
  all: "#b5b012",
  dispatch: "#218838",
  received: "#f2b141",
  kitchen: "#479ffc",
  rider: "#539962",
  support: "#b8464f",
};

const icons = {
  all: <Circle style={{ height: 14 }} />,
  kitchen: <Dish style={{ height: 15 }} />,
  dispatch: <DeliveryDining style={{ height: 15 }} />,
  received: <CheckCircle style={{ height: 15 }} />,
  rider: <DeliveryDining style={{ height: 15 }} />,
  support: <SupportAgent style={{ height: 15 }} />,
};

const CommentItem = ({ body, state, created_at }) => {
  const classes = useStyles();
  const [timeDiff, setTimeDiff] = useState("");
  const [timeCouter, setTimeCounter] = useState(0);

  useEffect(() => {
    const time = created_at;
    const now = moment();
    const duration = moment.duration(now.diff(time));

    const d =
      duration._data.hours === 0
        ? duration._data.minutes + "mins"
        : duration._data.hours + "hrs " + duration._data.minutes + "mins";

    setTimeDiff(d);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const time = created_at;
      const now = moment();
      const duration = moment.duration(now.diff(time));

      const d =
        duration._data.hours === 0
          ? duration._data.minutes + "mins"
          : duration._data.hours + "hrs " + duration._data.minutes + "mins";
      setTimeCounter(timeCouter + 1);
      setTimeDiff(d);
    }, 3000);

    return () => clearTimeout(timer);
  }, [timeCouter]);

  return (
    <div className={classes.root}>
      <p className={classes.commentTxt}>{body}</p>
      <p className={classes.commentTxt}>{timeDiff} ago</p>
      <div
        className={classes.state}
        style={{
          color: colors[state.title.toLowerCase()] || colors.all,
          borderColor:
            borderColors[state.title.toLowerCase()] || borderColors.all,
        }}
      >
        {icons[state.title.toLowerCase()] || icons.all}

        <p>{state.title}</p>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
  },

  commentTxt: {
    margin: 0,
    fontSize: 12.5,
  },

  state: {
    display: "inline-flex",
    border: "1px solid #000",
    padding: "3px 4px",
    borderRadius: 20,
    width: "fit-content",
    "& svg": {
      width: 14,
      marginRight: 2,
    },
    "& p": {
      fontSize: 10.5,
      margin: 0,
    },
  },
}));

export default CommentItem;
