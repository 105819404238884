import firebase from "../firebase";

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  fetchTimeMillis: 60000, // One min before timing out while fetching
  minimumFetchIntervalMillis: 1, // very short interval for fields expiration.
};

remoteConfig.defaultConfig = {
  countries: false,
  user_roles: false,
};

export default remoteConfig;
