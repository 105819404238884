import * as types from "../action.types";
import { BASE_URL } from "../../app.config";

export function getZonesComplexes(country) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ZONES_COMPLEXES_LOADING });

    const response = await fetch(`${BASE_URL}/zones_list/${country}`, {
      method: "GET",
    });

    const result = await response.json();

    if (!result.message) {
      dispatch({
        type: types.GET_ZONES_COMPLEXES_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.GET_ZONES_COMPLEXES_FAILED,
        payload: result,
      });
    }
  };
}

export function refreshZonesComplexes(country) {
  return async (dispatch) => {
    dispatch({ type: types.REFRESH_ZONES_COMPLEXES_LOADING });

    const response = await fetch(`${BASE_URL}/zones_list/${country}`, {
      method: "GET",
    });

    const result = await response.json();

    if (!result.message) {
      dispatch({
        type: types.REFRESH_ZONES_COMPLEXES_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.REFRESH_ZONES_COMPLEXES_FAILED,
        payload: result,
      });
    }
  };
}

export function getZoneComplex(data) {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ZONE_COMPLEX_LOADING,
    });
    const response = await fetch(`${BASE_URL}/zone_complex`, {
      method: "GET",
    });

    const result = await response.json();

    if (result) {
      dispatch({
        type: types.GET_ZONE_COMPLEX_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.GET_ZONE_COMPLEX_FAILED,
        payload: result,
      });
    }
  };
}

export function updateZoneComplexes(data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_ZONE_COMPLEX_LOADING });

    const response = await fetch(`${BASE_URL}/update_zone_complexes`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result.updated) {
      dispatch({
        type: types.UPDATE_ZONE_COMPLEX_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: types.UPDATE_ZONE_COMPLEX_FAILED,
        payload: result,
      });
    }
  };
}
