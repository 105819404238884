import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Shop from "./shop";
import Product from "./product";
import Loader from "react-loader-spinner";

const Menu = (props) => {
  const classes = useStyles();

  const [brandSpecials, setBrandSpecials] = useState([]);
  const [specialsMenu, setSpecialsMenu] = useState([]);
  const [brandCategories, setBrandCategories] = useState([]);
  const [brandCategoriesItems, setBrandCategoriesItems] = useState([]);
  const [brand, setBrand] = useState({});
  const [product, setProduct] = useState();
  const [productDialog, setProductDialog] = useState();
  const [loading, setLoading] = useState(false);

  const addProduct = (product) => {
    setProduct(product);
    setProductDialog(true);
  };

  const clearProduct = () => {
    setProduct(null);
    setProductDialog(false);
  };

  useEffect(() => {
    addBrandMenu(props.menu, props.menu.specials, props.menu.brand);
  }, [props.menu]);

  const addBrandMenu = (_menu, _specials, _value) => {
    try {
      if (_menu.brand) {
        const bnd = _menu.brand;

        setBrand(bnd);

        try {
          if (bnd) {
            if (_specials.length > 0 && bnd.specials) {
              let _brandSpecials = [];
              let _specialsMenu = [];
              bnd.specials.map((_brandSpecial) =>
                _specials
                  .filter((f) => f.uuid === _brandSpecial)
                  .map((bs) => _brandSpecials.push(bs))
              );

              _brandSpecials.map((special) =>
                _specialsMenu.push(
                  ..._menu.items.filter((f) => f.uuid === special.items)
                )
              );

              setBrandSpecials(_brandSpecials);
              setSpecialsMenu(_specialsMenu);
            }

            if (_menu) {
              setBrandCategories(
                _menu.categories.filter((f) => f.brand === bnd.uuid)
              );

              _menu.categories
                .filter((f) => f.brand === bnd.uuid)
                .map((category) => {
                  setBrandCategoriesItems((bci) => [
                    ...bci,
                    ..._menu.items.filter((f) =>
                      category.items.includes(f.uuid)
                    ),
                  ]);
                  return true;
                });
              setLoading(false);
            }
          }
        } catch (error) {}
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const renderTabContent = () => {
    if (brand) {
      return (
        <Shop
          addProduct={addProduct}
          specials={brandSpecials.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          specialsMenu={specialsMenu.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          brand={brand}
          categories={brandCategories.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          brandCategoriesItems={brandCategoriesItems.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          menu={props.menu}
          updateMenu={props.updateMenu}
          availableCurrency={props.menu.available_currencies}
          currency={props.menu.available_currencies[0]}
        />
      );
    }
  };

  return (
    <div>
      {props.menu && (
        <div
          style={{
            backgroundColor:
              props.menu.tab_header_config[0].container.backgroundColor,
          }}
          className={classes.tabHeader}
        >
          <img
            height="60"
            src={props.menu.brand.logo_list.en}
            alt={props.menu.brand.name.en}
          />
        </div>
      )}

      {props.menu && renderTabContent()}

      {product && (
        <Product
          brand={brand}
          menu={props.menu}
          item={product}
          open={productDialog}
          onAddCartIterm={props.onAddCartIterm}
          close={clearProduct}
          classes={classes}
          updateMenu={props.updateMenu}
          availableCurrency={props.menu.available_currencies}
          currency={props.menu.available_currencies[0]}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#FAFAFA",
  },
  flex: {
    flex: 1,
  },
  body: {
    flex: 1,
    alignSelf: "center",
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    alignSelf: "center",
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabHeader: {
    width: "100%",
    textAlign: "center",
  },
  tabsIndicator: {
    backgroundColor: "#1890ff",
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    height: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$tabSelected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  tabSelected: {},
  list: {
    backgroundColor: "#ffffff",
    borderRadius: 4,
    marginLeft: 24,
    marginRight: 24,
    padding: 8,
  },
  quantityItem: {
    backgroundColor: "#ffffff",
    borderRadius: 4,
    marginLeft: 24,
    marginRight: 24,
  },
}));

export default Menu;
