/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Pagination from "../components/commons/pagination";
import {
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Switch,
  TextField,
  withStyles,
} from "@material-ui/core";
import { Refresh, SearchOutlined } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";

import StockItem from "../components/stockouts/StockItem";
import { AutoSelect, MySelect } from "../components/commons/select";
import { disableProduct, getStocks } from "../redux/actions/stock.actions";
import { getComplexes } from "../redux/actions/complexes.action";
import { RedCheckbox } from "../components/commons/checkboxs";

const headings = [
  "Name",
  "Description",
  "Price",
  "Type",
  "Complex",
  "Brand",
  "",
];

const filters = ["None", "Items", "Options", "Sizes", "Specials"];

const StockOuts = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const remote = useSelector((state) => state.remote);
  const r_complexes = useSelector((state) => state.complexes);
  const r_stocks = useSelector((state) => state.stocks);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [complexes, setComplexes] = useState();
  const [complex, setComplex] = useState("");
  const [brands, setBrands] = useState();
  const [brand, setBrand] = useState("");
  const [stocks, setStocks] = useState([]);
  const [stocks2, setStocks2] = useState([]);
  const [selected, setSelected] = useState([]);
  const [bulkAvailable, setBulkAvailable] = useState(false);
  const [change_stock, setChangeStock] = useState(false);
  const [filter, setFilter] = useState();
  const [a_filter, setA_Filter] = useState();

  useEffect(() => {
    if (rows !== 10) {
      setPage(1);
    }
  }, [rows]);

  useEffect(() => {
    setSelected([]);
    setQuery("");
    if (complex && brand) {
      setLoading(true);
      if (change_stock) {
        dispatch(getStocks(complex.uuid, brand.uuid));
      } else {
        if (stocks.length === 0) {
          dispatch(getStocks(complex.uuid, brand.uuid));
        }
      }
    }
  }, [complex, brand, change_stock]);

  useEffect(() => {
    if (remote && !r_complexes) {
      const country_name = user.country;
      dispatch(getComplexes(country_name));
    } else {
      if (r_complexes?.list && !complex) {
        const _complexes = r_complexes.list.map((_complex) => _complex.complex);
        let _complex;

        if (user.complex) {
          const u_c = user.complex.toLowerCase();
          _complex = _complexes.find(({ name }) => name.toLowerCase() === u_c);
        } else {
          _complex = _complexes[0];
        }

        setComplex(_complex);
        setComplexes(_complexes);
      }
    }

    if (remote && remote.country.brands && !brand) {
      if (user.brand) {
        const _brand = remote.country.brands.find((b) => b.name === user.brand);
        setBrand(_brand);
      } else {
        setBrand(remote.country.brands[0]);
        setBrands(remote.country.brands);
      }
    }
  }, [user, remote, r_complexes]);

  useEffect(() => {
    let stocks = [];
    let data = r_stocks?.data;

    if (data && data.length) {
      if (data[0]?.complex_name === complex.name) {
        if (query) {
          data = data.filter(
            ({ name, description }) =>
              name?.en.toLowerCase().includes(query.toLowerCase()) ||
              description?.en.toLowerCase().includes(query.toLowerCase())
          );
        }

        if (filter) {
          if (filter !== "none") {
            data = data.filter((f) => f.type === filter);
          }
        }

        if (a_filter) {
          if (a_filter !== "none") {
            const a_f = a_filter === "true" ? true : false;
            data = data.filter((f) => f.available === a_f);
          }
        }

        setTotal(data.length);

        if (page === 1) {
          stocks = data?.slice(0, rows);
        } else {
          stocks = data?.slice(rows * (page - 1), rows * page);
        }
      } else {
        dispatch(getStocks(complex.uuid, brand.uuid));
      }
    }

    setStocks([...stocks]);

    if (data) setStocks2([...data]);

    setLoading(false);
  }, [page, rows, r_stocks, query, filter, a_filter]);

  async function _onNext() {
    setLoading(true);
    setPage(page + 1);
  }

  function _onPrev() {
    setLoading(true);
    setPage(page - 1);
  }

  function _onRefresh() {
    setLoading(true);
    if (query) {
      setPage(1);
      setRows(10);
    }

    if (complex && brand && !r_stocks?.isLoading) {
      dispatch(getStocks(complex.uuid, brand.uuid));
    }
  }

  function _onDisable(data, bulkValue) {
    const stock_email = brand.stockouts_email ?? "";

    if (data) {
      const { product, complex_uuid, brand_uuid, available } = data;
      dispatch(
        disableProduct([
          {
            ...product,
            complex_uuid,
            brand_uuid,
            available,
            stock_email,
            agent: user,
          },
        ])
      );
    } else if (selected.length > 0) {
      const selectedData = selected.map((product) => {
        return {
          ...product,
          complex_uuid: complex.uuid,
          brand_uuid: brand.uuid,
          available: bulkValue,
          stock_email,
          agent: user,
        };
      });

      dispatch(disableProduct(selectedData));

      setSelected([]);
    }
  }

  function _onSelect(product) {
    const _selected = selected;

    let j;

    for (let i = 0; i < selected.length; i++) {
      const item = selected[i];
      if (item.uuid === product.uuid && item.type === product.type) {
        j = i;
        break;
      }
    }

    if (j || j === 0) {
      selected.splice(j, 1);
    } else {
      _selected.push(product);
    }

    let bulk = true;

    for (let i = 0; i < _selected.length; i++) {
      const item = selected[i];
      if (!item.available) {
        bulk = false;

        break;
      }
    }

    setBulkAvailable(bulk);

    setSelected([..._selected]);
  }

  return (
    <>
      <div className={classes.heading}>
        <h2 className={classes.name}>Stock Outs</h2>
      </div>

      <div className={classes.main}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <div style={{ marginTop: 16 }} />
            <AutoSelect
              classes={classes}
              value={complex}
              select_items={complexes}
              setValue={(value) => {
                setComplex(value);
                setChangeStock(true);
              }}
              name="complex"
              id="complex"
              select_loading="Fetching Complexes..."
            />
          </Grid>

          {!user.brand && (
            <Grid
              item
              xs={2}
              style={{ paddingRight: 0, flexBasis: "14%", maxWidth: "14%" }}
            >
              <div style={{ marginTop: 16 }} />
              <AutoSelect
                classes={classes}
                select_items={brands}
                value={brand}
                setValue={(value) => {
                  setBrand(value);
                  setChangeStock(true);
                }}
                name="brand"
                id="brand"
                select_loading="Fetching Brands..."
              />
            </Grid>
          )}

          <Grid
            item
            xs={2}
            style={{ paddingRight: 0, flexBasis: "11.5%", maxWidth: "11.5%" }}
          >
            <div style={{ marginTop: 16 }} />
            <div style={{ display: "inline-flex" }}>
              <div style={{ fontWeight: "bold", marginTop: 8 }}>Type:</div>
              <MySelect
                native={true}
                classes={classes}
                select_items={filters}
                setValue={(value) => {
                  setSelected([]);
                  setFilter(value.toLowerCase());
                }}
                name="filter_by"
                id="filter_by"
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ flexBasis: "15%", maxWidth: "15%" }}>
            <div style={{ marginTop: 16 }} />
            <div style={{ display: "inline-flex" }}>
              <div style={{ fontWeight: "bold", marginTop: 8 }}>Available:</div>
              <MySelect
                native={true}
                classes={classes}
                select_items={["None", "True", "False"]}
                setValue={(value) => {
                  setSelected([]);
                  setA_Filter(value.toLowerCase());
                }}
                name="filter_by_availability"
                id="filter_by_availability"
              />
            </div>
          </Grid>

          <Grid
            item
            xs={2}
            style={{ paddingLeft: 0, flexBasis: "30.6%", maxWidth: "30.6%" }}
          >
            <div className={classes.searchbar_container}>
              <div className={classes.searchbar}>
                <TextField
                  variant="outlined"
                  placeholder="Search by name or description ..."
                  size="small"
                  value={query}
                  onChange={(event) => {
                    setSelected([]);
                    setQuery(event.target.value);
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={1} style={{ flexBasis: "6%", maxWidth: "6%" }}>
            <Button
              variant="text"
              color="primary"
              className={classes.refreshBtn}
              onClick={() => _onRefresh()}
            >
              <Refresh />
            </Button>
          </Grid>

          <Grid item xs={1} style={{ flexBasis: "6%", maxWidth: "6%" }}>
            {user.role !== "agent" && (
              <FormControlLabel
                style={{ marginTop: 19 }}
                control={
                  <MySwitch
                    checked={bulkAvailable}
                    onChange={() => {
                      setBulkAvailable(!bulkAvailable);
                      if (selected.length > 0) {
                        _onDisable("", !bulkAvailable);
                      } else {
                        alert("Nothing was selected");
                      }
                    }}
                  />
                }
              />
            )}
          </Grid>
        </Grid>
        <div className={classes.header} spacing={2}>
          <div style={{ flex: 1, paddingLeft: 3 }}>
            {user.role !== "agent" && (
              <RedCheckbox
                style={{ padding: 0, paddingTop: 1 }}
                checked={Boolean(selected.length === stocks2.length)}
                onChange={() => {
                  if (selected.length === stocks2.length) {
                    setSelected([]);
                  } else {
                    setSelected([...stocks2]);

                    let bulk = true;

                    for (let i = 0; i < stocks2.length; i++) {
                      const item = stocks2[i];
                      if (!item.available) {
                        bulk = false;

                        break;
                      }
                    }

                    setBulkAvailable(bulk);
                  }
                }}
              />
            )}
          </div>

          {headings.map((title, index) => (
            <div
              key={index}
              className={classes.headerItem}
              style={{
                flex:
                  index < 2
                    ? 4
                    : (index >= 2 && index < 4) || index === 5
                    ? 2
                    : index < 6
                    ? 3
                    : 1,
              }}
            >
              <p className={classes.title}>{title}</p>
            </div>
          ))}
        </div>

        {stocks && !loading ? (
          <div className={classes.content}>
            {r_stocks?.isLoading && (
              <div
                style={{
                  zIndex: 200,
                  position: "absolute",
                  top: "40%",
                  left: "50%",
                }}
              >
                <div
                  style={{
                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                    textAlign: "center",
                    backgroundColor: "white",
                    borderRadius: 10,
                    padding: 30,
                  }}
                >
                  <Loader type="Oval" color="red" width="100" height="100" />
                  <h4>Updating stock, Please wait.............</h4>
                </div>
              </div>
            )}
            {stocks.map((product, index) => (
              <StockItem
                role={user.role}
                product={product}
                complex_uuid={complex.uuid}
                brand_uuid={brand.uuid}
                key={index}
                checked={() => {
                  let checked = false;
                  for (let i = 0; i < selected.length; i++) {
                    const item = selected[i];
                    if (
                      item.uuid === product.uuid &&
                      item.type === product.type
                    ) {
                      checked = true;
                      break;
                    }
                  }

                  return checked;
                }}
                _onSelect={_onSelect}
                _onDisable={_onDisable}
              />
            ))}
          </div>
        ) : r_stocks?.error ? (
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            {r_stocks?.error.message}
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            <Loader type="ThreeDots" color="red" width="60" height="60" />
          </div>
        )}

        {stocks && (
          <Pagination
            prev={Boolean(page <= 1)}
            next={Boolean(page * rows >= total)}
            from={(page - 1) * rows + 1}
            to={page * rows >= total ? total : page * rows}
            total={total}
            rows={rows}
            _onChangeRows={(rows) => {
              setRows(rows);
              setLoading(true);
            }}
            _onNext={_onNext}
            _onPrev={_onPrev}
          />
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    backgroundColor: "#fcfcfc",
    width: "100%",
    minHeight: 40,
    position: "fixed",
    zIndex: 1000,
  },

  backIcon: {
    color: "#000",
    fontSize: 15,
    "&:hover": {
      display: "block",
      backgroundColor: "#fcfcfc",
    },
  },

  main: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: "5%",
  },

  searchbar_container: {
    backgroundColor: "inherit",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  notchedOutline: {
    border: 0,
  },
  searchbar: {
    "& > div": {
      backgroundColor: "#fff",
      width: "100%",
      minHeight: 40,
      borderRadius: 5,
    },
  },

  header: {
    backgroundColor: "#000",
    width: "100%",
    padding: 12,
    display: "flex",
    flexDirection: "row",
  },

  title: {
    color: "#fff",
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
  },

  content: {
    backgroundColor: "#fff",
    padding: 7,
    paddingTop: 0,
    miHeight: "65vh",
  },

  name: {
    marginTop: -1,
    marginBottom: 4,
    marginLeft: 29,
    paddingTop: 15,
    paddingBottom: 10,
  },

  refreshBtn: {
    marginTop: theme.spacing(2.3),
  },

  select: {
    backgroundColor: "#fff",
  },
  autoSelect: {
    backgroundColor: "#fff",
    paddingTop: 5,
    paddingLeft: 5,
    borderRadius: 5,
    minHeight: 40,
  },
}));

const MySwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#099c40",
    },
    "&$checked + $track": {
      backgroundColor: "#099c40",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default StockOuts;
