import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  List,
  Paper,
  Button,
  Grid,
  Input,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core/";
import { Close, Add, Remove } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Group from "./group";
import Loader from "react-loader-spinner";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Product = (props) => {
  const classes = useStyles();

  const [item, setItem] = useState();
  const [sizes, setSizes] = useState([]);
  const [groups, setGroups] = useState([]);
  const [options, setOptions] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => setItem(props.item), [props.item]);

  useEffect(() => {
    let grps = props.item.groups;
    let szs = props.item.size;
    if (szs) {
      szs.map((s) => {
        props.menu.sizes
          .filter((f) => f.uuid === s)
          .map((size) => {
            setSizes((sizes) => [...sizes, size]);
          });
      });
    }
    if (grps) {
      grps.map((g) => {
        props.menu.groups
          .filter((f) => f.uuid === g)
          .map((group) => {
            setGroups((groups) => [...groups, group]);
          });
      });
    }
  }, []);

  const addOptions = (options) => setOptions(options);

  const save = () => {
    setLoading(true);
    if (item) {
      props.menu.items.map((m_item, i) => {
        if (m_item.uuid === item.uuid) {
          props.menu.items[i] = item;
        }
      });
      props.updateMenu(props.menu, "items");
    }

    if (options) {
      options.map((opt) => {
        if (opt.type === "size") {
          props.menu.sizes.map((m_opt, i) => {
            if (m_opt.uuid === opt.uuid) {
              props.menu.sizes[i] = opt;
            }
          });
        } else if (opt.type === "group") {
          props.menu.groups.map((m_opt, i) => {
            if (m_opt.uuid === opt.uuid) {
              props.menu.groups[i] = opt;
            }
          });
        } else {
          props.menu.options.map((m_opt, i) => {
            if (m_opt.uuid === opt.uuid) {
              props.menu.options[i] = opt;
            }
          });
        }
      });

      if (options.find((f) => f.type === "size")) {
        props.updateMenu(props.menu, "sizes");
      } else if (options.find((f) => f.type === "group")) {
        props.updateMenu(props.menu, "groups");
      } else {
        props.updateMenu(props.menu, "options");
      }
    }
    setLoading(true);
    props.close();
  };

  const view = () => {
    let sizesView = null;
    let groupsView = null;

    if (sizes.length > 0) {
      sizesView = (
        <Group
          currency={props.currency}
          addOptions={addOptions}
          name="Sizes"
          id={1}
          options={sizes.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          sizeGroupOptions={props.menu.options.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          sizeGroups={props.menu.groups.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
        />
      );
    }

    if (groups.length > 0) {
      groupsView = groups.map((group) => {
        let options = [];
        group.options.map((o) => {
          props.menu.options
            .filter((f) => f.uuid === o)
            .map((opt) => options.push(opt));
        });

        return (
          <Group
            key={group.id}
            name={group.name.en}
            currency={props.currency}
            addOptions={addOptions}
            options={options.sort((a, b) =>
              a.position < b.position ? -1 : a.position > b.position ? 1 : 0
            )}
            sizeGroupOptions={props.menu.options.sort((a, b) =>
              a.position < b.position ? -1 : a.position > b.position ? 1 : 0
            )}
            sizeGroups={props.menu.groups.sort((a, b) =>
              a.position < b.position ? -1 : a.position > b.position ? 1 : 0
            )}
          />
        );
      });
    }

    return { groups: groupsView, sizes: sizesView };
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.close}
    >
      <DialogTitle>
        <div align="right">
          <IconButton onClick={props.close} className={classes.closeBtn}>
            <Close />
          </IconButton>
        </div>
        <div
          align="center"
          style={{
            marginTop: -20,
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          {" "}
          Choose your options
        </div>
      </DialogTitle>
      <DialogContent className={classes.root}>
        <main
          style={{
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          <List>
            <ListItem style={{ padding: 0, paddingRight: 90 }}>
              <ListItemText
                primary={
                  <div
                    style={{
                      fontWeight: "bold",
                      marginRight: 80,
                    }}
                  >
                    {props.item.name.en}
                  </div>
                }
                style={{
                  paddingRight: item?.prices[props.currency] !== 0 && 80,
                }}
                secondary={
                  <span>
                    {props.item.description && props.item.description.en}
                  </span>
                }
              />

              <ListItemSecondaryAction style={{ right: 0, textAlign: "end" }}>
                <ListItemText
                  primary={
                    <div style={{ fontWeight: "bold" }}>
                      {props.item.prices[props.currency] !== 0 &&
                        props.item.prices[props.currency]}
                      <FormControlLabel
                        style={{ marginLeft: 1 }}
                        control={
                          <MySwitch
                            checked={item?.available}
                            onChange={() => {
                              setItem((prevState) => {
                                return {
                                  ...prevState,
                                  available: !item.available,
                                };
                              });
                            }}
                          />
                        }
                      />
                    </div>
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          {view().sizes}
          {view().groups}
          <List>
            <div
              style={{
                marginBottom: 48,
                marginTop: 6,
              }}
            >
              <Button
                fullWidth={true}
                onClick={() => save()}
                variant="contained"
                style={{
                  backgroundColor: "#000",
                  borderRadius: 16,
                  textTransform: "capitalize",
                  padding: 0,
                }}
              >
                {loading ? (
                  <div style={{ textAlign: "center" }}>
                    <Loader type="Oval" color="grey" width="20" height="20" />
                  </div>
                ) : (
                  <ListItemText
                    disableTypography
                    primary={
                      <div
                        style={{
                          color: "#FFFFFF",
                          fontWeight: "bold",
                        }}
                      >
                        Save
                      </div>
                    }
                  />
                )}
              </Button>
            </div>
          </List>
        </main>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
  flex: {
    flex: 1,
  },
  content: {
    backgroundColor: "#f4f4f4",
    paddingLeft: 80,
    paddingRight: 80,
  },
  closeBtn: {
    border: "thin solid",
    height: 20,
    width: 20,
    fontSize: 15,
    padding: 0,
    marginTop: "-20px",
    marginRight: "-15px",
  },
  quantityItem: {
    borderRadius: 10,
    boxShadow: "none",
    marginBottom: 10,
  },
  btn: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  body: {
    flex: 1,
    alignSelf: "center",
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    alignSelf: "center",
  },
  controls: {
    flex: 1,
    width: "100%",
    paddingLeft: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  listText: {
    color: "#fff",
  },
}));

const MySwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#099c40",
    },
    "&$checked + $track": {
      backgroundColor: "#099c40",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default Product;
