import React, { useState } from "react";
import {
  Button,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Edit, MoreHoriz } from "@material-ui/icons";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import {
  disableUser,
  resetUserPassword,
} from "../../redux/actions/users.action";

const UserItem = ({
  id,
  name,
  email,
  created,
  signed_in,
  role,
  _onUpdate,
  disabled,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user_disable = useSelector((state) => state.user_disable);
  const editor = useSelector((state) => state.auth?.user?.editor);
  const password_reset = useSelector((state) => state.password_reset);

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: disabled ? "#f0f0f0" : "" }}>
      <div className={classes.item} style={{ flex: 3 }}>
        <p className={classes.text}>{name}</p>
      </div>

      <div className={classes.item} style={{ flex: 3 }}>
        <p className={classes.text}>{email}</p>
      </div>

      <div className={classes.item} style={{ flex: 2 }}>
        <p className={classes.text}>{created}</p>
      </div>

      <div className={classes.item} style={{ flex: 2 }}>
        <p className={classes.text}>{signed_in}</p>
      </div>

      <div className={classes.item} style={{ flex: 2 }}>
        <p className={classes.text}>{role}</p>
      </div>

      <div className={clsx(classes.item)} style={{ flex: 1 }}>
        {disabled ? (
          <Button variant="contained" color="primary" className={classes.btn}>
            Disabled
          </Button>
        ) : (
          editor && (
            <IconButton
              className={clsx(classes.editBtn, "edit")}
              onClick={_onUpdate}>
              <Edit />
            </IconButton>
          )
        )}
      </div>

      <div className={clsx(classes.item)} style={{ flex: 1 }}>
        {(user_disable?.isLoading && user_disable?.user?.email === email) ||
        (password_reset?.isLoading && password_reset?.user?.email === email) ? (
          <div
            style={{
              marginBottom: -2,
            }}>
            <Loader type="Oval" color="grey" width="20" height="20" />
          </div>
        ) : (
          <IconButton
            className={clsx(classes.editBtn)}
            onClick={(event) => setAnchorEl(event.currentTarget)}>
            <MoreHoriz />
          </IconButton>
        )}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(false)}>
          <MenuItem
            onClick={() => {
              dispatch(resetUserPassword({ email }));
              setAnchorEl(null);
            }}>
            Reset Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(disableUser({ email, disabled: !disabled }));
              setAnchorEl(null);
            }}>
            {disabled ? "Enable Account" : "Disable Account"}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 5,
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #bfbfbf",

    "&:hover": {
      backgroundColor: "#EBFCFF",
    },

    "& .edit": {
      display: "none",
    },

    "&:hover .edit": {
      display: "block",
    },
  },

  btn: {
    borderRadius: 20,
    textTransform: "none",
    fontSize: 12,
    backgroundColor: "#f0f0f0",
    color: "#000",

    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },

  item: {
    flex: 1,
  },

  text: {
    fontSize: 15,
    margin: "7px 0px",
  },

  editBtn: {
    padding: 2,
  },
}));

export default UserItem;
