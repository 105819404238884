/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Select,
  Slide,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../redux/actions/users.action";
import { getComplexes } from "../../redux/actions/complexes.action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const NewUser = ({ open, _onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users);
  const { country, user_mgt } = useSelector((state) => state.remote);
  const r_complexes = useSelector((state) => state.complexes);

  const [data, setData] = useState({
    name: "",
    email: "",
    role: "agent",
    complex: "",
    brand: "",
  });
  const [updating, setUpdating] = useState(false);
  const [complexes, setComplexes] = useState([]);

  useEffect(() => {
    if (country && !r_complexes) {
      dispatch(getComplexes(country.name));
    } else {
      if (r_complexes?.list) {
        const _complexes = r_complexes.list.map((_complex) => _complex.complex);

        setComplexes(_complexes);
      }
    }
  }, [country, r_complexes]);

  useEffect(() => {
    if (users) {
      if (users.isLoading === false && users.isAdded) {
        _onClose();
        setData({ name: "", email: "", role: "agent", complex: "" });
      }

      if (users.isLoading === false) {
        setUpdating(false);
      }
    }
  }, [users]);

  function _onChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setData((prevState) => {
      return { ...prevState, [name]: value };
    });
  }

  async function _onSubmit(event) {
    event.preventDefault();
    data.country = country.name;
    if (user_mgt.who_edits.includes(data.role)) {
      data.editor = true;
    }

    setUpdating(true);
    dispatch(addUser(data));
  }

  return (
    <Dialog
      open={open}
      className={classes.root}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}
    >
      <DialogTitle className={classes.header}>
        <div align="right" style={{ marginBottom: -40 }}>
          <IconButton
            onClick={() => {
              _onClose();
              setData({ name: "", email: "", role: "agent", complex: "" });
            }}
            className={classes.closeBtn}
          >
            <Close />
          </IconButton>
        </div>
        New User
      </DialogTitle>

      <DialogContent className={classes.content}>
        <form>
          <TextField
            fullWidth
            value={data.name}
            name="name"
            onChange={_onChange}
            variant="outlined"
            label="Full name"
            size="small"
            InputProps={{
              classes: {
                notchedOutline:
                  users?.error && users?.error?.name
                    ? classes.notchedOutline
                    : null,
              },
            }}
            className={classes.input}
          />

          <TextField
            fullWidth
            value={data.email}
            name="email"
            onChange={_onChange}
            variant="outlined"
            label="Email"
            size="small"
            InputProps={{
              classes: {
                notchedOutline:
                  users?.error && users?.error?.email
                    ? classes.notchedOutline
                    : null,
              },
            }}
            className={classes.input}
          />

          <p className={classes.label}>Role</p>
          <FormControl
            className={classes.select}
            variant="outlined"
            margin="dense"
            style={{ marginTop: 0 }}
          >
            <Select
              native
              value={data.role}
              onChange={_onChange}
              inputProps={{
                name: "role",
                id: "role",
              }}
            >
              <option value="">-- Select Role --</option>
              {user_mgt?.user_roles.map((role, index) => (
                <option key={index} value={role}>
                  {role}
                </option>
              ))}
            </Select>
          </FormControl>

          <p className={classes.label}>Brand</p>
          <FormControl
            className={classes.select}
            variant="outlined"
            margin="dense"
            style={{ marginTop: 0 }}
          >
            <Select
              native
              value={data.brand}
              onChange={_onChange}
              inputProps={{
                name: "brand",
                id: "brand",
              }}
            >
              <option value="">-- Select Brand --</option>
              {country.brands.map(({ name }, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>

          <p className={classes.label}>Complex</p>
          <FormControl
            className={classes.select}
            variant="outlined"
            margin="dense"
            style={{ marginTop: 0 }}
          >
            <Select
              native
              value={data.complex}
              onChange={_onChange}
              inputProps={{
                name: "complex",
                id: "complex",
              }}
            >
              <option value="">-- Select Complex --</option>
              {complexes.map((complex, index) => (
                <option key={index} value={complex.name}>
                  {complex.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <br />
          <br />
          <br />

          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                disabled={updating}
                className={classes.btn}
                color="primary"
                onClick={() => {
                  _onClose();
                  setData({ name: "", email: "", role: "agent" });
                }}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={_onSubmit}
                variant="contained"
                className={classes.btn}
                color="secondary"
              >
                {updating ? (
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: -2,
                    }}
                  >
                    <Loader type="Oval" color="#fff" width="20" height="20" />
                  </div>
                ) : (
                  "Save"
                )}
              </Button>
            </Grid>
          </Grid>
          <br />
        </form>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#c71c24",
    textAlign: "center",
    padding: "8px 12px",
    color: "#fff",

    "& p": {
      margin: 0,
      fontSize: 15,
      textAlign: "left",
    },
  },

  content: {
    borderTop: "3px #fff  solid",
  },

  btn: {
    textTransform: "none",
  },

  closeBtn: {
    color: "#fff",
  },

  label: {
    marginTop: 25,
    marginBottom: 3,
    marginLeft: 3,
    fontSize: 15,
    fontWeight: 600,
  },

  select: {
    backgroundColor: "#fff",

    "& select": {
      minWidth: 200,
    },
  },

  input: {
    backgroundColor: "#fff",
    marginTop: 20,
  },
  notchedOutline: {
    borderColor: "red !important",
    "&:before": {
      borderColor: "red !important",
    },
    "&:after": {
      borderColor: "red !important",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
  },
}));

export default NewUser;
