/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Pagination from "../components/commons/pagination";
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Refresh, SearchOutlined } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import CustomerItem from "../components/customers/customerItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomers,
  getMoreCustomers,
  refrestCustomers,
  searchCustomer,
} from "../redux/actions/customers.action";

const headings = ["Name", "Phone Number", "Email", "Date Created", "", ""];

const Customers = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { country } = useSelector((state) => state.remote);
  const r_customers = useSelector((state) => state.customers);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState("");
  const [query, setQuery] = useState("");
  const [customers, setCustomers] = useState();

  useEffect(() => {
    let timeout;
    if (query) {
      setPage(1);
      setRows(10);
      timeout = setTimeout(() => {
        dispatch(searchCustomer(country.country_code.toLowerCase(), query));
      }, 500);
    }

    return () => clearTimeout(timeout);
  }, [query]);

  useEffect(() => {
    if (rows !== 10) {
      setPage(1);
      dispatch(
        getMoreCustomers(country.country_code.toLowerCase(), rows, token)
      );
    }
  }, [rows]);

  useEffect(() => {
    if (country && !r_customers?.isLoading && !r_customers?.data) {
      if (country.country_code) {
        dispatch(getCustomers(country.country_code));
      }
    }
  }, [r_customers, country]);

  useEffect(() => {
    let customers = [];
    const data = r_customers?.data;

    if (data && data.customers && data.customers.length >= rows * page) {
      setTotal(data.total);
      setToken(data.token);

      if (page === 1) {
        customers = data.customers?.slice(0, rows);
      } else {
        customers = data.customers?.slice(rows * (page - 1), rows * page);
      }

      setCustomers([...customers]);
    } else {
      if (data?.customers && data?.query) {
        setTotal(data.total);
        setToken(data.token);
        if (!query) {
          setQuery(data.query);
        }

        customers = data.customers?.slice(0, rows);

        setCustomers([...customers]);
      }
    }
    setLoading(false);
  }, [page, rows, r_customers]);

  async function _onNext() {
    setLoading(true);

    await dispatch(
      getMoreCustomers(country.country_code.toLowerCase(), rows, token)
    );

    setPage(page + 1);
  }

  function _onPrev() {
    setLoading(true);

    setPage(page - 1);
  }

  return (
    <>
      <div className={classes.heading} />
      <div className={classes.main}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <div className={classes.searchbar_container}>
              <div className={classes.searchbar}>
                <TextField
                  variant="outlined"
                  placeholder="Search by name or phone number ..."
                  size="small"
                  value={query}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    if (!event.target.value) {
                      dispatch(
                        searchCustomer(country.country_code.toLowerCase(), "")
                      );
                    }
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </Grid>

          <Grid item></Grid>

          <Grid item>
            <Button
              variant="text"
              color="primary"
              className={classes.refreshBtn}
              onClick={() => {
                setLoading(true);
                if (query) {
                  setPage(1);
                  setRows(10);

                  dispatch(
                    searchCustomer(country.country_code.toLowerCase(), query)
                  );
                } else {
                  dispatch(
                    refrestCustomers(country.country_code.toLowerCase())
                  );
                }
              }}
            >
              <Refresh />
            </Button>
          </Grid>
        </Grid>
        <div className={classes.header} spacing={2}>
          {headings.map((title, index) => (
            <div
              key={index}
              className={classes.headerItem}
              style={{ flex: index < 3 ? 2 : 1 }}
            >
              <p className={classes.title}>{title}</p>
            </div>
          ))}
        </div>

        {customers && !loading ? (
          <div className={classes.content}>
            {customers.map((customer, index) => (
              <CustomerItem {...customer} key={index} />
            ))}
          </div>
        ) : r_customers?.error ? (
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            {r_customers?.error.message}
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            <Loader type="ThreeDots" color="red" width="60" height="60" />
          </div>
        )}

        {customers && (
          <Pagination
            prev={Boolean(page <= 1)}
            next={Boolean(page * rows >= total)}
            from={(page - 1) * rows + 1}
            to={page * rows >= total ? total : page * rows}
            total={total}
            rows={rows}
            _onChangeRows={(rows) => {
              setRows(rows);
              setLoading(true);
            }}
            _onNext={_onNext}
            _onPrev={_onPrev}
          />
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    backgroundColor: "#fcfcfc",
    width: "100%",
    minHeight: 40,
  },

  backIcon: {
    color: "#000",
    fontSize: 15,
    "&:hover": {
      display: "block",
      backgroundColor: "#fcfcfc",
    },
  },

  main: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  searchbar_container: {
    backgroundColor: "inherit",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  notchedOutline: {
    border: 0,
  },
  searchbar: {
    paddingLeft: theme.spacing(4),
    "& > div": {
      backgroundColor: "#fff",
      width: "100%",
      minHeight: 40,
      borderRadius: 5,
    },
  },

  header: {
    backgroundColor: "#ed1b24",
    width: "100%",
    padding: 5,
    display: "flex",
    flexDirection: "row",
  },

  headerItem: {
    flex: 1,
  },
  title: {
    color: "#fff",
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
  },

  content: {
    backgroundColor: "#fff",
    padding: 7,
    paddingTop: 0,
    miHeight: "65vh",
  },

  refreshBtn: {
    marginTop: theme.spacing(2.3),
  },
}));

export default Customers;
