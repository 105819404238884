/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import OrderLogItem from "./OrderLogItem";
import firebase from "../../firebase";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const headings = ["Time Stamp", "State", "User", "Comment", "Type"];

const OrderLogs = ({ open, close, order_uuid, order_placed, odos_number }) => {
  const classes = useStyles();

  const [logs, setLogs] = useState("");

  useEffect(() => {
    if (open) {
      const logsRef = firebase.database().ref("orders/" + order_uuid + "/logs");

      logsRef.on("value", (snapshot) => {
        let allLogs = [];
        snapshot.forEach((snap) => {
          allLogs.push({ _id: snap.key, ...snap.val() });
        });

        allLogs = allLogs.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });

        allLogs = allLogs.map((log, index) => {
          const p_index = index - 1;

          let p_changed_at = log.changed_at;

          if (p_index >= 0) {
            let p_log = allLogs[p_index];

            if (!p_log.changed_at) {
              for (let j = 0; index < p_index; j++) {
                if (allLogs[j].changed_at) {
                  p_log = allLogs[j];
                  break;
                }
              }
            } else {
              p_changed_at = p_log.changed_at;
            }
          }

          if (log.changed_at && p_changed_at) {
            let hrs = moment(log.changed_at).diff(p_changed_at, "hours");
            let mins = moment(log.changed_at).diff(p_changed_at, "minutes");

            if (mins % 60 < 10) {
              mins = "0" + (mins % 60);
            } else {
              mins = mins % 60;
            }

            log.log_diff = hrs + ":" + mins;
          }

          return log;
        });

        setLogs(allLogs);
      });
    }
  }, [open]);

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        className={classes.root}
        TransitionComponent={Transition}
        onClose={() => close()}
        fullWidth
        maxWidth="md"
        PaperProps={{
          classes: {
            root: classes.paper,
          },
        }}
      >
        <DialogTitle className={classes.header}>
          <div
            onClick={() => close()}
            align="right"
            style={{ marginBottom: -40 }}
          >
            <IconButton className={classes.closeBtn}>
              <Close />
            </IconButton>
          </div>
          Order - {odos_number} Log
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div
            className={classes.header1}
            spacing={2}
            style={{ gridTemplateColumns: "18% 10% 30% 30% 12%" }}
          >
            {headings.map((title, index) => (
              <div
                key={index}
                className={classes.headerItem}
                style={{
                  textAlign: index === headings.length - 1 ? "right" : "",
                }}
              >
                <p className={classes.title}>{title}</p>
              </div>
            ))}
          </div>

          {logs ? (
            logs.map((item, index) => (
              <OrderLogItem key={index} {...item} order_placed={order_placed} />
            ))
          ) : (
            <div
              style={{
                width: "fit-content",
                margin: "0px auto",
                marginTop: 200,
              }}
            >
              <CircularProgress size={50} color="primary" />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "8px 12px",
    fontWeight: 700,
  },

  header1: {
    backgroundColor: "#000",
    width: "100%",
    padding: 6,
    display: "grid",
  },

  headerItem: {
    flex: 1,
  },
  title: {
    color: "#fff",
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
  },
  content: {
    borderTop: "3px #fff  solid",
  },
  closeBtn: {},
}));
export default OrderLogs;
